import React, { Component } from "react";

export default class ButtonSubmit extends Component {
  render() {
    return (
      <button
        id={this.props.id}
        type="submit"
        value={this.props.value}
        className={this.props.className}
        hidden={this.props.hidden}
        disabled={this.props.disabled || this.props.readOnly}
        onClick={this.props.onClick}
      >
        {this.props.disabled && !this.props.readOnly ? (
          <i className="fa fa-spinner fa-spin mr-2"></i>
        ) : (
          <i
            className={
              this.props.icon ? "fas " + this.props.icon + " mr-2" : "hidden"
            }
          />
        )}
        {this.props.name}
      </button>
    );
  }
}

import axios from "axios";
import localStorage from "./localStorage";
import { toastTimerSuccess, toastPopupError } from "./sweetAlert";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    const originalRequest = response.config;

    // if login success
    if (originalRequest.url === "/auth/login") {
      localStorage.setToken(response.data);
      localStorage.setUser(response.data);
    }

    // fire toast if there is a message
    if (response.data.message) {
      toastTimerSuccess.fire({ title: response.data.message });
    }

    return response;
  },
  (error) => {
    const originalRequest = error.config;

    // server issue (ex. api not started) will not return any responses
    if (!error.response) return Promise.reject(error); //window.location.replace("/500");

    // if error of logout, don't care anythings, just redirect to login
    if (originalRequest.url === "/auth/logout") return Promise.reject(error);

    // if refresh_token expired, clear storage & force login
    if (
      error.response.status === 401 &&
      originalRequest.url === "/auth/token"
    ) {
      localStorage.clearStorage();
      window.location.reload();
    }

    // if access_token expired, get new token by refresh_token
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== "/auth/login"
    ) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getRefreshToken();
      return axios
        .post("/auth/token", {
          refresh_token: refreshToken,
        })
        .then((res) => {
          localStorage.setToken(res.data);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getAccessToken();
          return axios(originalRequest);
        });
    }

    // get the server error
    if (error.response.status >= 500) {
      toastPopupError.fire({
        text: "An unexpected error occured. Please contact your administrator.",
      });
    }

    // get the client error
    if (error.response.status < 500 && originalRequest.url !== "/auth/token") {
      toastPopupError.fire({
        text:
          error.response.data.errMsg /*+ " [code: " + error.response.status + "]"*/,
      });
    }

    return Promise.reject(error);
  }
);

import React, { Component } from "react";
import axios from "axios";

import { toastTimerSuccess } from "../utils/sweetAlert";

export default class SignUpActivate extends Component {
  componentDidMount() {
    let token = this.props.match.params.token;

    axios.get(`/auth/signup/activate/${token}`).then((success) => {
      toastTimerSuccess.fire({ title: "Email has been verified." });

      this.props.history.push("/");
    });
  }

  render() {
    return <div></div>;
  }
}

import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Card, Form } from "react-bootstrap";

import Header from "../components/Header";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import PageLoader from "../components/PageLoader";
import ButtonLoading from "../components/ButtonLoading";

import params from "../utils/params";
import localStorage from "../utils/localStorage";
import { toastTimerSuccess, toastPopupError } from "../utils/sweetAlert";

export default class SupplierDetail extends Component {
  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getUser());
    if (!user) {
      this.state = { invalid: true };
      return;
    }

    this.state = {
      role: user.role,

      id: props.match.params.id,
      code: "",
      name: "",
      addrMain: "",
      addrShipping: "",
      officeTel: "",
      officeMobile: "",
      officeEmail: "",
      contactName: "",
      contactTel: "",
      taxId: "",
      accountName: "",
      accountId: "",
      ecountId: "",

      isLoading: false,
      isCreate: props.match.params.id ? false : true,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.setState({ isLoading: true });

      axios
        .get("/supplier/" + this.state.id)
        .then((success) => {
          this.setState({
            id: success.data.id,
            code: success.data.code,
            name: success.data.name,
            addrMain: success.data.addr_main,
            addrShipping: success.data.addr_shipping
              ? success.data.addr_shipping
              : "",
            officeTel: success.data.office_tel ? success.data.office_tel : "",
            officeMobile: success.data.office_mobile
              ? success.data.office_mobile
              : "",
            officeEmail: success.data.office_email
              ? success.data.office_email
              : "",
            contactName: success.data.contact_name,
            contactTel: success.data.contact_tel,
            taxId: success.data.tax_id ? success.data.tax_id : "",
            accountName: success.data.account_name
              ? success.data.account_name
              : "",
            accountId: success.data.account_id ? success.data.account_id : "",
            ecountId: success.data.ecount_id ? success.data.ecount_id : "",
          });
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  handleOnChange = (e) => {
    let val = "";

    if (e.target.type === "checkbox") {
      val = e.target.checked;
    } else {
      val = e.target.value;
    }

    this.setState({ [e.target.name]: val });
  };

  handleOnCreate = (e) => {
    e.preventDefault();

    // validate required input
    if (this.missingRequiredField()) return;

    this.setState({ isLoading: true });

    const data = new FormData();
    data.append("code", this.state.code);
    data.append("name", this.state.name);
    data.append("addrMain", this.state.addrMain);
    data.append("addrShipping", this.state.addrShipping);
    data.append("officeTel", this.state.officeTel);
    data.append("officeMobile", this.state.officeMobile);
    data.append("officeEmail", this.state.officeEmail);
    data.append("contactName", this.state.contactName);
    data.append("contactTel", this.state.contactTel);
    data.append("taxId", this.state.taxId);
    data.append("accountName", this.state.accountName);
    data.append("accountId", this.state.accountId);
    data.append("ecountId", this.state.ecountId);

    axios
      .post("/supplier", data)
      .then((success) => {
        this.setState({
          id: success.data.id,
        });

        this.props.history.push("/supplier/" + this.state.id);
        this.setState({ isCreate: false });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleOnUpdate = (e) => {
    e.preventDefault();

    // validate required input
    if (this.state.id && this.missingRequiredField()) return;

    this.setState({ isLoading: true });

    const data = new FormData();
    data.append("code", this.state.code);
    data.append("name", this.state.name);
    data.append("addrMain", this.state.addrMain);
    data.append(
      "addrShipping",
      this.state.addrShipping === "" ? "-" : this.state.addrShipping
    );
    data.append(
      "officeTel",
      this.state.officeTel === "" ? "-" : this.state.officeTel
    );
    data.append(
      "officeMobile",
      this.state.officeMobile === "" ? "-" : this.state.officeMobile
    );
    data.append(
      "officeEmail",
      this.state.officeEmail === "" ? "-" : this.state.officeEmail
    );
    data.append("contactName", this.state.contactName);
    data.append("contactTel", this.state.contactTel);
    data.append("taxId", this.state.taxId === "" ? "-" : this.state.taxId);
    data.append(
      "accountName",
      this.state.accountName === "" ? "-" : this.state.accountName
    );
    data.append(
      "accountId",
      this.state.accountId === "" ? "-" : this.state.accountId
    );
    data.append(
      "ecountId",
      this.state.ecountId === "" ? "-" : this.state.ecountId
    );

    axios
      .post("/supplier/" + this.state.id, data)
      .then((success) => {
        toastTimerSuccess.fire({
          title: "Supplier's details has been updated",
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  missingRequiredField = (e) => {
    if (!this.state.code)
      return toastPopupError.fire({ title: "Code is required!" });
    if (!this.state.name)
      return toastPopupError.fire({ title: "Name is required!" });
    if (!this.state.addrMain)
      return toastPopupError.fire({ title: "Main Address is required!" });
    if (!this.state.contactName)
      return toastPopupError.fire({ title: "Contact Name is required!" });
    if (!this.state.contactTel)
      return toastPopupError.fire({ title: "Contact Tel. is required!" });

    return false;
  };

  render() {
    if (this.state.invalid) {
      return <Redirect to="/logout" />;
    }

    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader
            title="Supplier "
            subtitle={this.state.id}
            prevDir="Supplier"
            prevUrl="/supplier"
            activeDir="Detail"
            button="hidden"
          />

          <section className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Body>
                      <Form>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Code *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="code"
                              value={this.state.code}
                              onChange={this.handleOnChange}
                              autoComplete="false"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Name *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="name"
                              value={this.state.name}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Main Address *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="addrMain"
                              value={this.state.addrMain}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Shipping Address
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="addrShipping"
                              value={this.state.addrShipping}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Office Tel.
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              type="number"
                              name="officeTel"
                              value={this.state.officeTel}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Office Mobile
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              type="number"
                              name="officeMobile"
                              value={this.state.officeMobile}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Email
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              type="email"
                              name="officeEmail"
                              value={this.state.officeEmail}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Contact Name *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="contactName"
                              value={this.state.contactName}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Contact Tel. *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              type="number"
                              name="contactTel"
                              value={this.state.contactTel}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Tax ID
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              type="number"
                              name="taxId"
                              value={this.state.taxId}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Account Name
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="accountName"
                              value={this.state.accountName}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Account ID
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              type="number"
                              name="accountId"
                              value={this.state.accountId}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              eCount Id
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="ecountId"
                              value={this.state.ecountId}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="offset-sm-2">
                          <ButtonLoading
                            name="Submit"
                            hidden={
                              !this.state.isCreate ||
                              this.state.role === params.ROLE.PURCHASE
                            }
                            className="btn btn-success"
                            disabled={
                              this.state.isLoading ||
                              this.state.role === params.ROLE.PURCHASE
                            }
                            onClick={this.handleOnCreate}
                          />
                          <ButtonLoading
                            name="Save"
                            hidden={
                              this.state.isCreate ||
                              this.state.role === params.ROLE.PURCHASE
                            }
                            className="btn btn-success"
                            disabled={
                              this.state.isLoading ||
                              this.state.role === params.ROLE.PURCHASE
                            }
                            onClick={this.handleOnUpdate}
                          />
                        </Form.Group>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
        <PageLoader marginTop="30%" loading={this.state.isLoading} />
      </div>
    );
  }
}

const params = {
  LOCAL_STORAGE: {
    ACCESS_TOKEN: "d2p-act-tk",
    REFRESH_TOKEN: "d2p-rfh-tk",
    CURRENT_USER: "d2p-crr-us",
  },
  ROLE: {
    APPS: "APPS",
    ACCOUNTING: "ACCT",
    ADMIN: "ADMN",
    DRAFT: "DRFT",
    IT_SUPPORT: "IT-SUPP",
    PRODUCTION: "PROD",
    PURCHASE: "PURC",
    MANAGER: "MGMT",
    DRAFT_MANAGER: "MGMT-DRFT",
    PRODUCTION_MANAGER: "MGMT-PROD",
    PURCHASE_MANAGER: "MGMT-PURC",
  },
  FILE: {
    STEP: {
      PYTHA_FILE: "PYTHA",
      EXPORT_FILE: "OPTICUT",
      SUBMIT_JOB: "JOB",
    },
  },
  STATUS: {
    PENDING: "pending",
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
    DRAFT: "draft",
    SUBMITTED: "submitted",
    CONFIRMED: "confirmed",
    CANCELLED: "cancelled",
    OPEN: "open",
    CLOSED: "closed",
    PAID: "paid",
    APPROVED: "approved",
  },
  MATERIAL: {
    PURCHASE: "1",
    PROCESS: "2",
    PAYMENT: "3",
    DELIVER: "4",
    READY: "5",
    COMPLETED: "6",
  },
  JOBSTATUS: {
    NEW: "New",
    DOING: "Doing",
    WAITING: "Waiting",
    COMPLETED: "Completed",
    PLANNED: "Planned",
    RESERVED: "Reserved",
  },
};

export default params;

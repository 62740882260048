import React, { Component } from "react";
import { Container, Row, Col, Card, Table, Image } from "react-bootstrap";
import axios from "axios";

import PageLoader from "../components/PageLoader";

export default class RequisitionOrderPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      job: "",
      project: "",
      staff: "",
      roCode: "",
      remark: "",
      createdAt: "",

      suppName: "",
      suppTaxId: "",
      suppAddress: "",
      suppTel: "",
      suppEmail: "",
      suppContactName: "",
      suppContactTel: "",

      roItems: [],
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    // Promise all
    await axios.get("/re/" + this.state.id).then((res1) => {
      this.setState({
        id: res1.data.id,
        job: res1.data.job_id,
        staff:
          res1.data.staff.firstname + " (" + res1.data.staff.nickname + ")",
        roCode: res1.data.code,
        remark: res1.data.remark ? res1.data.remark : "",
        createdAt: res1.data.created_at,
      });
    });

    let fetchData1 = axios.get("/job/" + this.state.job).then((res1) => {
      this.setState({
        project: res1.data.roomCode.project_id + "_" + res1.data.roomCode.room_code,
      });
    });

    let fetchData2 = axios.get("/reitem/" + this.state.id).then((res2) => {
      let list = [];

      if (res2.data.length) {
        res2.data.map((item) => {
          return list.push({
            id: item.id,
            itemId: item.item_id,
            itemName: item.s_name,
            qty: item.qty,
            unit: item.s_unit,
            remark: item.remark,
          });
        });

        this.setState({
          roItems: list,
        });
      }
    });

    Promise.all([fetchData1, fetchData2]).then((success) => {
      this.setState({ isLoading: false });
      window.addEventListener("load", window.print());
    });
  };

  render() {
    return (
      <section className="invoice">
        <Container fluid>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <br />
                  <br />
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <h4 className="page-header">
                    <strong>บริษัท บ้านสุข ดีไซน์ แอนด์ ลิฟวิ่ง จำกัด</strong>
                  </h4>
                  <p className="page-header">
                    เลขที่ 21/4 หมู่ที่ 3 ต.คอกกระบือ อ.เมืองสมุทรสาคร
                    จ.สมุทรสาคร 74000
                    <br />
                    โทร. 096-294-6365&nbsp;&nbsp;&nbsp;&nbsp;เลขนิติบุคคล:
                    0105559007748
                    <br />
                    www.baansook.com&nbsp;&nbsp;&nbsp;&nbsp;email:
                    info@baansook.com
                  </p>
                </Col>
                <Col
                  md={4}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Image
                    src={"/dist/img/BaansookLogo.png"}
                    className="img-responsive"
                    style={{ width: "35%" }}
                  />
                  <br />
                  <br />

                  <h4 className="page-header text-center">
                    <strong>ใบเบิกสินค้า / Requisition Order</strong>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {/* info row */}
                  <PageLoader loading={this.state.isLoading} marginTop="25%" />
                  <Table responsive size="sm">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            border: "solid 1px",
                          }}
                        >
                          <Table responsive borderless size="sm">
                            <tbody>
                              <tr>
                                <td width={"15%"}>
                                  <strong>
                                    Job:
                                    <br />
                                    Project:
                                  </strong>
                                </td>
                                <td width={"50%"}>
                                  {this.state.job}
                                  <br />
                                  {this.state.project}
                                </td>
                                <td width={"15%"}>
                                  <strong>
                                    เลขที่:
                                    <br />
                                    วันที่:
                                    <br />
                                    ผู้เบิกสินค้า:
                                  </strong>
                                </td>
                                <td width={"20%"}>
                                  {this.state.roCode}
                                  <br />
                                  {new Date(
                                    this.state.createdAt
                                  ).toLocaleDateString("en-GB")}
                                  <br />
                                  {this.state.staff}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Row>
                    <Col md={12}>
                      <Table responsive size="sm">
                        <thead>
                          <tr>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                              }}
                            >
                              No.
                            </th>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                              }}
                            >
                              Item ID
                            </th>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                                width: "45%",
                              }}
                            >
                              Item Name
                            </th>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                              }}
                            >
                              Qty
                            </th>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                              }}
                            >
                              Unit
                            </th>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                              }}
                            >
                              Remark
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.roItems.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                  align="center"
                                >
                                  {index + 1}
                                </td>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                >
                                  {item.itemId}
                                </td>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                >
                                  {item.itemName}
                                </td>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                  align="right"
                                >
                                  {item.qty.toLocaleString("en-US", {
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                >
                                  {item.unit}
                                </td>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                >{item.remark}</td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td
                              style={{ border: "solid 1px" }}
                              rowSpan={4}
                              colSpan={6}
                            >
                              <strong>{"หมายเหตุ: "}</strong>
                              {this.state.remark ? this.state.remark : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <br />
                  <Row className="invoice-info">
                    <Col sm={4} className="invoice-col text-center">
                      {"_______________________"}
                      <br />
                      ผู้เบิกสินค้า
                      <br />
                      วันที่:
                      {" ____ / ____ / ________"}
                    </Col>
                    <Col sm={4} className="invoice-col text-center">
                      {"_______________________"}
                      <br />
                      ผู้จ่ายสินค้า
                      <br />
                      วันที่:
                      {" ____ / ____ / ________"}
                    </Col>
                    <Col sm={4} className="invoice-col text-center">
                      {"_______________________"}
                      <br />
                      ผู้อนุมัติ
                      <br />
                      วันที่:
                      {" ____ / ____ / ________"}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    );
  }
}

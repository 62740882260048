import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer className="main-footer">
        Powered by <a href="#!">JARVIS</a>
        <div className="float-right d-none d-sm-inline-block">
          Version {process.env.REACT_APP_VERSION}
        </div>
      </footer>
    );
  }
}

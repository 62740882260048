import params from "./params";

const localStorageService = (function () {
  // set method
  function _setToken(tokenObj) {
    localStorage.setItem(
      params.LOCAL_STORAGE.ACCESS_TOKEN,
      tokenObj.access_token
    );
    localStorage.setItem(
      params.LOCAL_STORAGE.REFRESH_TOKEN,
      tokenObj.refresh_token
    );
  }
  function _setUser(tokenObj) {
    localStorage.setItem(
      params.LOCAL_STORAGE.CURRENT_USER,
      JSON.stringify(tokenObj.user)
    );
  }

  // get method
  function _getAccessToken() {
    return localStorage.getItem(params.LOCAL_STORAGE.ACCESS_TOKEN);
  }
  function _getRefreshToken() {
    return localStorage.getItem(params.LOCAL_STORAGE.REFRESH_TOKEN);
  }
  function _getUser() {
    return localStorage.getItem(params.LOCAL_STORAGE.CURRENT_USER);
  }

  // clear method
  function _clearAccessToken() {
    localStorage.removeItem(params.LOCAL_STORAGE.ACCESS_TOKEN);
  }
  function _clearRefreshToken() {
    localStorage.removeItem(params.LOCAL_STORAGE.REFRESH_TOKEN);
  }
  function _clearStorage() {
    localStorage.clear();
  }

  // return
  return {
    setToken: _setToken,
    setUser: _setUser,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    getUser: _getUser,
    clearAccessToken: _clearAccessToken,
    clearRefreshToken: _clearRefreshToken,
    clearStorage: _clearStorage,
  };
})();

export default localStorageService;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import axios from "axios";
import DataTable from "react-data-table-component";

import Header from "../components/Header";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import PageLoader from "../components/PageLoader";

export default class ReceivedOrder extends Component {
  COLUMNS = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      wrap: true,
      omit: true,
    },
    {
      name: "No.",
      selector: "code",
      sortable: true,
      wrap: true,
      cell: (row) => (
        <NavLink exact to={"/ro/" + row.id}>
          <b>{row.code}</b>
        </NavLink>
      ),
    },
    {
      name: "PO No.",
      selector: "purchase_order.code",
      sortable: true,
      wrap: true,
    },
    {
      name: "Received Date",
      selector: "received_date",
      sortable: true,
      wrap: true,
      cell: (row) => (
        <span>{new Date(row.received_date).toLocaleDateString("en-GB")}</span>
      ),
    },
    {
      name: "Net Amount",
      selector: "net_amount_before_vat",
      sortable: true,
      wrap: true,
      cell: (row) =>
        row.net_amount_before_vat.toLocaleString("en-GB", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      name: "Status",
      width: "10%",
      selector: "status",
      sortable: true,
      wrap: true,
      cell: (row) => (
        <small
          className={
            row.status === "open"
              ? "badge badge-primary"
              : row.status === "approved"
              ? "badge badge-success"
              : row.status === "cancelled"
              ? "badge badge-danger"
              : "badge badge-secondary"
          }
        >
          {row.status}
        </small>
      ),
    },
    {
      name: "Owner",
      sortable: true,
      wrap: true,
      cell: (row) => (
        <span>
          {row.staff.firstname + " (" + row.staff.nickname + ")"}
        </span>
      ),
    },
    {
      name: "Last Modified",
      selector: "updated_at",
      sortable: true,
      wrap: true,
      cell: (row) => (
        <span>{new Date(row.updated_at).toLocaleString("en-GB")}</span>
      ),
    },
  ];

  constructor() {
    super();
    this.state = {
      isLoading: true,

      // table
      items: [],
      filterText: "",
      filterItems: [],
      columns: [],
      selectedRow: [],
      toggledClearRows: false,
      customStyles: {
        headCells: {
          style: {
            fontSize: 16,
            fontWeight: "bold",
            padding: "5px",
          },
        },
        cells: {
          style: {
            fontSize: 16,
            padding: "5px",
          },
        },
      },
    };
  }

  componentDidMount() {
    axios
      .get("/ro")
      .then((success) => {
        this.setState({
          items: success.data,
          columns: this.COLUMNS,
          toggledClearRows: !this.state.toggledClearRows,
        });
      })
      .finally(() => {
        this.filterTable(this.state.filterText);
        this.setState({ isLoading: false });
      });
  }

  handleOnSearch = (e) => {
    this.setState({ filterText: e.target.value });
    this.filterTable(e.target.value);
  };

  filterTable = (search) => {
    let filterItems = [];
    let text = search.toString();

    filterItems = this.state.items.filter(
      (item) =>
        (item.code &&
          item.code.toString().toLowerCase().includes(text.toLowerCase())) ||
        (item.purchase_order.code &&
          item.purchase_order.code
            .toString()
            .toLowerCase()
            .includes(text.toLowerCase())) ||
        (
          new Date(item.received_date).toLocaleDateString("en-GB") &&
          new Date(item.received_date)
            .toLocaleDateString("en-GB")
            .toString()
            .toLowerCase()
            .includes(text.toLowerCase())
        )(
          item.net_amount_before_vat &&
            item.net_amount_before_vat
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())
        ) ||
        (item.staff.firstname + " (" + item.staff.nickname + ")" &&
          (item.staff.firstname + " (" + item.staff.nickname + ")")
            .toString()
            .toLowerCase()
            .includes(text.toLowerCase())) ||
        (new Date(item.updated_at).toLocaleString("en-GB") &&
          new Date(item.updated_at)
            .toLocaleString("en-GB")
            .toString()
            .toLowerCase()
            .includes(text.toLowerCase()))
    );

    this.setState({ filterItems: filterItems });
  };

  render() {
    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader title="RO" breadcrumb="hidden" buttonUrl="/ro/create" />

          <section className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Body>
                      <PageLoader loading={this.state.isLoading} />
                      <Row>
                        <Col md={12}>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                name="searchBox"
                                className="float-right"
                                placeholder="Search ..."
                                value={this.state.filterText}
                                onChange={this.handleOnSearch}
                              />
                              <InputGroup.Append>
                                <Form.Label>
                                  <Button
                                    variant="default"
                                    onClick={() => {
                                      this.setState({ filterText: "" });
                                      this.filterTable("");
                                    }}
                                  >
                                    <i className="fas fa-times"></i>
                                  </Button>
                                </Form.Label>
                              </InputGroup.Append>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                      <DataTable
                        columns={this.state.columns}
                        data={this.state.filterItems}
                        customStyles={this.state.customStyles}
                        highlightOnHover
                        clearSelectedRows={this.state.toggledClearRows}
                        noHeader={true}
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[50, 100, 500, 1000]}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
      </div>
    );
  }
}

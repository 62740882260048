import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import { NavLink, Link, Redirect } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";

import Header from "../components/Header";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import PageLoader from "../components/PageLoader";
import ButtonLoading from "../components/ButtonLoading";
import ActivityLog from "../components/ActivityLog";
import {
  toastTimerSuccess,
  toastPopupSuccess,
  toastPopupError,
  toastModalWide,
  toastConfirmPrimary,
  toastConfirmSuccess,
  toastInputDanger,
} from "../utils/sweetAlert";

import params from "../utils/params";
import localStorage from "../utils/localStorage";

export default class JobDetail extends Component {
  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getUser());
    if (!user) {
      this.state = { invalid: true };
      return;
    }

    this.state = {
      role: user.role,

      id: props.match.params.id,
      projectId: "",
      roomCodeId: "",
      roomCode: "",
      step: "",
      errorLogId: null,
      isLoading: false,
      pythaFile: null,
      pythaFilePath: "",
      pythaFileName: "",
      pythaFileStatus: "",
      pythaFileHidden: true,
      panelFilePath: "",
      exportFile: null,
      exportFilePath: "",
      exportFileName: "",
      exportFileStatus: "",
      exportFileHidden: true,
      jobId: "",
      jobStatus: "",

      projectOptions: [],
      roomCodeOptions: [],

      // step3
      cutFilePath: "",
      cutFileRequired: "",
      drillFilePath: "",
      drillFileRequired: "",
      ntFilePath: "",
      ntFileRequired: "",
      assemblyFilePath: "",
      installFilePath: "",
      opticutFilePath: "",
      purchasingDocPath: "",
      jobNote: "",
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.setState({ isLoading: true });

      axios
        .get("/file/" + this.state.id)
        .then((success) => {
          this.setState({
            projectId: success.data.file.project_id,
            roomCodeId: success.data.file.room_code_id,
            roomCode: success.data.roomCode.room_code,
            step: success.data.file.step,
            pythaFilePath: success.data.file.file_pytha_path,
            pythaFileStatus: success.data.file.file_pytha_status,
            panelFilePath: success.data.file.file_panel_path,
            exportFilePath: success.data.file.file_export_path,
            exportFileStatus: success.data.file.file_export_status,
            jobId: success.data.job ? success.data.job.id : "",
            jobStatus: success.data.job ? success.data.job.status : "",
          });
        })
        .finally(() => {
          if (this.state.jobId) {
            axios
              .get("/job/" + this.state.jobId)
              .then((success) => {
                this.setState({
                  cutFilePath: success.data.job.cut_file_path
                    ? success.data.job.cut_file_path
                    : "",
                  cutFileRequired: success.data.job.cut_file_required,
                  drillFilePath: success.data.job.drill_file_path
                    ? success.data.job.drill_file_path
                    : "",
                  drillFileRequired: success.data.job.drill_file_required,
                  ntFilePath: success.data.job.nt_file_path
                    ? success.data.job.nt_file_path
                    : "",
                  ntFileRequired: success.data.job.nt_file_required,
                  assemblyFilePath: success.data.job.assembly_file_path
                    ? success.data.job.assembly_file_path
                    : "",
                  installFilePath: success.data.job.install_file_path
                    ? success.data.job.install_file_path
                    : "",
                  opticutFilePath: success.data.job.opticut_file_path
                    ? success.data.job.opticut_file_path
                    : "",
                  purchasingDocPath: success.data.job.purchasing_doc_path
                    ? success.data.job.purchasing_doc_path
                    : "",
                  jobNote: success.data.job.note
                    ? success.data.job.note
                    : "",
                });
              })
              .finally(() => {
                this.setState({ isLoading: false });
              });
          } else {
            this.setState({ isLoading: false });
          }
        });
    }

    // load project/room list
    axios.get("/project").then((success) => {
      let list = [];

      // process if has an option
      if (success.data.length) {
        success.data.map((project) => {
          list.push({
            value: project.id,
            label: project.id,
          });

          return 0;
        });
      }

      this.setState({
        projectOptions: list,
      });
    });
  }

  handleOnChange = (e) => {
    let val = "";

    if (e.target.type === "file") {
      val = e.target.files[0];

      // validate file extention
      if (
        val.name.split(".").pop() !== "csv" &&
        val.name.split(".").pop() !== "txt"
      ) {
        toastPopupError.fire({
          title: "Only .csv or .txt file is allowed!",
        });
      }

      this.setState({
        [e.target.name]: val,
        [e.target.name + "Name"]: val.name,
      });
    } else if (e.target.type === "checkbox") {
      val = e.target.checked;
    } else {
      val = e.target.value;
    }

    this.setState({ [e.target.name]: val });
  };

  handleOnPythaFile = (e) => {
    e.preventDefault();

    // validate required input
    if (this.missingRequiredField1()) return;

    this.setState({ isLoading: true });

    const data = new FormData();

    let url = "/file";
    if (this.state.id) {
      url = "/file/" + this.state.id + "/pytha";
    }

    data.append("projectId", this.state.projectId);
    data.append("roomCodeId", this.state.roomCodeId);
    data.append("pythaFile", this.state.pythaFile);
    data.append("step", params.FILE.STEP.PYTHA_FILE);

    axios
      .post(url, data)
      .then((success) => {
        this.setState({
          id: success.data.file.id,
          step: success.data.file.step,
          pythaFilePath: success.data.file.file_pytha_path,
          pythaFileStatus: success.data.file.file_pytha_status,
          pythaFile: null,
          panelFilePath: success.data.file.file_panel_path,
          exportFilePath: success.data.file.file_export_path,
          exportFileStatus: success.data.file.file_export_status,
          exportFile: null,
          jobId: success.data.roomCode.job_id
            ? success.data.roomCode.job_id
            : "",
          jobStatus: params.STATUS.PENDING,
          pythaFileHidden: true,
        });

        // show modal
        if (success.data.file.file_pytha_status === params.STATUS.SUCCESS)
          toastPopupSuccess.fire({
            text: "Pytha File has been successed to process",
          });
        else {
          this.generateErrorTable("pytha");
        }

        this.props.history.push("/job/" + this.state.id);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleOnExportFile = (e) => {
    e.preventDefault();

    // validate required input
    if (this.missingRequiredField2()) return;

    this.setState({ isLoading: true });

    const data = new FormData();
    data.append("projectId", this.state.projectId);
    data.append("roomCodeId", this.state.roomCodeId);
    data.append("exportFile", this.state.exportFile);
    data.append("step", params.FILE.STEP.EXPORT_FILE);

    axios
      .post("/file/" + this.state.id + "/export", data)
      .then((success) => {
        this.setState({
          step: success.data.file.step,
          exportFilePath: success.data.file.file_export_path,
          exportFileStatus: success.data.file.file_export_status,
          exportFile: null,
          jobStatus: params.STATUS.PENDING,
          exportFileHidden: true,
        });

        // show modal
        if (success.data.file.file_export_status === params.STATUS.SUCCESS)
          toastPopupSuccess.fire({
            text: "Opticut Export File has been successed to process",
          });
        else this.generateErrorTable("opticut");
      })
      .finally(() => {
        if (
          this.state.exportFileStatus === params.STATUS.SUCCESS ||
          this.state.exportFileStatus === params.STATUS.WARNING
        ) {
          axios
            .get("/job/" + this.state.jobId)
            .then((success) => {
              this.setState({
                cutFilePath: success.data.job.cut_file_path
                  ? success.data.job.cut_file_path
                  : "",
                cutFileRequired: success.data.job.cut_file_required,
                drillFilePath: success.data.job.drill_file_path
                  ? success.data.job.drill_file_path
                  : "",
                drillFileRequired: success.data.job.drill_file_required,
                ntFilePath: success.data.job.nt_file_path
                  ? success.data.job.nt_file_path
                  : "",
                ntFileRequired: success.data.job.nt_file_required,
                assemblyFilePath: success.data.job.assembly_file_path
                  ? success.data.job.assembly_file_path
                  : "",
                installFilePath: success.data.job.install_file_path
                  ? success.data.job.install_file_path
                  : "",
                opticutFilePath: success.data.job.opticut_file_path
                  ? success.data.job.opticut_file_path
                  : "",
                purchasingDocPath: success.data.job.purchasing_doc_path
                  ? success.data.job.purchasing_doc_path
                  : "",
                jobNote: success.data.job.note
                  ? success.data.job.note
                  : "",
              });
            })
            .finally(() => {
              this.setState({ isLoading: false });
            });
        } else {
          this.setState({ isLoading: false });
        }
      });
  };

  handleOnCopy = (e) => {
    toastTimerSuccess.fire({
      title: "Text copied to clipboard!",
    });
  };

  handleOnStatusJob = (e) => {
    e.preventDefault();

    let value = e.target.value;
    let toastType = null;

    // validate required input
    if (
      (value === params.STATUS.CONFIRMED ||
        value === params.STATUS.SUBMITTED) &&
      this.missingRequiredField()
    )
      return;

    // process
    let data = new FormData();
    data.append("status", value);
    data.append("cutFilePath", this.state.cutFilePath);
    data.append("drillFilePath", this.state.drillFilePath);
    data.append("ntFilePath", this.state.ntFilePath);
    data.append("assemblyFilePath", this.state.assemblyFilePath);
    data.append("installFilePath", this.state.installFilePath);
    data.append("opticutFilePath", this.state.opticutFilePath);
    data.append("purchasingDocPath", this.state.purchasingDocPath);
    data.append("note", this.state.jobNote);

    if (value === params.STATUS.DRAFT) {
      this.setState({ isLoading: true });
      this.updateJobStatus(data, value);
    } else {
      if (value === params.STATUS.SUBMITTED) toastType = toastConfirmPrimary;
      if (value === params.STATUS.CONFIRMED) toastType = toastConfirmSuccess;
      if (value === params.STATUS.CANCELLED) toastType = toastInputDanger;

      toastType.fire().then((result) => {
        if (result.isConfirmed) {
          this.setState({ isLoading: true });

          data.append(
            "reason",
            value === params.STATUS.CANCELLED ? result.value : "-"
          );
          this.updateJobStatus(data, value);
        }
      });
    }
  };

  handleOnDropdown = (e) => {
    this.setState({ projectId: e.value });

    axios.get("/roomcode/" + e.value + "/project").then((success) => {
      let list = [];

      // process if has an option
      if (success.data.length) {
        success.data.map((roomcode) => {
          list.push({
            value: roomcode.id,
            label: roomcode.room_code,
          });

          return 0;
        });
      }

      this.setState({ roomCodeOptions: list });
    });
  };

  updateJobStatus = (data, value) => {
    axios
      .post("/job/" + this.state.jobId, data)
      .then((success) => {
        toastTimerSuccess.fire({
          title: "Job's status has been updated",
        });

        // send LINE message
        if (value === params.STATUS.SUBMITTED) {
          axios.post("/line/submitted/" + this.state.jobId);
        }
        if (value === params.STATUS.CONFIRMED) {
          axios.post("/line/confirmed/" + this.state.jobId);
        }
        if (value === params.STATUS.CANCELLED) {
          axios.post(
            "/line/cancelled-" + this.state.jobStatus + "/" + this.state.jobId
          );
        }

        // reset state
        if (value === params.STATUS.CANCELLED) {
          this.setState({
            step: params.FILE.STEP.PYTHA_FILE,
            status: params.STATUS.PENDING,
            pythaFileStatus: "",
            exportFileStatus: "",
            jobId: "",
            jobStatus: "",
          });
        } else {
          this.setState({ jobStatus: value });
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  missingRequiredField = (e) => {
    if (this.state.cutFileRequired === "Y" && this.state.cutFilePath === "") {
      return toastPopupError.fire({
        title: "SK450 File is required!",
      });
    }
    if (
      this.state.drillFileRequired === "Y" &&
      this.state.drillFilePath === ""
    ) {
      return toastPopupError.fire({
        title: "Rover B File is required!",
      });
    }
    if (this.state.ntFileRequired === "Y" && this.state.ntFilePath === "") {
      return toastPopupError.fire({
        title: "NT File is required!",
      });
    }
    if (this.state.assemblyFilePath === "") {
      return toastPopupError.fire({
        title: "PYO File is required!",
      });
    }
    if (this.state.installFilePath === "") {
      return toastPopupError.fire({
        title: "Install File is required!",
      });
    }
    if (this.state.opticutFilePath === "") {
      return toastPopupError.fire({
        title: "Opticut File is required!",
      });
    }
    if (this.state.purchasingDocPath === "") {
      return toastPopupError.fire({
        title: "Purchasing Doc is required!",
      });
    }
  };

  missingRequiredField1 = (e) => {
    if (!this.state.projectId)
      return toastPopupError.fire({ title: "Project is required!" });
    if (!this.state.roomCodeId)
      return toastPopupError.fire({ title: "Room Code is required!" });
    if (!this.state.pythaFile)
      return toastPopupError.fire({ title: "Pytha File is required!" });

    return false;
  };

  missingRequiredField2 = (e) => {
    if (!this.state.exportFile)
      return toastPopupError.fire({
        title: "Opticut Export File is required!",
      });

    return false;
  };

  generateErrorTable = async (step) => {
    let errorLog = `
    <table class="table table-hover">
    <thead>
      <tr>
        <th>#</th>
        <th>Code</th>
        <th>Description</th>
        <th>Position No.</th>
        <th>Fur Code</th>
      </tr>
    </thead>
    <tbody>`;

    this.setState({ isLoading: true });

    await axios
      .get("/errorlog/" + this.state.jobId + "/" + step)
      .then((success) => {
        if (success.data.length === 0) errorLog = `NO DATA`;
        else {
          let i = 1;
          success.data.map((item) => {
            errorLog += `
            <tr>
            <td>${i++}</td>
            <td>
              <div class="badge badge-${item.error_code < 7
                ? "secondary"
                : item.error_code < 9
                  ? "warning"
                  : "danger"
              }">${item.error_code}</div>
            </td>
            <td style="text-align:left; width:50%;">${item.error_desc
              }<br/><small><b>Part Name:</b> ${item.part_name}</small></td>
            <td>${item.part_no ? item.part_no : ""}</td>
            <td>${item.fur_code ? item.fur_code : ""}</td>
            </tr>`;

            return 0;
          });
        }
      })
      .finally(() => {
        errorLog += `</tbody></table>`;
        toastModalWide.fire({
          title: `<div><u>JOB:</u> #${this.state.jobId} | <u>STEP:</u> ${step}</div>`,
          html: errorLog,
        });
        this.setState({ isLoading: false });
      });
  };

  render() {
    if (this.state.invalid) {
      return <Redirect to="/logout" />;
    }

    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader
            title={this.state.projectId}
            subtitle={this.state.roomCode}
            prevDir="Job"
            prevUrl="/job"
            activeDir="Detail"
            button="hidden"
          />

          <section className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Form>
                    <Row>
                      <Col md={12}>
                        <Card className="card-default">
                          <Card.Header>
                            <Card.Title>Status</Card.Title>
                            <div className="card-tools">
                              <Button
                                variant="tool"
                                data-card-widget="collapse"
                              >
                                <i className="fas fa-plus" />
                              </Button>
                            </div>
                          </Card.Header>
                          <Card.Body className="p-0">
                            <div className="bs-stepper">
                              <Row>
                                <Col md={12}>
                                  <div className="bs-stepper-header">
                                    <div className="step step-trigger">
                                      <div
                                        className={
                                          this.state.pythaFileStatus ===
                                            params.STATUS.SUCCESS ||
                                            this.state.pythaFileStatus ===
                                            params.STATUS.WARNING
                                            ? "active"
                                            : "disabled"
                                        }
                                      >
                                        <span className="bs-stepper-circle">
                                          1
                                        </span>
                                        <span className="bs-stepper-label">
                                          Pytha Processed
                                        </span>
                                      </div>
                                    </div>
                                    <div className="line" />
                                    <div className="step step-trigger">
                                      <div
                                        className={
                                          this.state.exportFileStatus ===
                                            params.STATUS.SUCCESS ||
                                            this.state.exportFileStatus ===
                                            params.STATUS.WARNING
                                            ? "active"
                                            : "disabled"
                                        }
                                      >
                                        <span className="bs-stepper-circle">
                                          2
                                        </span>
                                        <span className="bs-stepper-label">
                                          Opticut Export Processed
                                        </span>
                                      </div>
                                    </div>
                                    <div className="line" />
                                    <div className="step step-trigger">
                                      <div
                                        className={
                                          this.state.jobStatus === "confirmed"
                                            ? "active"
                                            : "disabled"
                                        }
                                      >
                                        <span className="bs-stepper-circle">
                                          3
                                        </span>
                                        <span className="bs-stepper-label">
                                          Job Confirmed
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <Card>
                          <Card.Header>
                            <Card.Title>Step 1</Card.Title>
                            <div className="card-tools">
                              <Button
                                variant="tool"
                                data-card-widget="collapse"
                              >
                                <i className="fas fa-minus" />
                              </Button>
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <Row
                              style={{
                                display: this.state.id ? "none" : "",
                              }}
                            >
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label>Project</Form.Label>
                                  <Select
                                    name="projectId"
                                    className="basic-select"
                                    classNamePrefix="select"
                                    styles={{
                                      // Fixes the overlapping problem of the component
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    isSearchable={true}
                                    options={this.state.projectOptions}
                                    value={
                                      this.state.projectOptions[
                                      this.state.projectOptions.findIndex(
                                        (obj) =>
                                          obj.value === this.state.projectId
                                      )
                                      ]
                                    }
                                    onChange={this.handleOnDropdown}
                                    isDisabled={this.state.id ? true : false}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label>Room Code</Form.Label>
                                  <Select
                                    name="roomCode"
                                    className="basic-select"
                                    classNamePrefix="select"
                                    styles={{
                                      // Fixes the overlapping problem of the component
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    isSearchable={true}
                                    options={this.state.roomCodeOptions}
                                    value={
                                      this.state.roomCodeOptions[
                                      this.state.roomCodeOptions.findIndex(
                                        (obj) =>
                                          obj.value === this.state.roomCodeId
                                      )
                                      ]
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        roomCodeId: e.value,
                                        roomCode: e.label,
                                      })
                                    }
                                    isDisabled={this.state.id ? true : false}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <Form.Group>
                                  <Form.Label>Pytha File *</Form.Label>
                                  <InputGroup
                                    style={{
                                      display:
                                        (this.state.pythaFileStatus ===
                                          "success" ||
                                          this.state.pythaFileStatus ===
                                          "warning" ||
                                          this.state.pythaFileStatus ===
                                          "error") &&
                                          this.state.pythaFileHidden
                                          ? "none"
                                          : "",
                                    }}
                                  >
                                    <div className="custom-file">
                                      <Form.File
                                        name="pythaFile"
                                        className="custom-file-input"
                                        onChange={this.handleOnChange}
                                        onClick={(e) => (e.target.value = null)}
                                      />
                                      <Form.Label className="custom-file-label">
                                        {this.state.pythaFile
                                          ? this.state.pythaFile.name
                                          : "Choose file"}
                                      </Form.Label>
                                    </div>
                                    <InputGroup.Append>
                                      <ButtonLoading
                                        id="pythaFile"
                                        name="Upload"
                                        icon="fa-upload"
                                        className="btn btn-success"
                                        disabled={
                                          this.state.isLoading ||
                                          this.state.role ===
                                          params.ROLE.PRODUCTION_MANAGER
                                        }
                                        onClick={this.handleOnPythaFile}
                                        hidden={
                                          this.state.role ===
                                          params.ROLE.PRODUCTION_MANAGER
                                        }
                                      />
                                    </InputGroup.Append>
                                  </InputGroup>
                                  <p
                                    style={{
                                      display: this.state.pythaFilePath
                                        ? ""
                                        : "none",
                                    }}
                                  >
                                    <small>
                                      <a
                                        href={
                                          process.env.REACT_APP_STORAGE +
                                          "/" +
                                          this.state.pythaFilePath
                                        }
                                      >
                                        file uploaded
                                      </a>
                                      {" - click to view file"}
                                    </small>
                                  </p>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <div
                                  className="callout callout-success"
                                  style={{
                                    display:
                                      this.state.pythaFileStatus === "success"
                                        ? ""
                                        : "none",
                                  }}
                                >
                                  <h5>
                                    <p className="text-success">SUCCESS!</p>
                                  </h5>
                                  <p>
                                    <a
                                      href={
                                        process.env.REACT_APP_STORAGE +
                                        "/" +
                                        this.state.panelFilePath
                                      }
                                    >
                                      Panel File
                                    </a>
                                    {" is created successfully"}
                                  </p>
                                  <hr />
                                  <p>
                                    <Link
                                      to="#!"
                                      onClick={() =>
                                        this.generateErrorTable("pytha")
                                      }
                                    >
                                      <Button
                                        variant="success"
                                        className="mr-2"
                                      >
                                        View Log
                                      </Button>
                                    </Link>
                                    <Button
                                      variant="default"
                                      className="mr-2"
                                      hidden={
                                        !this.state.pythaFileHidden ||
                                        this.state.role ===
                                        params.ROLE.PRODUCTION_MANAGER
                                      }
                                      onClick={() =>
                                        this.setState({
                                          pythaFileHidden: false,
                                        })
                                      }
                                    >
                                      <i className="mr-2"></i>
                                      Upload Again
                                    </Button>
                                  </p>
                                </div>
                                <div
                                  className="callout callout-warning"
                                  style={{
                                    display:
                                      this.state.pythaFileStatus === "warning"
                                        ? ""
                                        : "none",
                                  }}
                                >
                                  <h5>
                                    <p className="text-warning">
                                      <b>WARNING!</b>
                                    </p>
                                  </h5>
                                  <p>
                                    <a
                                      href={
                                        process.env.REACT_APP_STORAGE +
                                        "/" +
                                        this.state.panelFilePath
                                      }
                                    >
                                      Panel File
                                    </a>
                                    {" is created successfully"}
                                  </p>
                                  <hr />
                                  <p>
                                    <Link
                                      to="#!"
                                      onClick={() =>
                                        this.generateErrorTable("pytha")
                                      }
                                    >
                                      <Button
                                        variant="warning"
                                        className="mr-2"
                                      >
                                        View Log
                                      </Button>
                                    </Link>
                                    <Button
                                      variant="default"
                                      className="mr-2"
                                      hidden={
                                        !this.state.pythaFileHidden ||
                                        this.state.role ===
                                        params.ROLE.PRODUCTION_MANAGER
                                      }
                                      onClick={() =>
                                        this.setState({
                                          pythaFileHidden: false,
                                        })
                                      }
                                    >
                                      <i className="mr-2"></i>
                                      Upload Again
                                    </Button>
                                  </p>
                                </div>
                                <div
                                  className="callout callout-danger"
                                  style={{
                                    display:
                                      this.state.pythaFileStatus === "error"
                                        ? ""
                                        : "none",
                                  }}
                                >
                                  <h5>
                                    <p className="text-danger">ERROR!</p>
                                  </h5>
                                  <p>{"Pytha file is failed!"}</p>
                                  <hr />
                                  <p>
                                    <Link
                                      to="#!"
                                      onClick={() =>
                                        this.generateErrorTable("pytha")
                                      }
                                    >
                                      <Button variant="danger" className="mr-2">
                                        View Log
                                      </Button>
                                    </Link>
                                    <Button
                                      variant="default"
                                      className="mr-2"
                                      hidden={
                                        !this.state.pythaFileHidden ||
                                        this.state.role ===
                                        params.ROLE.PRODUCTION_MANAGER
                                      }
                                      onClick={() =>
                                        this.setState({
                                          pythaFileHidden: false,
                                        })
                                      }
                                    >
                                      <i className="mr-2"></i>
                                      Upload Again
                                    </Button>
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                        <Card
                          style={{
                            display:
                              this.state.pythaFileStatus === "success" ||
                                this.state.pythaFileStatus === "warning"
                                ? ""
                                : "none",
                          }}
                        >
                          <Card.Header>
                            <Card.Title>Step 2</Card.Title>
                            <div className="card-tools">
                              <Button
                                variant="tool"
                                data-card-widget="collapse"
                              >
                                <i className="fas fa-minus" />
                              </Button>
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col md={12}>
                                <Form.Group>
                                  <Form.Label>Opticut Export File *</Form.Label>
                                  <InputGroup
                                    style={{
                                      display:
                                        (this.state.exportFileStatus ===
                                          "success" ||
                                          this.state.exportFileStatus ===
                                          "warning" ||
                                          this.state.exportFileStatus ===
                                          "error") &&
                                          this.state.exportFileHidden
                                          ? "none"
                                          : "",
                                    }}
                                  >
                                    <div className="custom-file">
                                      <Form.File
                                        name="exportFile"
                                        className="custom-file-input"
                                        onChange={this.handleOnChange}
                                        onClick={(e) => (e.target.value = null)}
                                      />
                                      <Form.Label className="custom-file-label">
                                        {this.state.exportFile
                                          ? this.state.exportFile.name
                                          : "Choose file"}
                                      </Form.Label>
                                    </div>
                                    <InputGroup.Append>
                                      <ButtonLoading
                                        id="exportFile"
                                        name="Upload"
                                        icon="fa-upload"
                                        className="btn btn-success"
                                        disabled={
                                          this.state.isLoading ||
                                          this.state.role ===
                                          params.ROLE.PRODUCTION_MANAGER
                                        }
                                        onClick={this.handleOnExportFile}
                                        hidden={
                                          this.state.role ===
                                          params.ROLE.PRODUCTION_MANAGER
                                        }
                                      />
                                    </InputGroup.Append>
                                  </InputGroup>
                                  <p
                                    style={{
                                      display: this.state.exportFilePath
                                        ? ""
                                        : "none",
                                    }}
                                  >
                                    <small>
                                      <a
                                        href={
                                          process.env.REACT_APP_STORAGE +
                                          "/" +
                                          this.state.exportFilePath
                                        }
                                      >
                                        file uploaded
                                      </a>
                                      {" - click to view file"}
                                    </small>
                                  </p>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <div
                                  className="callout callout-success"
                                  style={{
                                    display:
                                      this.state.exportFileStatus === "success"
                                        ? ""
                                        : "none",
                                  }}
                                >
                                  <h5>
                                    <p className="text-success">SUCCESS!</p>
                                  </h5>
                                  <p>{"Job is created successfully"}</p>
                                  <hr />
                                  <p>
                                    <Link
                                      to="#!"
                                      onClick={() =>
                                        this.generateErrorTable("opticut")
                                      }
                                    >
                                      <Button
                                        variant="success"
                                        className="mr-2"
                                      >
                                        View Log
                                      </Button>
                                    </Link>
                                    <Button
                                      variant="default"
                                      className="mr-2"
                                      hidden={
                                        !this.state.exportFileHidden ||
                                        this.state.role ===
                                        params.ROLE.PRODUCTION_MANAGER
                                      }
                                      onClick={() =>
                                        this.setState({
                                          exportFileHidden: false,
                                        })
                                      }
                                    >
                                      <i className="mr-2"></i>
                                      Upload Again
                                    </Button>
                                  </p>
                                </div>
                                <div
                                  className="callout callout-warning"
                                  style={{
                                    display:
                                      this.state.exportFileStatus === "warning"
                                        ? ""
                                        : "none",
                                  }}
                                >
                                  <h5>
                                    <p className="text-warning">
                                      <b>WARNING!</b>
                                    </p>
                                  </h5>
                                  <p>{"Job is created successfully"}</p>
                                  <hr />
                                  <p>
                                    <Link
                                      to="#!"
                                      onClick={() =>
                                        this.generateErrorTable("opticut")
                                      }
                                    >
                                      <Button
                                        variant="warning"
                                        className="mr-2"
                                      >
                                        View Log
                                      </Button>
                                    </Link>
                                    <Button
                                      variant="default"
                                      className="mr-2"
                                      hidden={
                                        !this.state.exportFileHidden ||
                                        this.state.role ===
                                        params.ROLE.PRODUCTION_MANAGER
                                      }
                                      onClick={() =>
                                        this.setState({
                                          exportFileHidden: false,
                                        })
                                      }
                                    >
                                      <i className="mr-2"></i>
                                      Upload Again
                                    </Button>
                                  </p>
                                </div>
                                <div
                                  className="callout callout-danger"
                                  style={{
                                    display:
                                      this.state.exportFileStatus === "error"
                                        ? ""
                                        : "none",
                                  }}
                                >
                                  <h5>
                                    <p className="text-danger">ERROR!</p>
                                  </h5>
                                  <p>{"Export file is failed!"}</p>
                                  <hr />
                                  <p>
                                    <Link
                                      to="#!"
                                      onClick={() =>
                                        this.generateErrorTable("opticut")
                                      }
                                    >
                                      <Button variant="danger" className="mr-2">
                                        View Log
                                      </Button>
                                    </Link>
                                    <Button
                                      variant="default"
                                      className="mr-2"
                                      hidden={
                                        !this.state.exportFileHidden ||
                                        this.state.role ===
                                        params.ROLE.PRODUCTION_MANAGER
                                      }
                                      onClick={() =>
                                        this.setState({
                                          exportFileHidden: false,
                                        })
                                      }
                                    >
                                      <i className="mr-2"></i>
                                      Upload Again
                                    </Button>
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                        <Card
                          style={{
                            display:
                              this.state.exportFileStatus === "success" ||
                                this.state.exportFileStatus === "warning"
                                ? ""
                                : "none",
                          }}
                        >
                          <Card.Header>
                            <Card.Title>Step 3</Card.Title>
                            <div className="card-tools">
                              <Button
                                variant="tool"
                                data-card-widget="collapse"
                              >
                                <i className="fas fa-minus" />
                              </Button>
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <Form.Group as={Row}>
                              <Col md={3}>
                                <Form.Label>Job ID</Form.Label>
                              </Col>
                              <Col md={3}>{this.state.jobId}</Col>
                              <Col md={2}>
                                <Form.Label>Status</Form.Label>
                              </Col>
                              <Col md={4}>
                                <small
                                  className={
                                    this.state.jobStatus ===
                                      params.STATUS.ERROR ||
                                      this.state.jobStatus ===
                                      params.STATUS.CANCELLED
                                      ? "badge badge-danger"
                                      : this.state.jobStatus ===
                                        params.STATUS.SUCCESS ||
                                        this.state.jobStatus ===
                                        params.STATUS.CONFIRMED
                                        ? "badge badge-success"
                                        : this.state.jobStatus ===
                                          params.STATUS.WARNING ||
                                          this.state.jobStatus ===
                                          params.STATUS.PENDING
                                          ? "badge badge-warning"
                                          : this.state.jobStatus ===
                                            params.STATUS.SUBMITTED
                                            ? "badge badge-primary"
                                            : "badge badge-secondary"
                                  }
                                >
                                  {this.state.jobStatus}
                                </small>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col md={3}>
                                <Form.Label>Job Report</Form.Label>
                              </Col>
                              <Col md={9}>
                                <Row>
                                  <Col md={12}>
                                    <NavLink to={"/report/" + this.state.jobId}>
                                      Click here
                                    </NavLink>
                                  </Col>
                                </Row>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col md={3}>
                                <Form.Label>
                                  {this.state.cutFileRequired === "Y"
                                    ? "SK450 File *"
                                    : "SK450 File"}
                                </Form.Label>
                              </Col>
                              <Col md={9}>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    name="cutFilePath"
                                    value={this.state.cutFilePath}
                                    onChange={this.handleOnChange}
                                  />
                                  <InputGroup.Append>
                                    <CopyToClipboard
                                      name="Cut File Path"
                                      text={this.state.cutFilePath}
                                      onCopy={this.handleOnCopy}
                                    >
                                      <Button variant="default">
                                        <i className="fas fa-copy mr-2"></i>Copy
                                        Link
                                      </Button>
                                    </CopyToClipboard>
                                  </InputGroup.Append>
                                </InputGroup>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col md={3}>
                                <Form.Label>
                                  {this.state.drillFileRequired === "Y"
                                    ? "Rover B File *"
                                    : "Rover B File"}
                                </Form.Label>
                              </Col>
                              <Col md={9}>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    name="drillFilePath"
                                    value={this.state.drillFilePath}
                                    onChange={this.handleOnChange}
                                  />
                                  <InputGroup.Append>
                                    <CopyToClipboard
                                      name="Drill File Path"
                                      text={this.state.drillFilePath}
                                      onCopy={this.handleOnCopy}
                                    >
                                      <Button variant="default">
                                        <i className="fas fa-copy mr-2"></i>Copy
                                        Link
                                      </Button>
                                    </CopyToClipboard>
                                  </InputGroup.Append>
                                </InputGroup>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col md={3}>
                                <Form.Label>
                                  {this.state.ntFileRequired === "Y"
                                    ? "NT File *"
                                    : "NT File"}
                                </Form.Label>
                              </Col>
                              <Col md={9}>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    name="ntFilePath"
                                    value={this.state.ntFilePath}
                                    onChange={this.handleOnChange}
                                  />
                                  <InputGroup.Append>
                                    <CopyToClipboard
                                      name="NT File Path"
                                      text={this.state.ntFilePath}
                                      onCopy={this.handleOnCopy}
                                    >
                                      <Button variant="default">
                                        <i className="fas fa-copy mr-2"></i>Copy
                                        Link
                                      </Button>
                                    </CopyToClipboard>
                                  </InputGroup.Append>
                                </InputGroup>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col md={3}>
                                <Form.Label>PYO File *</Form.Label>
                              </Col>
                              <Col md={9}>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    name="assemblyFilePath"
                                    value={this.state.assemblyFilePath}
                                    onChange={this.handleOnChange}
                                  />
                                  <InputGroup.Append>
                                    <CopyToClipboard
                                      name="Assembly File Path"
                                      text={this.state.assemblyFilePath}
                                      onCopy={this.handleOnCopy}
                                    >
                                      <Button variant="default">
                                        <i className="fas fa-copy mr-2"></i>Copy
                                        Link
                                      </Button>
                                    </CopyToClipboard>
                                  </InputGroup.Append>
                                </InputGroup>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col md={3}>
                                <Form.Label>Install File *</Form.Label>
                              </Col>
                              <Col md={9}>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    name="installFilePath"
                                    value={this.state.installFilePath}
                                    onChange={this.handleOnChange}
                                  />
                                  <InputGroup.Append>
                                    <CopyToClipboard
                                      name="Install File Path"
                                      text={this.state.installFilePath}
                                      onCopy={this.handleOnCopy}
                                    >
                                      <Button variant="default">
                                        <i className="fas fa-copy mr-2"></i>Copy
                                        Link
                                      </Button>
                                    </CopyToClipboard>
                                  </InputGroup.Append>
                                </InputGroup>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col md={3}>
                                <Form.Label>Opticut File *</Form.Label>
                              </Col>
                              <Col md={9}>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    name="opticutFilePath"
                                    value={this.state.opticutFilePath}
                                    onChange={this.handleOnChange}
                                  />
                                  <InputGroup.Append>
                                    <CopyToClipboard
                                      name="Report File Path"
                                      text={this.state.opticutFilePath}
                                      onCopy={this.handleOnCopy}
                                    >
                                      <Button variant="default">
                                        <i className="fas fa-copy mr-2"></i>Copy
                                        Link
                                      </Button>
                                    </CopyToClipboard>
                                  </InputGroup.Append>
                                </InputGroup>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col md={3}>
                                <Form.Label>Purchasing Doc *</Form.Label>
                              </Col>
                              <Col md={9}>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    name="purchasingDocPath"
                                    value={this.state.purchasingDocPath}
                                    onChange={this.handleOnChange}
                                  />
                                  <InputGroup.Append>
                                    <CopyToClipboard
                                      name="Report File Path"
                                      text={this.state.purchasingDocPath}
                                      onCopy={this.handleOnCopy}
                                    >
                                      <Button variant="default">
                                        <i className="fas fa-copy mr-2"></i>Copy
                                        Link
                                      </Button>
                                    </CopyToClipboard>
                                  </InputGroup.Append>
                                </InputGroup>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col md={3}>
                                <Form.Label>Note</Form.Label>
                              </Col>
                              <Col md={9}>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  name="jobNote"
                                  value={this.state.jobNote}
                                  onChange={this.handleOnChange}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col md={3}>
                                <Button
                                  variant="default"
                                  href={
                                    "/job/" + this.state.jobId + "/print/job"
                                  }
                                  target="_blank"
                                >
                                  <i className="fas fa-print mr-2"></i>Print
                                </Button>
                              </Col>
                              <Col md={9}>
                                <ButtonLoading
                                  name="Submit"
                                  value={params.STATUS.SUBMITTED}
                                  className="btn btn-primary mr-2 float-right"
                                  disabled={
                                    this.state.isLoading ||
                                    this.state.role ===
                                    params.ROLE.PRODUCTION_MANAGER
                                  }
                                  hidden={
                                    this.state.jobStatus ===
                                    params.STATUS.SUBMITTED ||
                                    this.state.jobStatus ===
                                    params.STATUS.CONFIRMED ||
                                    this.state.role ===
                                    params.ROLE.PRODUCTION_MANAGER
                                  }
                                  onClick={this.handleOnStatusJob}
                                />
                                <ButtonLoading
                                  name="Save"
                                  value={params.STATUS.DRAFT}
                                  className="btn btn-default mr-2 float-right"
                                  disabled={
                                    this.state.isLoading ||
                                    this.state.role ===
                                    params.ROLE.PRODUCTION_MANAGER
                                  }
                                  hidden={
                                    this.state.jobStatus ===
                                    params.STATUS.SUBMITTED ||
                                    this.state.jobStatus ===
                                    params.STATUS.CONFIRMED ||
                                    this.state.role ===
                                    params.ROLE.PRODUCTION_MANAGER
                                  }
                                  onClick={this.handleOnStatusJob}
                                />
                                <ButtonLoading
                                  name="Cancel"
                                  value={params.STATUS.CANCELLED}
                                  className="btn btn-danger mr-2 float-right"
                                  disabled={
                                    this.state.isLoading ||
                                    this.state.role ===
                                    params.ROLE.PRODUCTION_MANAGER
                                  }
                                  hidden={
                                    this.state.jobStatus ===
                                    params.STATUS.DRAFT ||
                                    this.state.jobStatus ===
                                    params.STATUS.PENDING ||
                                    this.state.role ===
                                    params.ROLE.PRODUCTION_MANAGER
                                  }
                                  onClick={this.handleOnStatusJob}
                                />
                                <ButtonLoading
                                  name="Confirm"
                                  value={params.STATUS.CONFIRMED}
                                  className="btn btn-success mr-2 float-right"
                                  disabled={
                                    this.state.isLoading ||
                                    this.state.role ===
                                    params.ROLE.PRODUCTION_MANAGER
                                  }
                                  onClick={this.handleOnStatusJob}
                                  hidden={
                                    this.state.jobStatus ===
                                    params.STATUS.DRAFT ||
                                    this.state.jobStatus ===
                                    params.STATUS.PENDING ||
                                    this.state.jobStatus ===
                                    params.STATUS.CONFIRMED ||
                                    this.state.role ===
                                    params.ROLE.PRODUCTION_MANAGER
                                  }
                                />
                              </Col>
                            </Form.Group>
                          </Card.Body>
                        </Card>
                      </Col>

                      <ActivityLog></ActivityLog>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
        <PageLoader marginTop="45%" loading={this.state.isLoading} />
      </div>
    );
  }
}

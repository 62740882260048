import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Error404 extends Component {
  render() {
    return (
      <div className="hold-transition login-page">
        <section className="content">
          <div className="error-page">
            <h2 className="headline text-warning">404</h2>
            <div className="error-content">
              <h3>
                <i className="fas fa-exclamation-triangle text-warning" /> Oops!
                Page not found.
              </h3>
              <p>
                We could not find the page you were looking for.<br/>Meanwhile, you
                may <Link to="/">return to home</Link>.
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Container, Row, Col, Breadcrumb, Button } from "react-bootstrap";

export default class PageHeader extends Component {
  render() {
    return (
      <section className="content-header">
        <Container fluid>
          <Row className="mb-2">
            <Col sm="6">
              <h1>
                {this.props.title}
                <small>
                  {this.props.subtitle ? " : " + this.props.subtitle : ""}
                </small>
              </h1>
            </Col>
            <Col sm="6">
              <NavLink
                exact
                to={this.props.buttonUrl ? this.props.buttonUrl : ""}
                hidden={this.props.button}
              >
                <Button variant="primary" className="float-sm-right">
                  <i className="fas fa-plus mr-2" />
                  {"Create " + this.props.title}
                </Button>
              </NavLink>
              <Breadcrumb
                className="float-sm-right"
                hidden={this.props.breadcrumb}
              >
                <Breadcrumb.Item
                  linkAs={NavLink}
                  linkProps={{
                    to: this.props.prevUrl ? this.props.prevUrl : "",
                  }}
                >
                  {this.props.prevDir}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{this.props.activeDir}</Breadcrumb.Item>
              </Breadcrumb>
              {this.props.children}
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

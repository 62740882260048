import React, { Component } from "react";
import { Card } from "react-bootstrap";

import PageLoader from "./PageLoader";

export default class LoginBox extends Component {
  render() {
    return (
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="login-logo">
            <b>D2P</b> Cabinet
          </div>
          <Card>
            <Card.Body className="login-card-body">
              <p className="login-box-msg">{this.props.description}</p>
              {this.props.children}
            </Card.Body>
            <PageLoader loading={this.props.isLoading} marginTop="45%" />
          </Card>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Card, Image, Form } from "react-bootstrap";

import Header from "../components/Header";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import PageLoader from "../components/PageLoader";
import ButtonLoading from "../components/ButtonLoading";

import params from "../utils/params";
import localStorage from "../utils/localStorage";
import { toastTimerSuccess, toastPopupError } from "../utils/sweetAlert";

export default class CustomerDetail extends Component {
  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getUser());
    if (!user) {
      this.state = { invalid: true };
      return;
    }

    this.state = {
      role: user.role,

      id: props.match.params.id,
      avatar: "/dist/img/avatarDefault.png",
      firstname: "",
      lastname: "",
      nickname: "",
      mobile: "",
      email: "",
      position: "",
      addrContact: "",
      addrWork: "",

      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.setState({ isLoading: true });

      axios
        .get("/customer/" + this.state.id)
        .then((success) => {
          this.setState({
            firstname: success.data.firstname,
            lastname: success.data.lastname,
            nickname: success.data.nickname,
            mobile: success.data.mobile,
            email: success.data.email ? success.data.email : "",
            position: success.data.position ? success.data.position : "",
            addrContact: success.data.addr_contact
              ? success.data.addr_contact
              : "",
            addrWork: success.data.addr_work ? success.data.addr_work : "",
          });
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  handleOnChange = (e) => {
    let val = "";

    if (e.target.type === "checkbox") {
      val = e.target.checked;
    } else {
      val = e.target.value;
    }

    this.setState({ [e.target.name]: val });
  };

  handleOnCreate = (e) => {
    e.preventDefault();

    // validate required input
    if (this.missingRequiredField()) return;

    this.setState({ isLoading: true });

    const data = new FormData();
    data.append("firstname", this.state.firstname);
    data.append("lastname", this.state.lastname);
    data.append("nickname", this.state.nickname);
    data.append("mobile", this.state.mobile);
    data.append("email", this.state.email);
    data.append("position", this.state.position);
    data.append("addrContact", this.state.addrContact);
    data.append("addrWork", this.state.addrWork);

    axios
      .post("/customer", data)
      .then((success) => {
        this.setState({
          id: success.data.item.id,
        });

        this.props.history.push("/customer/" + this.state.id);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleOnUpdate = (e) => {
    e.preventDefault();

    // validate required input
    if (this.state.id && this.missingRequiredField()) return;

    this.setState({ isLoading: true });

    const data = new FormData();
    data.append("firstname", this.state.firstname);
    data.append("lastname", this.state.lastname);
    data.append("nickname", this.state.nickname);
    data.append("mobile", this.state.mobile);
    data.append("email", this.state.email === "" ? "-" : this.state.email);
    data.append(
      "position",
      this.state.position === "" ? "-" : this.state.position
    );
    data.append(
      "addrContact",
      this.state.addrContact === "" ? "-" : this.state.addrContact
    );
    data.append(
      "addrWork",
      this.state.addrWork === "" ? "-" : this.state.addrWork
    );

    axios
      .post("/customer/" + this.state.id, data)
      .then((success) => {
        toastTimerSuccess.fire({
          title: "Customer's details has been updated",
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  missingRequiredField = (e) => {
    if (!this.state.firstname)
      return toastPopupError.fire({ title: "First Name is required!" });
    if (!this.state.lastname)
      return toastPopupError.fire({ title: "Last Name is required!" });
    if (!this.state.nickname)
      return toastPopupError.fire({ title: "Nick Name is required!" });
    if (!this.state.mobile)
      return toastPopupError.fire({ title: "Mobile is required!" });

    return false;
  };

  render() {
    if (this.state.invalid) {
      return <Redirect to="/logout" />;
    }

    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader
            title="Customer "
            subtitle={this.state.id}
            prevDir="Customer"
            prevUrl="/customer"
            activeDir="Detail"
            button="hidden"
          />

          <section className="content">
            <Container fluid>
              <Row>
                <Col md={3}>
                  <Card className="card-primary card-outline">
                    <Card.Body className="box-profile">
                      <div className="text-center">
                        <Image
                          className="profile-user-img"
                          src={this.state.avatar}
                          roundedCircle
                        />
                      </div>
                      <h3 className="profile-username text-center">
                        {this.state.firstname}
                        {this.state.nickname
                          ? " (" + this.state.nickname + ") "
                          : ""}
                      </h3>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={9}>
                  <Card>
                    <Card.Header>
                      <Card.Title>Detail</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              First Name *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="firstname"
                              value={this.state.firstname}
                              onChange={this.handleOnChange}
                              autoComplete="false"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Last Name *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="lastname"
                              value={this.state.lastname}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Nick Name *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="nickname"
                              value={this.state.nickname}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Mobile *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="mobile"
                              value={this.state.mobile}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Email
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              type="email"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Position
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="position"
                              value={this.state.position}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Address (Contact)
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="addrContact"
                              value={this.state.addrContact}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Address (Work)
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="addrWork"
                              value={this.state.addrWork}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="offset-sm-2">
                          <ButtonLoading
                            name="Submit"
                            className="btn btn-success"
                            hidden={
                              this.state.id ||
                              this.state.role === params.ROLE.DRAFT ||
                              this.state.role === params.ROLE.PURCHASE
                                ? true
                                : false
                            }
                            disabled={
                              this.state.isLoading ||
                              this.state.role === params.ROLE.DRAFT ||
                              this.state.role === params.ROLE.PURCHASE
                            }
                            onClick={this.handleOnCreate}
                          />
                          <ButtonLoading
                            name="Save"
                            className="btn btn-success"
                            hidden={
                              !this.state.id ||
                              this.state.role === params.ROLE.DRAFT ||
                              this.state.role === params.ROLE.PURCHASE
                                ? true
                                : false
                            }
                            disabled={
                              this.state.isLoading ||
                              this.state.role === params.ROLE.DRAFT ||
                              this.state.role === params.ROLE.PURCHASE
                            }
                            onClick={this.handleOnUpdate}
                          />
                        </Form.Group>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
        <PageLoader marginTop="30%" loading={this.state.isLoading} />
      </div>
    );
  }
}

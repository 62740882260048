import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

import LoginBox from "../components/LoginBox";
import ButtonLoading from "../components/ButtonLoading";
import { toastTimerSuccess } from "../utils/sweetAlert";

export default class PasswordForget extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      isLoading: false,
    };
  }

  handleOnChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    this.setState({
      isLoading: true,
    });

    axios
      .post("/password/create", { email: this.state.email })
      .then((success) => {
        toastTimerSuccess.fire({
          title: "Reset password link has been sent!",
        });

        this.props.history.push("/");
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <LoginBox
        isLoading={this.state.isLoading}
        description="You forgot your password? Here you can easily retrieve a newpassword."
      >
        <form onSubmit={this.handleOnSubmit}>
          <div className="input-group mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleOnChange}
              autoFocus
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-envelope" />
              </div>
            </div>
          </div>

          <p
            style={{
              color: "red",
              display: this.state.isMsgDisplay,
            }}
          >
            {this.state.isMsg}
          </p>

          <div className="row">
            <div className="col-12">
              <ButtonLoading
                name="Reset Password"
                className="btn btn-primary btn-block"
                disabled={this.state.isLoading}
              />
            </div>
            {/* /.col */}
          </div>
        </form>
        <p className="mt-3 mb-1">
          <NavLink exact to="/" className="mb-3">
            Back to Login
          </NavLink>
        </p>
      </LoginBox>
    );
  }
}

import React, { Component } from "react";
import { Col, Card, Button } from "react-bootstrap";

export default class ActivityLog extends Component {
  render() {
    return (
      <Col md={4}>
        <Card className="collapsed-card">
          <Card.Header>
            <Card.Title>Activity Log</Card.Title>
            <div className="card-tools">
              <Button variant="tool" data-card-widget="collapse">
                <i className="fas fa-plus" />
              </Button>
            </div>
          </Card.Header>
          <Card.Body className="p-0">
            <ul className="products-list product-list-in-card pl-2 pr-2">
              <li className="item">
                <div>Coming Soon ...</div>
              </li>
            </ul>
          </Card.Body>
        </Card>
      </Col>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";

import localStorage from "../utils/localStorage";

export default class Logout extends Component {
  constructor() {
    super();
    this.state = {
      isDone: false,
    };
  }

  componentDidMount() {
    axios
      .get("/auth/logout")
      .finally(() => {
        localStorage.clearStorage();
        this.setState({ isDone: true });
      });
  }

  render() {
    return this.state.isDone ? (
      <Redirect to="/" />
    ) : (
      <div>
        <b>Logged out.</b>
        <br /> This page will be redirected in a few seconds ...
      </div>
    );
  }
}

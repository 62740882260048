import React, { Component } from "react";
import { Container, Row, Col, Card, Table, Form } from "react-bootstrap";
import axios from "axios";

import PageLoader from "../components/PageLoader";

export default class JobPrintDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      isLoading: true,
      jobStatus: "",
      project: "",
      roomCode: "",
      createdAt: new Date(),
      countError: "",
      countPanel: "",
      countBar: "",
      countBF: "",
      countFur: "",
      cutFilePath: "",
      cutFileRequired: "",
      drillFilePath: "",
      drillFileRequired: "",
      ntFilePath: "",
      ntFileRequired: "",
      assemblyFilePath: "",
      installFilePath: "",
      opticutFilePath: "",
      purchasingDocPath: "",
      jobNote: "",
      staffFirstname: "",
      staffLastname: "",
      staffNickname: "",
      staffEmail: "",
      customerFirstname: "",
      customerLastname: "",
      customerNickname: "",
    };
  }

  componentDidMount = async () => {
    await axios.get("/job/" + this.state.id).then((success) => {
      this.setState({
        jobStatus: success.data.job.status,
        project: success.data.roomCode.project_id,
        roomCode: success.data.roomCode.room_code,
        createdId: success.data.job.created_by,
        createdAt: success.data.job.created_at,
        cutFilePath: success.data.job.cut_file_path,
        cutFileRequired: success.data.job.cut_file_required,
        drillFilePath: success.data.job.drill_file_path,
        drillFileRequired: success.data.job.drill_file_required,
        ntFilePath: success.data.job.nt_file_path,
        ntFileRequired: success.data.job.nt_file_required,
        assemblyFilePath: success.data.job.assembly_file_path,
        installFilePath: success.data.job.install_file_path,
        opticutFilePath: success.data.job.opticut_file_path,
        purchasingDocPath: success.data.job.purchasing_doc_path,
        jobNote: success.data.job.note,
      });
    });

    // Promise all
    let fetchData1 = axios
      .get("/staff/" + this.state.createdId + "/user")
      .then((res1) => {
        this.setState({
          staffFirstname: res1.data.firstname,
          staffLastname: res1.data.lastname,
          staffNickname: res1.data.nickname,
          staffEmail: res1.data.email,
        });
      });

    let fetchData2 = axios
      .get("/project/" + this.state.project)
      .then((res1) => {
        this.setState({
          customerFirstname: res1.data.customer.firstname,
          customerLastname: res1.data.customer.lastname,
          customerNickname: res1.data.customer.nickname,
        });
      });
    let fetchData3 = axios
      .get("/job/" + this.state.id + "/stat")
      .then((res1) => {
        this.setState({
          countError: res1.data.error,
          countPanel: res1.data.panel,
          countBar: res1.data.bar,
          countBF: res1.data.bf,
          countFur: res1.data.fur,
        });
      });

    Promise.all([fetchData1, fetchData2, fetchData3]).then((success) => {
      this.setState({ isLoading: false });
      window.addEventListener("load", window.print());
    });
  };

  render() {
    return (
      <section className="invoice">
        <Container fluid>
          <Card>
            <Card.Header>
              <h2 className="page-header">
                D2P Cabinet Solutions (Job)
                <small className="float-right">
                  {"Printed Date: " +
                    new Date().getDate() +
                    "/" +
                    (new Date().getMonth() + 1) +
                    "/" +
                    new Date().getFullYear()}
                </small>
              </h2>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={12}>
                  {/* info row */}
                  <PageLoader loading={this.state.isLoading} marginTop="25%" />
                  <Row className="invoice-info">
                    <Col sm={4} className="invoice-col">
                      Project
                      <address>
                        <strong>
                          {this.state.project + " / " + this.state.roomCode}
                        </strong>
                        <br />
                        <br />
                        {this.state.customerFirstname +
                          " " +
                          this.state.customerLastname +
                          " (" +
                          this.state.customerNickname +
                          ")"}
                      </address>
                    </Col>

                    <Col sm={4} className="invoice-col">
                      <b>Job ID #{this.state.id}</b>
                      <br />
                      <br />
                      <b>Status:</b>
                      {" " + this.state.jobStatus}
                      <br />
                      <b>Created at:</b>{" "}
                      {new Date(this.state.createdAt).toLocaleString("en-GB")}
                      <br />
                      <b>Created by:</b>{" "}
                      {this.state.staffFirstname +
                        " (" +
                        this.state.staffNickname +
                        ")"}
                    </Col>
                    <Col sm={4} className="invoice-col">
                      <b>Total Remaining Error:</b>{" "}
                      {this.state.countError + " errors"}
                      <br />
                      <b>Total Cutting Part:</b>{" "}
                      {this.state.countPanel + " pcs."}
                      <br />
                      <b>Total Cutting Bar:</b> {this.state.countBar + " pcs."}
                      <br />
                      <b>Total BF:</b> {this.state.countBF + " BFCodes"}
                      <br />
                      <b>Total Furcode:</b> {this.state.countFur + " Furcodes"}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={12}>
                      <Table striped responsive>
                        <thead>
                          <tr>
                            <th>Need Files?</th>
                            <th>List</th>
                            <th>File Path</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{this.state.cutFileRequired}</td>
                            <td>SK450 File</td>
                            <td>{this.state.cutFilePath}</td>
                          </tr>
                          <tr>
                            <td>{this.state.drillFileRequired}</td>
                            <td>Rover B File</td>
                            <td>{this.state.drillFilePath}</td>
                          </tr>
                          <tr>
                            <td>{this.state.ntFileRequired}</td>
                            <td>NT File</td>
                            <td>{this.state.ntFilePath}</td>
                          </tr>
                          <tr>
                            <td>Y</td>
                            <td>PYO File</td>
                            <td>{this.state.assemblyFilePath}</td>
                          </tr>
                          <tr>
                            <td>Y</td>
                            <td>Install File</td>
                            <td>{this.state.installFilePath}</td>
                          </tr>
                          <tr>
                            <td>Y</td>
                            <td>Opticut File</td>
                            <td>{this.state.opticutFilePath}</td>
                          </tr>
                          <tr>
                            <td>Y</td>
                            <td>Purchasing Doc</td>
                            <td>{this.state.purchasingDocPath}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12} style={{ textAlign: "center" }}>
                      <br />
                      ----- <b>สำหรับแผนกถอดแบบ</b> -----
                      <br />
                      <br />
                      <br />
                    </Col>
                  </Row>
                  <br />
                  <Row className="invoice-info">
                    <Col sm={3} className="invoice-col">
                      <b>Draftman - รับผิดชอบโดย:</b>
                      <br />
                      <br />
                      <br />
                      <b>Draftman - รีวิวงานโดย:</b>
                      <br />
                      <br />
                      <br />
                      <b>Draftman - หมายเหตุ:</b>
                    </Col>
                    <Col sm={5} className="invoice-col">
                      {"________________________________________"}
                      <br />
                      <br />
                      <br />
                      {"________________________________________"}
                      <br />
                      <br />
                      <br />
                      <div style={{ whiteSpace: "pre-wrap" }}>{this.state.jobNote}</div>
                    </Col>
                    <Col sm={4} className="invoice-col">
                      <b>วันที่: </b>
                      {"_______/_______/______________"}
                      <br />
                      <br />
                      <br />
                      <b>วันที่: </b>
                      {"_______/_______/______________"}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    );
  }
}

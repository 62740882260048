import Swal from "sweetalert2";

// modal timer
export const toastTimerSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
  },
  buttonsStyling: false,
  showConfirmButton: false,
  toast: true,
  icon: "success",
  title: "General Title",
  showClass: true,
  position: "top",
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

// modal popup
export const toastPopupSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
  },
  buttonsStyling: false,
  showConfirmButton: false,
  showCloseButton: true,
  toast: false,
  icon: "success",
  title: "Success!",
  text: "",
  showClass: true,
});

export const toastPopupWarning = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-warning",
  },
  buttonsStyling: false,
  showConfirmButton: false,
  showCloseButton: true,
  toast: false,
  icon: "warning",
  title: "Warning!",
  text: "",
  showClass: true,
});

export const toastPopupError = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-danger",
  },
  buttonsStyling: false,
  showConfirmButton: false,
  showCloseButton: true,
  toast: false,
  icon: "error",
  title: "Oops!",
  text: "",
  showClass: true,
});

// modal confirm
export const toastConfirmPrimary = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary mr-2",
    denyButton: "btn btn-default",
  },
  buttonsStyling: false,
  icon: "info",
  title: "Are you sure?",
  confirmButtonText: "Submit",
  showDenyButton: true,
  showCancelButton: false,
});

export const toastConfirmSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success mr-2",
    denyButton: "btn btn-default",
  },
  buttonsStyling: false,
  icon: "warning",
  title: "Are you sure?",
  confirmButtonText: "Confirm",
  showDenyButton: true,
  showCancelButton: false,
});

export const toastConfirmWarning = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-warning mr-2",
    denyButton: "btn btn-default",
  },
  buttonsStyling: false,
  icon: "warning",
  title: "Are you sure?",
  confirmButtonText: "Pending",
  showDenyButton: true,
  showCancelButton: false,
});

export const toastConfirmDanger = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-danger mr-2",
    denyButton: "btn btn-default",
  },
  buttonsStyling: false,
  icon: "warning",
  title: "Are you sure?",
  confirmButtonText: "Cancel",
  showDenyButton: true,
  showCancelButton: false,
});

// modal error log
export const toastModalWide = Swal.mixin({
  width: "1000px",
  title: "Title!",
  icon: "warning",
  html: "<b>Html tags</b>",
  showConfirmButton: false,
  showCloseButton: true,
});

// modal confirm with input
export const toastInputDanger = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-danger mr-2",
    denyButton: "btn btn-default",
  },
  buttonsStyling: false,
  icon: "warning",
  title: "Are you sure?\nAll data will be lost!",
  text: "Please enter the cancel reason",
  input: "text",
  confirmButtonText: "Cancel",
  showDenyButton: true,
  showCancelButton: false,
  preConfirm: (val) => {
    if (val === "")
      Swal.showValidationMessage("Cancel reason cannot be empty!");
  },
});

// modal html
export const toastHtmlSubproject = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary mr-2",
    cancelButton: "btn btn-default mr-2",
  },
  buttonsStyling: false,
  icon: "info",
  title: "Sub Project name?",
  html: `<input type="text" id="iName" class="swal2-input">
  <input type="checkbox" id="iCheck"> Just a default group "without" sub project`,
  confirmButtonText: "Submit",
  cancelButtonText: "Cancel",
  showConfirmButton: true,
  showCancelButton: true,
  preConfirm: () => {
    const iName = Swal.getPopup().querySelector("#iName").value;
    const iCheck = Swal.getPopup().querySelector("#iCheck").checked;

    if (iName === "" && !iCheck) {
      Swal.showValidationMessage("Input cannot be empty!");
    }

    if (iName !== "" && iCheck) {
      Swal.showValidationMessage("Input cannot be both!");
    }

    return { name: iName, check: iCheck };
  },
});

export const toastHtmlRoomcode = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary mr-2",
    cancelButton: "btn btn-default mr-2",
  },
  buttonsStyling: false,
  icon: "info",
  title: "Room codes?",
  html: `Internal:<input type="text" id="roomCodeInternal" class="form-control"><br/>
  Customer:<input type="text" id="roomCodeCustomer" class="form-control">`,
  confirmButtonText: "Submit",
  cancelButtonText: "Cancel",
  showConfirmButton: true,
  showCancelButton: true,
  preConfirm: () => {
    const roomCodeInternal =
      Swal.getPopup().querySelector("#roomCodeInternal").value;
    const roomCodeCustomer =
      Swal.getPopup().querySelector("#roomCodeCustomer").value;

    if (roomCodeInternal === "") {
      Swal.showValidationMessage("Room code (Internal) cannot be empty!");
    }

    if (roomCodeCustomer === "") {
      Swal.showValidationMessage("Room code (Customer) cannot be empty!");
    }

    return {
      roomCodeInternal: roomCodeInternal,
      roomCodeCustomer: roomCodeCustomer,
    };
  },
});

export const toastHtmlJobId = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary mr-2",
    cancelButton: "btn btn-default mr-2",
  },
  buttonsStyling: false,
  icon: "info",
  title: "Job ID?",
  html: "",
  confirmButtonText: "Submit",
  cancelButtonText: "Cancel",
  showConfirmButton: true,
  showCancelButton: true,
  preConfirm: () => {
    return {
      jobId: Swal.getPopup().querySelector("#jobId").value,
    };
  },
});

export const toastHtmlStatus = Swal.mixin({
  width: "1200px",
  customClass: {
    confirmButton: "btn btn-primary mr-2",
    cancelButton: "btn btn-default mr-2",
  },
  buttonsStyling: false,
  icon: "info",
  title: "Room codes?",
  html: "",
  confirmButtonText: "Update",
  cancelButtonText: "Cancel",
  showConfirmButton: true,
  showCancelButton: true,
  preConfirm: () => {
    return {
      startDate: Swal.getPopup().querySelector("#startDate").value,
      deadlineDate: Swal.getPopup().querySelector("#deadlineDate").value,
      note: Swal.getPopup().querySelector("#note").value,
      purchaseStatus: Swal.getPopup().querySelector("#purchaseStatus").value,
      purchaseDateStart:
        Swal.getPopup().querySelector("#purchaseDateStart").value,
      purchaseDateTarget: Swal.getPopup().querySelector("#purchaseDateTarget")
        .value,
      purchaseNote: Swal.getPopup().querySelector("#purchaseNote").value,
      purchaseAssignee:
        Swal.getPopup().querySelector("#purchaseAssignee").value,
      machineStatus: Swal.getPopup().querySelector("#machineStatus").value,
      machineDateStart:
        Swal.getPopup().querySelector("#machineDateStart").value,
      machineDateTarget:
        Swal.getPopup().querySelector("#machineDateTarget").value,
      machineNote: Swal.getPopup().querySelector("#machineNote").value,
      machineAssignee: Swal.getPopup().querySelector("#machineAssignee").value,
      assemblyStatus: Swal.getPopup().querySelector("#assemblyStatus").value,
      assemblyDateStart:
        Swal.getPopup().querySelector("#assemblyDateStart").value,
      assemblyDateTarget: Swal.getPopup().querySelector("#assemblyDateTarget")
        .value,
      assemblyNote: Swal.getPopup().querySelector("#assemblyNote").value,
      assemblyAssignee:
        Swal.getPopup().querySelector("#assemblyAssignee").value,
      mockupStatus: Swal.getPopup().querySelector("#mockupStatus").value,
      mockupDateStart: Swal.getPopup().querySelector("#mockupDateStart").value,
      mockupDateTarget:
        Swal.getPopup().querySelector("#mockupDateTarget").value,
      mockupNote: Swal.getPopup().querySelector("#mockupNote").value,
      mockupAssignee: Swal.getPopup().querySelector("#mockupAssignee").value,
      paintStatus: Swal.getPopup().querySelector("#paintStatus").value,
      paintDateStart: Swal.getPopup().querySelector("#paintDateStart").value,
      paintDateTarget: Swal.getPopup().querySelector("#paintDateTarget").value,
      paintNote: Swal.getPopup().querySelector("#paintNote").value,
      paintAssignee: Swal.getPopup().querySelector("#paintAssignee").value,
      shipStatus: Swal.getPopup().querySelector("#shipStatus").value,
      shipDateStart: Swal.getPopup().querySelector("#shipDateStart").value,
      shipDateTarget: Swal.getPopup().querySelector("#shipDateTarget").value,
      shipNote: Swal.getPopup().querySelector("#shipNote").value,
      shipAssignee: Swal.getPopup().querySelector("#shipAssignee").value,
      installStatus: Swal.getPopup().querySelector("#installStatus").value,
      installDateStart:
        Swal.getPopup().querySelector("#installDateStart").value,
      installDateTarget:
        Swal.getPopup().querySelector("#installDateTarget").value,
      installNote: Swal.getPopup().querySelector("#installNote").value,
      installAssignee: Swal.getPopup().querySelector("#installAssignee").value,
      deliveryStatus: Swal.getPopup().querySelector("#deliveryStatus").value,
      deliveryDateStart:
        Swal.getPopup().querySelector("#deliveryDateStart").value,
      deliveryDateTarget: Swal.getPopup().querySelector("#deliveryDateTarget")
        .value,
      deliveryNote: Swal.getPopup().querySelector("#deliveryNote").value,
      deliveryAssignee:
        Swal.getPopup().querySelector("#deliveryAssignee").value,
      iCheck: Swal.getPopup().querySelector("#iCheck").checked,
    };
  },
});

import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import axios from "axios";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";

import PageLoader from "../components/PageLoader";

import params from "../utils/params";
import localStorage from "../utils/localStorage";

import { toastHtmlRoomcode } from "../utils/sweetAlert";

export default class RoomCodeTable extends Component {
  COLUMNS = [
    {
      name: "Room Code (Internal)",
      selector: "room_code_internal",
      wrap: true,
      sortable: true,
    },
    {
      name: "Room Code (Customer)",
      selector: "room_code_customer",
      wrap: true,
      sortable: true,
    },

    {
      name: "Status",
      width: "10%",
      selector: "status",
      sortable: false,
      cell: (row) => (
        <small
          className={
            row.status === params.STATUS.OPEN
              ? "badge badge-success"
              : row.status === params.STATUS.CLOSED
              ? "badge badge-danger"
              : "badge badge-secondary"
          }
        >
          {row.status ? row.status : "N/A"}
        </small>
      ),
    },
    {
      name: "Job ID",
      width: "10%",
      selector: "job_id",
      sortable: true,
      wrap: true,
      cell: (row) => (
        <NavLink exact to={"/report/" + row.job_id}>
          {row.job_id}
        </NavLink>
      ),
    },
    {
      name: "Updated At",
      width: "15%",
      selector: "updated_at",
      sortable: true,
      wrap: true,
      cell: (row) => (
        <span>
          {row.job ? new Date(row.job.updated_at).toLocaleString("en-GB") : ""}
        </span>
      ),
    },
    {
      name: "Action",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      cell: (row) => (
        <div>
          <Button
            id={row.id}
            className="fas fa-edit"
            variant="tool"
            onClick={this.handleOnUpdateRoomCode}
          ></Button>
          <Button
            id={row.id}
            className="fas fa-trash-alt"
            variant="tool"
            onClick={this.handleOnDeleteRoomCode}
            hidden
          ></Button>
        </div>
      ),
    },
  ];

  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getUser());
    if (!user) {
      this.state = { invalid: true };
      return;
    }

    this.state = {
      isLoading: false,
      role: user.role,

      roomcodeList: [],
      projectId: this.props.projectId,
      subproject: this.props.subproject,

      customStyles: {
        headCells: {
          style: {
            fontSize: 16,
            fontWeight: "bold",
            padding: "5px",
          },
        },
        cells: {
          style: {
            fontSize: 16,
            padding: "5px",
          },
        },
      },
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getRoomCode();
  }

  handleOnCreateRoomCode = (e) => {
    toastHtmlRoomcode.fire().then((result) => {
      if (result.isConfirmed) {
        this.setState({ isLoading: true });

        const data = new FormData();
        data.append("projectId", this.state.projectId);
        data.append("subproject", this.state.subproject);
        data.append(
          "roomCodeInternal",
          result.value.roomCodeInternal.toUpperCase().replace(/\s+/g, "")
        );
        data.append(
          "roomCodeCustomer",
          result.value.roomCodeCustomer.toUpperCase()
        );

        axios
          .post("/roomcode", data)
          .then((success) => {
            this.getRoomCode();
          })
          .catch((e) => {
            this.setState({ isLoading: false });
          });
      }
    });
  };

  handleOnUpdateRoomCode = (e) => {
    let roomCodeId = e.target.id;

    this.setState({ isLoading: true });

    axios.get("/roomcode/" + roomCodeId).then((success) => {
      this.setState({ isLoading: false });

      toastHtmlRoomcode
        .fire({
          showConfirmButton:
            this.state.role === params.ROLE.ACCOUNTING ||
            this.state.role === params.ROLE.DRAFT ||
            this.state.role === params.ROLE.PURCHASE
              ? false
              : true,
          title: "Update Room Codes?",
          confirmButtonText: "Update",
          html:
            `Internal:<input type="text" id="roomCodeInternal" class="form-control" value="` +
            success.data.room_code_internal +
            `"><br/>
  Customer:<input type="text" id="roomCodeCustomer" class="form-control" value="` +
            success.data.room_code_customer +
            `">`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.setState({ isLoading: true });

            const data = new FormData();
            data.append(
              "roomCodeInternal",
              result.value.roomCodeInternal.toUpperCase().replace(/\s+/g, "")
            );
            data.append(
              "roomCodeCustomer",
              result.value.roomCodeCustomer.toUpperCase()
            );

            axios
              .post("/roomcode/" + roomCodeId, data)
              .then((success) => {
                this.getRoomCode();
              })
              .catch((e) => {
                this.setState({ isLoading: false });
              });
          }
        });
    });
  };

  getRoomCode = () => {
    axios
      .get("/roomcode/" + this.props.projectId + "/" + this.props.subproject)
      .then((success) => {
        this.setState({
          roomcodeList: success.data,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    if (this.state.invalid) {
      return <Redirect to="/logout" />;
    }

    return (
      <div>
        <PageLoader marginTop="45%" loading={this.state.isLoading} />

        <DataTable
          columns={this.COLUMNS}
          data={this.state.roomcodeList}
          customStyles={this.state.customStyles}
          highlightOnHover
          noHeader={true}
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 50, 100]}
        />
        <Button
          variant="success"
          size={"sm"}
          onClick={this.handleOnCreateRoomCode}
          hidden={
            this.state.role === params.ROLE.ACCOUNTING ||
            this.state.role === params.ROLE.DRAFT ||
            this.state.role === params.ROLE.PURCHASE
          }
        >
          <i className="fas fa-plus mr-2" />
          Add Room Code
        </Button>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import localStorage from "../utils/localStorage";

export default class ForceLogin extends Component {
  constructor() {
    super();
    this.state = { redirect: false, countdown: 5 };
    
    localStorage.clearStorage();
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ redirect: true });
    }, 5000);

    setInterval(() => {
      if (!this.state.redirect) {
        this.setState({ countdown: this.state.countdown - 1 });
      }
    }, 1000);
  }

  render() {
    return (
      <div>
        {this.state.redirect ? (
          <Redirect to="/" />
        ) : (
          <div>
            <b>{this.props.message}</b>
            <br /> This page will be redirected in {this.state.countdown}{" "}
            seconds ...
          </div>
        )}
      </div>
    );
  }
}

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

import LoginBox from "../components/LoginBox";
import ButtonLoading from "../components/ButtonLoading";
import localStorage from "../utils/localStorage";
import { toastPopupError } from "../utils/sweetAlert";

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      rememberMe: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    if (localStorage.getAccessToken()) {
      this.props.history.push("/dashboard");
    }
  }

  handleOnChange = (e) => {
    let val = "";

    if (e.target.type === "checkbox") {
      val = e.target.checked;
    } else if (e.target.type === "file") {
      val = e.target.files[0];
    } else {
      val = e.target.value;
    }

    this.setState({ [e.target.name]: val });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    // validate required input
    if (this.missingRequiredField()) return;

    this.setState({ isLoading: true });
    axios
      .post("/auth/login", JSON.stringify(this.state))
      .then(() => {
        this.props.history.push("/dashboard");
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  missingRequiredField = (e) => {
    if (!this.state.email)
      return toastPopupError.fire({ title: "Email is required!" });
    if (!this.state.password)
      return toastPopupError.fire({ title: "Password is required!" });

    return false;
  };

  render() {
    return (
      <LoginBox
        isLoading={this.state.isLoading}
        description="Log in to start your session"
      >
        <form onSubmit={this.handleOnSubmit}>
          <div className="input-group mb-3">
            <input
              name="email"
              type="email"
              className="form-control"
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleOnChange}
              autoFocus
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-envelope" />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input
              name="password"
              type="password"
              className="form-control"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handleOnChange}
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-lock" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-8">
              <div className="icheck-primary">
                <input
                  name="rememberMe"
                  type="checkbox"
                  id="rememberMe"
                  onClick={this.handleOnChange}
                />
                <label htmlFor="rememberMe">Remember Me</label>
              </div>
            </div>
            <div className="col-4">
              <ButtonLoading
                name="Log In"
                className="btn btn-primary btn-block"
                disabled={this.state.isLoading}
                onClick={this.handleOnCreate}
              />
            </div>
          </div>
        </form>
        <p className="mt-3 mb-1">
          <NavLink exact to="/password/forget" className="mb-3">
            I forgot my password
          </NavLink>
        </p>
      </LoginBox>
    );
  }
}

import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { Button, Nav, Image } from "react-bootstrap";

import localStorage from "../utils/localStorage";

export default class Header extends Component {
  constructor() {
    super();

    let user = JSON.parse(localStorage.getUser());
    if (!user) {
      this.state = { invalid: true };
      return;
    }

    this.state = {
      // user
      id: user.id,
      username: user.username,
      role: user.role,
      roleId: user.role_id,

      // info
      firstname: user.firstname,
      lastname: user.lastname,
      nickname: user.nickname,
      email: user.email,
      position: user.position,
    };
  }

  render() {
    if (this.state.invalid) {
      return <Redirect to="/logout" />;
    }

    return (
      <Nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <Nav.Item className="nav-item">
            <Nav.Link data-widget="pushmenu" role="button">
              <i className="fas fa-bars"></i>
            </Nav.Link>
          </Nav.Item>
        </ul>

        <ul className="navbar-nav ml-auto">
          <Nav.Item className="nav-item dropdown">
            <Nav.Link data-toggle="dropdown">
              <span>
                {this.state.firstname + " (" + this.state.nickname + ")"}
              </span>
            </Nav.Link>

            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <div className="card-body">
                <div className="text-center">
                  <Image
                    className="profile-user-img"
                    src="/dist/img/user8-128x128.jpg"
                    roundedCircle
                  />
                </div>
                <h3 className="profile-username text-center">
                  {this.state.firstname + " (" + this.state.nickname + ")"}
                </h3>
                <p className="text-muted text-center">{this.state.position}</p>
                <p className="text-center">
                    <small>{this.state.email}</small>
                </p>
              </div>

              <div className="card-footer">
                <NavLink exact to={"/staff/" + this.state.roleId}>
                  <Button variant="outline-dark" size="sm">
                    <i className="fas fa-user mr-2" />
                    Profile
                  </Button>
                </NavLink>
                <NavLink exact to="/logout">
                  <Button
                    variant="outline-dark"
                    size="sm"
                    className="float-right"
                  >
                    <i className="fas fa-sign-out-alt mr-2" />
                    Logout
                  </Button>
                </NavLink>
              </div>
            </div>
          </Nav.Item>

          <Nav.Item className="nav-item dropdown">
            <Nav.Link data-toggle="dropdown">
              <i className="far fa-bell" />
              <span className="badge badge-warning navbar-badge"></span>
            </Nav.Link>

            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">
                15 Notifications
              </span>
              <div className="dropdown-divider" />
              <a href="#!" className="dropdown-item">
                <i className="fas fa-envelope mr-2" /> 4 new messages
                <span className="float-right text-muted text-sm">3 mins</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#!" className="dropdown-item">
                <i className="fas fa-users mr-2" /> 8 friend requests
                <span className="float-right text-muted text-sm">12 hours</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#!" className="dropdown-item">
                <i className="fas fa-file mr-2" /> 3 new reports
                <span className="float-right text-muted text-sm">2 days</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#!" className="dropdown-item dropdown-footer">
                See All Notifications
              </a>
            </div>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link data-widget="fullscreen" role="button">
              <i className="fas fa-expand-arrows-alt" />
            </Nav.Link>
          </Nav.Item>
        </ul>
      </Nav>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import {} from "react-router-dom";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

import Header from "../components/Header";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import PageLoader from "../components/PageLoader";
import ButtonLoading from "../components/ButtonLoading";

import {
  toastPopupError,
  toastConfirmDanger,
  toastConfirmSuccess,
  toastHtmlJobId,
} from "../utils/sweetAlert";
import params from "../utils/params";
import localStorage from "../utils/localStorage";

export default class PurchaseOrderDetail extends Component {
  NEW_ID = -1; // starting no. for new item
  VAT = 0.07;
  COLUMNS = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "discountAmount",
      text: "Disc. Amount",
      type: "number",
      hidden: true,
    },
    {
      dataField: "totalAmount",
      text: "Total Amount",
      type: "number",
      hidden: true,
    },
    //--- show
    {
      dataField: "itemId",
      text: "Item ID",
      headerStyle: {
        textAlign: "center",
      },
      editable: false,
      style: {
        backgroundColor: "#e9ecef",
        width: "10%",
        fontSize: "14px",
      },
    },
    {
      dataField: "itemName",
      text: "Item Name",
      headerStyle: {
        textAlign: "center",
      },
      editable: false,
      style: {
        backgroundColor: "#e9ecef",
        width: "23%",
        fontSize: "14px",
      },
    },
    {
      dataField: "qty",
      text: "Qty",
      type: "number",
      headerStyle: {
        textAlign: "center",
      },
      style: {
        width: "10%",
        fontSize: "14px",
        textAlign: "right",
      },
      formatter: (cell, row) => {
        return cell.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Qty should be numeric",
          };
        }
        if (newValue < 0) {
          return {
            valid: false,
            message: "Qty should bigger than 0",
          };
        }
        return true;
      },
    },
    {
      dataField: "unit",
      text: "Unit",
      editable: false,
      headerStyle: {
        textAlign: "center",
      },
      style: {
        backgroundColor: "#e9ecef",
        width: "10%",
        fontSize: "14px",
      },
    },
    {
      dataField: "price",
      text: "Price",
      type: "number",
      headerStyle: {
        textAlign: "center",
      },
      style: {
        width: "10%",
        fontSize: "14px",
        textAlign: "right",
      },
      formatter: (cell, row) => {
        return cell.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Price should be numeric",
          };
        }
        if (newValue < 0) {
          return {
            valid: false,
            message: "Price should bigger than 0",
          };
        }
        return true;
      },
    },
    {
      dataField: "discountPercent",
      text: "Disc.",
      type: "number",
      headerStyle: {
        textAlign: "center",
      },
      style: {
        width: "10%",
        fontSize: "14px",
        textAlign: "right",
      },
      formatter: (cell, row) => {
        return (cell / 100).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: "percent",
        });
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Disc. should be numeric",
          };
        }
        if (newValue < 0 || newValue > 100) {
          return {
            valid: false,
            message: "Disc. should be between 0 - 100",
          };
        }
        return true;
      },
    },
    {
      dataField: "netAmount",
      text: "Net Total",
      editable: false,
      headerStyle: {
        textAlign: "center",
      },
      style: {
        backgroundColor: "#e9ecef",
        width: "10%",
        fontSize: "14px",
        textAlign: "right",
      },
      formatter: (cell, row) => {
        return cell.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      dataField: "remark",
      text: "Job ID",
      editable: false,
      headerStyle: {
        textAlign: "center",
      },
      style: {
        backgroundColor: "#e9ecef",
        width: "15%",
        fontSize: "14px",
      },
    },
    /*
    {
      dataField: "remark",
      text: "Job ID",
      style: {
        width: "15%",
        fontSize: "14px",
      },
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return this.state.jobIdList;
        },
      },
    },*/
  ];

  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      supplier: "",
      staff: "",
      location: "",
      poCode: "",
      status: params.STATUS.OPEN,
      remark: "",
      totalAmount: 0,
      discountAmount: 0,
      discountSpecial: 0,
      vatPercent: 0,
      vatAmount: 0,
      netAmountBeforeVat: 0,
      netAmountAfterVat: 0,

      stockItem: "",

      supplierList: [],
      staffList: [],
      locationList: [],
      stockItemList: [],
      jobIdList: [],
      poItems: [], // for show all list in Bootstrap table
      selectedId: [], // for toggle in Bootstrap table
      selectedItems: [], // for enable/disable button in Bootstrap table

      isLoading: false,
      isEditing: false,
      isCreate: props.match.params.id ? false : true,
    };
  }

  componentDidMount() {
    // dropdown list
    axios.get("/supplier").then((res1) => {
      let list1 = [];

      // process if has an option
      if (res1.data.length) {
        res1.data.map((supp) => {
          return list1.push({
            value: supp.id,
            label: supp.code + " - " + supp.name,
          });
        });

        this.setState({
          supplierList: list1,
        });
      }
    });

    axios.get("/staff").then((res2) => {
      let list2 = [];

      // process if has an option
      if (res2.data.length) {
        res2.data.map((staff) => {
          return list2.push({
            value: staff.id,
            label:
              staff.code +
              " - " +
              staff.firstname +
              " " +
              staff.lastname +
              " (" +
              staff.nickname +
              ")",
          });
        });

        this.setState({
          staffList: list2,
        });
      }
    });

    axios.get("/location").then((res3) => {
      let list3 = [];

      // process if has an option
      if (res3.data.length) {
        res3.data.map((loc) => {
          return list3.push({
            value: loc.id,
            label: loc.code + " - " + loc.name,
          });
        });

        this.setState({
          locationList: list3,
        });
      }
    });

    axios.get("/roomcode/job").then((res4) => {
      let list4 = [{ value: "", label: "-- Not Specified --" }];

      if (res4.data.length) {
        res4.data.map((job) => {
          return list4.push({
            value: job.job_id + " : " + job.project_id + "_" + job.room_code,
            label: job.job_id + " : " + job.project_id + "_" + job.room_code,
          });
        });

        this.setState({
          jobIdList: list4,
        });
      }
    });

    // load current data
    if (!this.state.isCreate) {
      this.setState({ isLoading: true });

      axios
        .get("/po/" + this.state.id)
        .then((success) => {
          this.setState({
            id: success.data.id,
            supplier: success.data.supplier_id,
            staff: success.data.staff_id,
            location: success.data.location_id,
            poCode: success.data.code,
            status: success.data.status,
            remark: success.data.remark ? success.data.remark : "",
            totalAmount: success.data.total_amount,
            discountAmount: success.data.discount_amount,
            discountSpecial: success.data.discount_special,
            vatPercent: success.data.vat_percent,
            vatAmount: success.data.vat_amount,
            netAmountBeforeVat: success.data.net_amount_before_vat,
            netAmountAfterVat: success.data.net_amount_after_vat,
          });

          this.getStockItemList(success.data.supplier_id);
          this.getPoItemList();
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ staff: JSON.parse(localStorage.getUser()).role_id });
    }
  }

  getStockItemList = (supplierId) => {
    this.setState({ isLoading: true });

    axios
      .get("/stockitem/" + supplierId + "/supplier")
      .then((res) => {
        let list = [];
        res.data.map((item) => {
          return list.push({
            value: item.id,
            label: item.id + " - " + item.name,
            obj: item,
          });
        });

        this.setState({
          stockItemList: list,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  getPoItemList = () => {
    this.setState({ isLoading: true });

    axios
      .get("/poitem/" + this.state.id)
      .then((res) => {
        let list = [];

        if (res.data.length) {
          res.data.map((item) => {
            return list.push({
              id: item.id,
              itemId: item.item_id,
              itemName: item.s_name,
              qty: item.qty,
              unit: item.s_unit,
              price: item.price,
              totalAmount: item.total_amount,
              discountPercent: item.discount_percent,
              discountAmount: item.discount_amount,
              netAmount: item.net_amount,
              remark: item.remark,
            });
          });

          this.setState({
            poItems: list,
            selectedItems: [],
            selectedId: [],
            isEditing: false,
          });
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  calculateTotalPoItem = () => {
    let totalAmount = 0;
    let discountAmount = 0;
    let netAmountBeforeVat = 0;

    this.state.poItems.map((item) => {
      totalAmount += item.totalAmount;
      discountAmount += item.discountAmount;
      netAmountBeforeVat += item.netAmount;

      return 0;
    });

    netAmountBeforeVat = netAmountBeforeVat - this.state.discountSpecial;

    let vatPercent = this.VAT * 100;
    let vatAmount = netAmountBeforeVat * this.VAT;
    let netAmountAfterVat = netAmountBeforeVat + vatAmount;

    this.setState({
      totalAmount: totalAmount,
      discountAmount: discountAmount,
      netAmountBeforeVat: netAmountBeforeVat,
      vatPercent: vatPercent,
      vatAmount: vatAmount,
      netAmountAfterVat: netAmountAfterVat,
    });
  };

  handleOnChange = (e) => {
    let val = "";

    if (e.target.type === "checkbox") {
      val = e.target.checked;
    } else {
      val = e.target.value;
    }

    this.setState({ [e.target.name]: val });
  };

  handleOnDiscountChange = (e) => {
    let val = e.target.value;

    if (isNaN(val)) {
      return false;
    }

    this.setState({ discountSpecial: val }, () => this.calculateTotalPoItem());
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    // validate required input
    if (this.missingRequiredField()) return;

    this.setState({ isLoading: true });

    const data = new FormData();
    data.append("supplier", this.state.supplier);
    data.append("staff", this.state.staff);
    data.append("location", this.state.location);
    data.append("remark", this.state.remark === "" ? "-" : this.state.remark);
    data.append("status", params.STATUS.OPEN);
    data.append("totalAmount", this.state.totalAmount);
    data.append("discountAmount", this.state.discountAmount);
    data.append("discountSpecial", this.state.discountSpecial);
    data.append("vatPercent", this.state.vatPercent);
    data.append("vatAmount", this.state.vatAmount);
    data.append("netAmountBeforeVat", this.state.netAmountBeforeVat);
    data.append("netAmountAfterVat", this.state.netAmountAfterVat);
    data.append("items", JSON.stringify(this.node.table.props.data));
    data.append("itemsEdit", this.state.isEditing);
    if (this.state.isCreate) {
      axios
        .post("/po", data)
        .then((success) => {
          this.setState({
            id: success.data.item.id,
            poCode: success.data.item.code,
            selectedItems: [],
            selectedId: [],
            isCreate: false,
            isEditing: false,
          });

          this.props.history.push("/po/" + this.state.id);
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    } else {
      axios.post("/po/" + this.state.id, data).finally(() => {
        this.setState(
          { isLoading: false, isEditing: false },
          this.getPoItemList()
        );
      });
    }
  };

  handleOnStatus = (e) => {
    e.preventDefault();

    const status = e.target.value;
    const data = new FormData();
    data.append("status", status);

    let toastType;
    if (status === params.STATUS.APPROVED || status === params.STATUS.PAID)
      toastType = toastConfirmSuccess;
    else toastType = toastConfirmDanger;

    toastType.fire().then((result) => {
      if (result.isConfirmed) {
        this.setState({ isLoading: true });

        axios
          .post("/po/" + this.state.id + "/status", data)
          .then(() => {
            this.setState({ status: status });
          })
          .finally(() => {
            this.setState({ isLoading: false });
          });
      }
    });
  };

  handleOnAddItem = (e) => {
    if (!this.state.stockItem) return;

    let itemId = this.state.stockItem;
    let selectedItem = this.state.stockItemList.filter(function (o) {
      return itemId === o.obj.id;
    });

    // set data for po item
    let newItem = {
      id: this.NEW_ID--,
      itemId: selectedItem[0].obj.id,
      itemName: selectedItem[0].obj.name,
      qty: 1,
      unit: selectedItem[0].obj.unit,
      price: selectedItem[0].obj.supplier_price,
      totalAmount: selectedItem[0].obj.supplier_price,
      discountPercent: 0,
      discountAmount: 0,
      netAmount: selectedItem[0].obj.supplier_price,
      remark: null,
    };

    this.setState(
      {
        poItems: [...this.state.poItems, newItem],
        selectedItems: [],
        selectedId: [],
        isEditing: true,
      },
      () => this.calculateTotalPoItem()
    );
  };

  handleOnAddJobId = (e) => {
    let selectedItems = this.state.selectedItems;

    let selectOptions = "";
    this.state.jobIdList.forEach((item) => {
      selectOptions += `<option value="${item.value}">${item.label}</option>`;
    });

    toastHtmlJobId
      .fire({
        html: `<select name="jobId" id="jobId" class="form-control">${selectOptions}</select>`,
      })
      .then((result) => {
        if (result.isConfirmed) {
          // update remark for selected items
          let updateList = this.state.poItems.map((item) => {
            if (selectedItems.includes(item)) {
              return {
                ...item,
                remark: result.value.jobId,
              };
            } else {
              return item;
            }
          });

          this.setState({
            poItems: updateList,
            selectedItems: [],
            selectedId: [],
            isEditing: true,
          });
        }
      });
  };

  handleOnDeleteItem = (e) => {
    let selectedItems = this.state.selectedItems;

    toastConfirmDanger
      .fire({
        title: "Delete " + selectedItems.length + " item(s) from the list?",
        confirmButtonText: "Delete",
      })
      .then((result) => {
        if (result.isConfirmed) {
          let filterList = this.state.poItems.filter(function (o) {
            return !selectedItems.includes(o);
          });

          this.setState(
            {
              poItems: filterList,
              selectedItems: [],
              selectedId: [],
              isEditing: true,
            },
            () => this.calculateTotalPoItem()
          );
        }
      });
  };

  handleOnSelectItemSingle = (row, isSelected) => {
    if (isSelected) {
      this.setState({
        selectedItems: [...this.state.selectedItems, row],
        selectedId: [...this.state.selectedId, row.id],
      });
    } else {
      let selectedId = this.state.selectedId.filter((item) => item !== row.id);
      let filterList = this.state.selectedItems.filter(function (o) {
        return row.id !== o.id;
      });

      this.setState({ selectedItems: filterList, selectedId: selectedId });
    }
  };

  handleOnSelectItemAll = (isSelected, row) => {
    if (isSelected) {
      let selectedId = [];
      row.forEach((item) => selectedId.push(item.id));

      this.setState({ selectedItems: row, selectedId: selectedId });
    } else {
      this.setState({ selectedItems: [], selectedId: [] });
    }
  };

  handleOnDiscard = (e) => {
    this.setState({ isLoading: true });
    this.getPoItemList();
  };

  handleOnTableChange = (oldValue, newValue, row, column) => {
    if (oldValue !== newValue) {
      if (
        column.dataField === "qty" ||
        column.dataField === "price" ||
        column.dataField === "discountPercent"
      ) {
        row.totalAmount = row.qty * row.price;
        row.discountAmount = row.totalAmount * (row.discountPercent / 100);
        row.netAmount = row.totalAmount - row.discountAmount;
      }

      this.calculateTotalPoItem();
      this.setState({ isEditing: true });
    }
  };

  missingRequiredField = () => {
    if (!this.state.supplier)
      return toastPopupError.fire({ title: "Supplier is required!" });
    if (!this.state.staff)
      return toastPopupError.fire({ title: "Staff is required!" });
    if (!this.state.location)
      return toastPopupError.fire({ title: "Location is required!" });

    return false;
  };

  render() {
    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader
            title="PO "
            subtitle={this.state.id}
            prevDir="PO"
            prevUrl="/po"
            activeDir="Detail"
            button="hidden"
          />

          <section className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                PO No.
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Form.Control
                                name="poCode"
                                value={this.state.poCode}
                                onChange={this.handleOnChange}
                                autoComplete="false"
                                disabled
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Status
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <h4>
                                <div
                                  className={
                                    this.state.status === params.STATUS.OPEN
                                      ? "badge badge-primary"
                                      : this.state.status ===
                                        params.STATUS.APPROVED
                                      ? "badge badge-success"
                                      : this.state.status === params.STATUS.PAID
                                      ? "badge badge-info"
                                      : this.state.status ===
                                        params.STATUS.CANCELLED
                                      ? "badge badge-danger"
                                      : "badge badge-secondary"
                                  }
                                >
                                  {this.state.status}
                                </div>
                              </h4>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Supplier *
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Select
                                name="supplier"
                                className="basic-select"
                                classNamePrefix="select"
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                isSearchable={true}
                                options={this.state.supplierList}
                                value={
                                  this.state.supplierList[
                                    this.state.supplierList.findIndex(
                                      (obj) => obj.value === this.state.supplier
                                    )
                                  ]
                                }
                                onChange={(e) => {
                                  this.setState(
                                    { supplier: e.value },
                                    this.getStockItemList(e.value)
                                  );
                                }}
                                isDisabled={
                                  this.state.status ===
                                    params.STATUS.APPROVED ||
                                  this.state.status === params.STATUS.PAID ||
                                  this.state.status ===
                                    params.STATUS.CANCELLED ||
                                  this.state.poItems.length > 0
                                }
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Staff *
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Select
                                name="staff"
                                className="basic-select"
                                classNamePrefix="select"
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                isSearchable={true}
                                options={this.state.staffList}
                                value={
                                  this.state.staffList[
                                    this.state.staffList.findIndex(
                                      (obj) => obj.value === this.state.staff
                                    )
                                  ]
                                }
                                onChange={(e) =>
                                  this.setState({ staff: e.value })
                                }
                                isDisabled={
                                  this.state.status ===
                                    params.STATUS.APPROVED ||
                                  this.state.status === params.STATUS.PAID ||
                                  this.state.status === params.STATUS.CANCELLED
                                }
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Location *
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Select
                                name="location"
                                className="basic-select"
                                classNamePrefix="select"
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                isSearchable={true}
                                options={this.state.locationList}
                                value={
                                  this.state.locationList[
                                    this.state.locationList.findIndex(
                                      (obj) => obj.value === this.state.location
                                    )
                                  ]
                                }
                                onChange={(e) =>
                                  this.setState({ location: e.value })
                                }
                                isDisabled={
                                  this.state.status ===
                                    params.STATUS.APPROVED ||
                                  this.state.status === params.STATUS.PAID ||
                                  this.state.status === params.STATUS.CANCELLED
                                }
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Remark
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                name="remark"
                                value={this.state.remark}
                                onChange={this.handleOnChange}
                                autoComplete="false"
                                disabled={
                                  this.state.status ===
                                    params.STATUS.APPROVED ||
                                  this.state.status === params.STATUS.PAID ||
                                  this.state.status === params.STATUS.CANCELLED
                                }
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Body>
                      <BootstrapTable
                        ref={(n) => (this.node = n)}
                        keyField="id"
                        data={this.state.poItems}
                        columns={this.COLUMNS}
                        cellEdit={cellEditFactory({
                          mode:
                            this.state.status === params.STATUS.APPROVED ||
                            this.state.status === params.STATUS.PAID ||
                            this.state.status === params.STATUS.CANCELLED
                              ? ""
                              : "click",
                          blurToSave: true,
                          afterSaveCell: this.handleOnTableChange,
                        })}
                        selectRow={{
                          mode: "checkbox",
                          selectColumnStyle: {
                            width: "2%",
                          },
                          selected: this.state.selectedId,
                          onSelect: this.handleOnSelectItemSingle,
                          onSelectAll: this.handleOnSelectItemAll,
                        }}
                      />
                      <Row
                        style={{
                          display:
                            this.state.selectedItems.length === 0 ||
                            this.state.status === params.STATUS.APPROVED ||
                            this.state.status === params.STATUS.PAID ||
                            this.state.status === params.STATUS.CANCELLED
                              ? "none"
                              : "",
                        }}
                      >
                        <Col md={12}>
                          <Button
                            variant="outline-primary"
                            onClick={this.handleOnAddJobId}
                            className="mr-2"
                          >
                            <i className="fas fa-download mr-2" />
                            Select Job ID
                          </Button>
                          <Button
                            variant="outline-danger"
                            onClick={this.handleOnDeleteItem}
                          >
                            <i className="fas fa-trash-alt mr-2" />
                            Delete
                          </Button>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          display:
                            this.state.selectedItems.length > 0 ||
                            this.state.status === params.STATUS.APPROVED ||
                            this.state.status === params.STATUS.PAID ||
                            this.state.status === params.STATUS.CANCELLED
                              ? "none"
                              : "",
                        }}
                      >
                        <Col md={7}>
                          <Select
                            name="stockItem"
                            className="basic-select"
                            classNamePrefix="select"
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                            isSearchable={true}
                            options={this.state.stockItemList}
                            value={
                              this.state.stockItemList[
                                this.state.stockItemList.findIndex(
                                  (obj) => obj.value === this.state.stockItem
                                )
                              ]
                            }
                            onChange={(e) =>
                              this.setState({ stockItem: e.value })
                            }
                          />
                        </Col>
                        <Col md={5}>
                          <Button
                            variant="default"
                            onClick={this.handleOnAddItem}
                            disabled={!this.state.stockItem}
                          >
                            <i className="fas fa-plus mr-2" />
                            Add item
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <span className="col-form-label float-right">
                            Total Amount
                          </span>
                        </Col>
                        <Col sm={2} className="float-end">
                          <Form.Control
                            className="text-right"
                            type="text"
                            value={this.state.totalAmount.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <span className="col-form-label float-right">
                            Total Discount
                          </span>
                        </Col>
                        <Col sm={2} className="float-end">
                          <Form.Control
                            className="text-right"
                            type="text"
                            value={this.state.discountAmount.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <span className="col-form-label float-right">
                            Special Discount
                          </span>
                        </Col>
                        <Col sm={2} className="float-end">
                          <Form.Control
                            className="text-right"
                            type="text"
                            name="discountSpecial"
                            disabled={
                              this.state.status === params.STATUS.APPROVED ||
                              this.state.status === params.STATUS.PAID ||
                              this.state.status === params.STATUS.CANCELLED
                            }
                            onChange={this.handleOnDiscountChange}
                            value={this.state.discountSpecial.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <span className="col-form-label float-right">
                            Net Total
                          </span>
                        </Col>
                        <Col sm={2} className="float-end">
                          <Form.Control
                            className="text-right"
                            type="text"
                            value={this.state.netAmountBeforeVat.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <span className="col-form-label float-right">
                            Vat (7%)
                          </span>
                        </Col>
                        <Col sm={2} className="float-end">
                          <Form.Control
                            className="text-right"
                            type="text"
                            value={this.state.vatAmount.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <span className="col-form-label float-right">
                            <b>Grand Total</b>
                          </span>
                        </Col>
                        <Col sm={2} className="float-end">
                          <Form.Control
                            className="text-bold text-right"
                            type="text"
                            value={this.state.netAmountAfterVat.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            disabled
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <font
                    color="red"
                    style={{
                      display: this.state.isEditing ? "" : "none",
                    }}
                  >
                    You are editing items. Please save or discard changes.
                  </font>
                  <ButtonLoading
                    name="Cancel PO"
                    value={params.STATUS.CANCELLED}
                    className="btn btn-danger mr-2"
                    disabled={this.state.isLoading}
                    onClick={this.handleOnStatus}
                    hidden={
                      this.state.isCreate ||
                      this.state.isEditing ||
                      this.state.status === params.STATUS.CANCELLED ||
                      this.state.status === params.STATUS.PAID
                    }
                  />
                  <ButtonLoading
                    name="Mark as Paid"
                    value={params.STATUS.PAID}
                    className="btn btn-info mr-2"
                    disabled={this.state.isLoading}
                    onClick={this.handleOnStatus}
                    hidden={
                      this.state.isCreate ||
                      this.state.isEditing ||
                      this.state.status === params.STATUS.OPEN ||
                      this.state.status === params.STATUS.PAID ||
                      this.state.status === params.STATUS.CANCELLED
                    }
                  />
                  <ButtonLoading
                    name="Approve"
                    value={params.STATUS.APPROVED}
                    className="btn btn-success mr-2"
                    disabled={this.state.isLoading}
                    onClick={this.handleOnStatus}
                    hidden={
                      this.state.isCreate ||
                      this.state.isEditing ||
                      this.state.status === params.STATUS.APPROVED ||
                      this.state.status === params.STATUS.PAID ||
                      this.state.status === params.STATUS.CANCELLED
                    }
                  />
                  <ButtonLoading
                    name={this.state.isCreate ? "Submit" : "Save"}
                    className="btn btn-primary float-right mr-2"
                    disabled={this.state.isLoading}
                    onClick={this.handleOnSubmit}
                    hidden={
                      this.state.status === params.STATUS.APPROVED ||
                      this.state.status === params.STATUS.PAID ||
                      this.state.status === params.STATUS.CANCELLED
                    }
                  />
                  <ButtonLoading
                    name="Discard"
                    className="btn btn-default float-right mr-2"
                    disabled={this.state.isLoading}
                    onClick={this.handleOnDiscard}
                    hidden={this.state.isCreate || !this.state.isEditing}
                  />
                  <Button
                    variant="default"
                    className="float-right mr-2"
                    hidden={this.state.isCreate || this.state.isEditing}
                    href={"/po/" + this.state.id + "/print"}
                    target="_blank"
                  >
                    <i className="fas fa-print mr-2"></i>Print
                  </Button>
                </Col>
              </Row>
              <br />
            </Container>
          </section>
        </div>

        <Footer />
        <PageLoader marginTop="30%" loading={this.state.isLoading} />
      </div>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import {} from "react-router-dom";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

import Header from "../components/Header";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import PageLoader from "../components/PageLoader";
import ButtonLoading from "../components/ButtonLoading";

import {
  toastPopupError,
  toastConfirmDanger,
  toastConfirmSuccess,
} from "../utils/sweetAlert";
import params from "../utils/params";
import localStorage from "../utils/localStorage";

export default class ReceivedOrderDetail extends Component {
  VAT = 0.07;
  COLUMNS = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "discountAmount",
      text: "Disc. Amount",
      type: "number",
      hidden: true,
    },
    {
      dataField: "totalAmount",
      text: "Total Amount",
      type: "number",
      hidden: true,
    },
    {
      dataField: "remark",
      text: "Job ID",
      hidden: true,
    },
    //--- show
    {
      dataField: "itemId",
      text: "Item ID",
      headerStyle: {
        textAlign: "center",
      },
      editable: false,
      style: {
        backgroundColor: "#e9ecef",
        width: "13%",
        fontSize: "14px",
      },
    },
    {
      dataField: "itemName",
      text: "Item Name",
      headerStyle: {
        textAlign: "center",
      },
      editable: false,
      style: {
        backgroundColor: "#e9ecef",
        width: "25%",
        fontSize: "14px",
      },
    },
    {
      dataField: "qtyPo",
      text: "PO Qty",
      editable: false,
      type: "number",
      headerStyle: {
        textAlign: "center",
      },
      style: {
        backgroundColor: "#e9ecef",
        width: "12%",
        fontSize: "14px",
        textAlign: "right",
      },
      formatter: (cell, row) => {
        return row.qtyPo.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
      },
    },
    {
      dataField: "unit",
      text: "Unit",
      editable: false,
      headerStyle: {
        textAlign: "center",
      },
      style: {
        backgroundColor: "#e9ecef",
        width: "10%",
        fontSize: "14px",
      },
    },
    {
      dataField: "price",
      text: "Price",
      editable: false,
      headerStyle: {
        textAlign: "center",
      },
      style: {
        backgroundColor: "#e9ecef",
        width: "10%",
        fontSize: "14px",
        textAlign: "right",
      },
      formatter: (cell, row) => {
        return cell.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      dataField: "discountPercent",
      text: "Disc.",
      editable: false,
      type: "number",
      headerStyle: {
        textAlign: "center",
      },
      style: {
        backgroundColor: "#e9ecef",
        width: "10%",
        fontSize: "14px",
        textAlign: "right",
      },
      formatter: (cell, row) => {
        return (cell / 100).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: "percent",
        });
      },
    },
    {
      dataField: "qty",
      text: "Qty",
      type: "number",
      headerStyle: {
        textAlign: "center",
      },
      style: {
        width: "10%",
        fontSize: "14px",
        textAlign: "right",
      },
      formatter: (cell, row) => {
        return cell.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: 'Qty should be numeric'
          };
        }
        if (newValue < 0) {
          return {
            valid: false,
            message: 'Qty should bigger than 0'
          };
        }
        if (newValue > row.qtyPo) {
          return {
            valid: false,
            message: 'Qty exceed PO Qty'
          };
        }
        return true;
      },
    },
    {
      dataField: "netAmount",
      text: "Net Total",
      editable: false,
      headerStyle: {
        textAlign: "center",
      },
      style: {
        backgroundColor: "#e9ecef",
        width: "10%",
        fontSize: "14px",
        textAlign: "right",
      },
      formatter: (cell, row) => {
        return cell.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      roCode: "",
      poId: "",
      poCode: "",
      poDiscountSpeicalRatio: 0,
      supplierId: "",
      supplier: "",
      receivedDate: "",
      staff: "",
      location: "",
      status: params.STATUS.OPEN,
      remark: "",
      reference: "",
      totalAmount: 0,
      discountAmount: 0,
      discountSpecial: 0,
      vatPercent: 0,
      vatAmount: 0,
      netAmountBeforeVat: 0,
      netAmountAfterVat: 0,

      poList: [],
      staffList: [],
      locationList: [],
      roItems: [], // for show all list in Bootstrap table

      isLoading: false,
      isEditing: false,
      isCreate: props.match.params.id ? false : true,
    };
  }

  componentDidMount() {
    axios.get("/staff").then((res2) => {
      let list2 = [];

      // process if has an option
      if (res2.data.length) {
        res2.data.map((staff) => {
          return list2.push({
            value: staff.id,
            label:
              staff.code +
              " - " +
              staff.firstname +
              " " +
              staff.lastname +
              " (" +
              staff.nickname +
              ")",
          });
        });

        this.setState({
          staffList: list2,
        });
      }
    });

    axios.get("/location").then((res3) => {
      let list3 = [];

      // process if has an option
      if (res3.data.length) {
        res3.data.map((loc) => {
          return list3.push({
            value: loc.id,
            label: loc.code + " - " + loc.name,
          });
        });

        this.setState({
          locationList: list3,
        });
      }
    });

    axios.get("/po").then((res5) => {
      let list5 = [];

      // process if has an option
      if (res5.data.length) {
        res5.data.map((po) => {
          if (po.status === params.STATUS.PAID) {
            return list5.push({
              value: po.id,
              label:
                po.code +
                " | Date: " +
                new Date(po.created_at).toLocaleDateString("en-US", {
                  year: "numeric",
                }) +
                "-" +
                new Date(po.created_at).toLocaleDateString("en-US", {
                  month: "2-digit",
                }) +
                "-" +
                new Date(po.created_at).toLocaleDateString("en-US", {
                  day: "2-digit",
                }) +
                " | Supp: " +
                po.supplier.code,
            });
          }

          return 0;
        });

        this.setState({
          poList: list5,
        });
      }
    });

    // load current data
    if (!this.state.isCreate) {
      this.setState({ isLoading: true });

      axios
        .get("/ro/" + this.state.id)
        .then((success) => {
          this.setState({
            id: success.data.id,
            roCode: success.data.code,
            poId: success.data.purchase_order.id,
            poCode: success.data.purchase_order.code,
            poDiscountSpeicalRatio:
              success.data.purchase_order.discount_special /
              (success.data.purchase_order.total_amount -
                success.data.purchase_order.discount_amount),
            supplierId: success.data.supplier.id,
            supplier: success.data.supplier.name,
            receivedDate:
              new Date(success.data.received_date).toLocaleDateString("en-US", {
                year: "numeric",
              }) +
              "-" +
              new Date(success.data.received_date).toLocaleDateString("en-US", {
                month: "2-digit",
              }) +
              "-" +
              new Date(success.data.received_date).toLocaleDateString("en-US", {
                day: "2-digit",
              }),
            staff: success.data.staff_id,
            location: success.data.location_id,
            status: success.data.status,
            remark: success.data.remark ? success.data.remark : "",
            reference: success.data.reference ? success.data.reference : "",
            totalAmount: success.data.total_amount,
            discountAmount: success.data.discount_amount,
            discountSpecial: success.data.discount_special,
            vatPercent: success.data.vat_percent,
            vatAmount: success.data.vat_amount,
            netAmountBeforeVat: success.data.net_amount_before_vat,
            netAmountAfterVat: success.data.net_amount_after_vat,
          });

          this.getRoItemList();
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ staff: JSON.parse(localStorage.getUser()).role_id });
    }
  }

  getPoItemList = () => {
    this.setState({ isLoading: true });

    axios.get("/po/" + this.state.poId).then((res1) => {
      this.setState({
        poDiscountSpeicalRatio:
          res1.data.discount_special /
          (res1.data.total_amount - res1.data.discount_amount),
        supplierId: res1.data.supplier.id,
        supplier: res1.data.supplier.code + " - " + res1.data.supplier.name,
      });
    });

    axios
      .get("/poitem/" + this.state.poId)
      .then((res2) => {
        let list = [];

        if (res2.data.length) {
          res2.data.map((item) => {
            return list.push({
              id: item.id,
              itemId: item.item_id,
              itemName: item.s_name,
              qtyPo: item.qty,
              qty: 0,
              unit: item.s_unit,
              price: item.price,
              totalAmount: 0,
              discountPercent: item.discount_percent,
              discountAmount: 0,
              netAmount: 0,
              remark: item.remark,
            });
          });

          this.setState({
            roItems: list,
            isEditing: false,
          });
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  getRoItemList = () => {
    this.setState({ isLoading: true });

    axios
      .get("/roitem/" + this.state.id)
      .then((res) => {
        let list = [];

        if (res.data.length) {
          res.data.map((item) => {
            return list.push({
              id: item.id,
              itemId: item.item_id,
              itemName: item.s_name,
              qtyPo: item.qty_po,
              qty: item.qty,
              unit: item.s_unit,
              price: item.price,
              totalAmount: item.total_amount,
              discountPercent: item.discount_percent,
              discountAmount: item.discount_amount,
              netAmount: item.net_amount,
              remark: item.remark,
            });
          });

          this.setState({
            roItems: list,
            isEditing: false,
          });
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  calculateTotalRoItem = () => {
    let totalAmount = 0;
    let discountAmount = 0;
    let netAmountBeforeVat = 0;

    this.state.roItems.map((item) => {
      totalAmount += item.totalAmount;
      discountAmount += item.discountAmount;
      netAmountBeforeVat += item.netAmount;

      return 0;
    });

    let discountSpecial =
      (totalAmount - discountAmount) * this.state.poDiscountSpeicalRatio;
    netAmountBeforeVat -= discountSpecial;

    let vatPercent = this.VAT * 100;
    let vatAmount = netAmountBeforeVat * this.VAT;
    let netAmountAfterVat = netAmountBeforeVat + vatAmount;

    this.setState({
      totalAmount: totalAmount,
      discountAmount: discountAmount,
      discountSpecial: discountSpecial,
      netAmountBeforeVat: netAmountBeforeVat,
      vatPercent: vatPercent,
      vatAmount: vatAmount,
      netAmountAfterVat: netAmountAfterVat,
    });
  };

  handleOnChange = (e) => {
    let val = "";

    if (e.target.type === "checkbox") {
      val = e.target.checked;
    } else {
      val = e.target.value;
    }

    this.setState({ [e.target.name]: val });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    // validate required input
    if (this.missingRequiredField()) return;

    this.setState({ isLoading: true });

    const data = new FormData();
    data.append("poId", this.state.poId);
    data.append("supplierId", this.state.supplierId);
    data.append("staff", this.state.staff);
    data.append("location", this.state.location);
    data.append("receivedDate", this.state.receivedDate);
    data.append("remark", this.state.remark === "" ? "-" : this.state.remark);
    data.append(
      "reference",
      this.state.reference === "" ? "-" : this.state.reference
    );
    data.append("status", params.STATUS.OPEN);
    data.append("totalAmount", this.state.totalAmount);
    data.append("discountAmount", this.state.discountAmount);
    data.append("discountSpecial", this.state.discountSpecial);
    data.append("vatPercent", this.state.vatPercent);
    data.append("vatAmount", this.state.vatAmount);
    data.append("netAmountBeforeVat", this.state.netAmountBeforeVat);
    data.append("netAmountAfterVat", this.state.netAmountAfterVat);
    data.append("items", JSON.stringify(this.node.table.props.data));
    data.append("itemsEdit", this.state.isEditing);

    if (this.state.isCreate) {
      axios
        .post("/ro", data)
        .then((success) => {
          this.setState({
            id: success.data.item.id,
            roCode: success.data.item.code,
            isCreate: false,
            isEditing: false,
          });

          this.props.history.push("/ro/" + this.state.id);
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    } else {
      axios.post("/ro/" + this.state.id, data).finally(() => {
        this.setState(
          { isLoading: false, isEditing: false },
          this.getRoItemList()
        );
      });
    }
  };

  handleOnStatus = (e) => {
    e.preventDefault();

    const status = e.target.value;
    const data = new FormData();
    data.append("status", status);
    data.append("items", JSON.stringify(this.node.table.props.data));

    let toastType;
    if (status === params.STATUS.APPROVED) toastType = toastConfirmSuccess;
    else toastType = toastConfirmDanger;

    toastType.fire().then((result) => {
      if (result.isConfirmed) {
        this.setState({ isLoading: true });

        axios
          .post("/ro/" + this.state.id + "/status", data)
          .then(() => {
            this.setState({ status: status });
          })
          .finally(() => {
            this.setState({ isLoading: false });
          });
      }
    });
  };

  handleOnDiscard = (e) => {
    this.setState({ isLoading: true });
    this.getRoItemList();
  };

  handleOnTableChange = (oldValue, newValue, row, column) => {
    if (oldValue !== newValue) {
      if (column.dataField === "qty") {
        row.totalAmount = row.qty * row.price;
        row.discountAmount = row.totalAmount * (row.discountPercent / 100);
        row.netAmount = row.totalAmount - row.discountAmount;
      }

      this.calculateTotalRoItem();
      this.setState({ isEditing: true });
    }
  };

  missingRequiredField = () => {
    if (!this.state.poId)
      return toastPopupError.fire({ title: "PO No. is required!" });
    if (!this.state.receivedDate)
      return toastPopupError.fire({ title: "Received Date is required!" });
    if (!this.state.staff)
      return toastPopupError.fire({ title: "Staff is required!" });
    if (!this.state.location)
      return toastPopupError.fire({ title: "Location is required!" });

    return false;
  };

  render() {
    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader
            title="RO "
            subtitle={this.state.id}
            prevDir="RO"
            prevUrl="/ro"
            activeDir="Detail"
            button="hidden"
          />

          <section className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                RO No.
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Form.Control
                                name="roCode"
                                value={this.state.roCode}
                                onChange={this.handleOnChange}
                                autoComplete="false"
                                disabled
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Status
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <h4>
                                <div
                                  className={
                                    this.state.status === params.STATUS.OPEN
                                      ? "badge badge-primary"
                                      : this.state.status ===
                                        params.STATUS.APPROVED
                                      ? "badge badge-success"
                                      : this.state.status ===
                                        params.STATUS.CANCELLED
                                      ? "badge badge-danger"
                                      : "badge badge-secondary"
                                  }
                                >
                                  {this.state.status}
                                </div>
                              </h4>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                PO No. *
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Select
                                name="po"
                                className="basic-select"
                                classNamePrefix="select"
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                isSearchable={true}
                                options={this.state.poList}
                                value={
                                  this.state.poList[
                                    this.state.poList.findIndex(
                                      (obj) => obj.value === this.state.poId
                                    )
                                  ]
                                }
                                onChange={(e) => {
                                  this.setState({ poId: e.value }, () => {
                                    this.getPoItemList();
                                  });
                                }}
                                isDisabled={this.state.id}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Supplier
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Form.Control
                                name="supplier"
                                value={this.state.supplier}
                                onChange={this.handleOnChange}
                                autoComplete="false"
                                disabled
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Received Date *
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Form.Control
                                type="date"
                                name="receivedDate"
                                value={this.state.receivedDate}
                                onChange={this.handleOnChange}
                                disabled={
                                  this.state.status ===
                                    params.STATUS.APPROVED ||
                                  this.state.status === params.STATUS.CANCELLED
                                }
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Staff *
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Select
                                name="staff"
                                className="basic-select"
                                classNamePrefix="select"
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                isSearchable={true}
                                options={this.state.staffList}
                                value={
                                  this.state.staffList[
                                    this.state.staffList.findIndex(
                                      (obj) => obj.value === this.state.staff
                                    )
                                  ]
                                }
                                onChange={(e) =>
                                  this.setState({ staff: e.value })
                                }
                                isDisabled={
                                  this.state.status ===
                                    params.STATUS.APPROVED ||
                                  this.state.status === params.STATUS.CANCELLED
                                }
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Location *
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Select
                                name="location"
                                className="basic-select"
                                classNamePrefix="select"
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                isSearchable={true}
                                options={this.state.locationList}
                                value={
                                  this.state.locationList[
                                    this.state.locationList.findIndex(
                                      (obj) => obj.value === this.state.location
                                    )
                                  ]
                                }
                                onChange={(e) =>
                                  this.setState({ location: e.value })
                                }
                                isDisabled={
                                  this.state.status ===
                                    params.STATUS.APPROVED ||
                                  this.state.status === params.STATUS.CANCELLED
                                }
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Reference
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Form.Control
                                name="reference"
                                value={this.state.reference}
                                onChange={this.handleOnChange}
                                autoComplete="false"
                                disabled={
                                  this.state.status ===
                                    params.STATUS.APPROVED ||
                                  this.state.status === params.STATUS.CANCELLED
                                }
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Remark
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                name="remark"
                                value={this.state.remark}
                                onChange={this.handleOnChange}
                                autoComplete="false"
                                disabled={
                                  this.state.status ===
                                    params.STATUS.APPROVED ||
                                  this.state.status === params.STATUS.CANCELLED
                                }
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Col sm={12}></Col>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Body>
                      <BootstrapTable
                        ref={(n) => (this.node = n)}
                        keyField="id"
                        data={this.state.roItems}
                        columns={this.COLUMNS}
                        cellEdit={cellEditFactory({
                          mode:
                            this.state.status === params.STATUS.APPROVED ||
                            this.state.status === params.STATUS.CANCELLED
                              ? ""
                              : "click",
                          blurToSave: true,
                          afterSaveCell: this.handleOnTableChange,
                        })}
                      />

                      <Row>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <span className="col-form-label float-right">
                            Total Amount
                          </span>
                        </Col>
                        <Col sm={2} className="float-end">
                          <Form.Control
                            className="text-right"
                            type="text"
                            value={this.state.totalAmount.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <span className="col-form-label float-right">
                            Total Discount
                          </span>
                        </Col>
                        <Col sm={2} className="float-end">
                          <Form.Control
                            className="text-right"
                            type="text"
                            value={this.state.discountAmount.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <span className="col-form-label float-right">
                            Special Discount
                          </span>
                        </Col>
                        <Col sm={2} className="float-end">
                          <Form.Control
                            className="text-right"
                            type="text"
                            value={this.state.discountSpecial.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <span className="col-form-label float-right">
                            Net Total
                          </span>
                        </Col>
                        <Col sm={2} className="float-end">
                          <Form.Control
                            className="text-right"
                            type="text"
                            value={this.state.netAmountBeforeVat.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <span className="col-form-label float-right">
                            Vat (7%)
                          </span>
                        </Col>
                        <Col sm={2} className="float-end">
                          <Form.Control
                            className="text-right"
                            type="text"
                            value={this.state.vatAmount.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}></Col>
                        <Col md={2}>
                          <span className="col-form-label float-right">
                            <b>Grand Total</b>
                          </span>
                        </Col>
                        <Col sm={2} className="float-end">
                          <Form.Control
                            className="text-bold text-right"
                            type="text"
                            value={this.state.netAmountAfterVat.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            disabled
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <font
                    color="red"
                    style={{
                      display: this.state.isEditing ? "" : "none",
                    }}
                  >
                    You are editing items. Please save or discard changes.
                  </font>
                  <ButtonLoading
                    name="Cancel RO"
                    value={params.STATUS.CANCELLED}
                    className="btn btn-danger mr-2"
                    disabled={this.state.isLoading}
                    onClick={this.handleOnStatus}
                    hidden={
                      this.state.isCreate ||
                      this.state.isEditing ||
                      this.state.status === params.STATUS.APPROVED ||
                      this.state.status === params.STATUS.CANCELLED
                    }
                  />
                  <ButtonLoading
                    name="Approve"
                    value={params.STATUS.APPROVED}
                    className="btn btn-success mr-2"
                    disabled={this.state.isLoading}
                    onClick={this.handleOnStatus}
                    hidden={
                      this.state.isCreate ||
                      this.state.isEditing ||
                      this.state.status === params.STATUS.APPROVED ||
                      this.state.status === params.STATUS.CANCELLED
                    }
                  />
                  <ButtonLoading
                    name={this.state.isCreate ? "Submit" : "Save"}
                    className="btn btn-primary float-right mr-2"
                    disabled={this.state.isLoading}
                    onClick={this.handleOnSubmit}
                    hidden={
                      this.state.status === params.STATUS.APPROVED ||
                      this.state.status === params.STATUS.CANCELLED
                    }
                  />
                  <ButtonLoading
                    name="Discard"
                    className="btn btn-default float-right mr-2"
                    disabled={this.state.isLoading}
                    onClick={this.handleOnDiscard}
                    hidden={this.state.isCreate || !this.state.isEditing}
                  />
                  <Button
                    variant="default"
                    className="float-right mr-2"
                    hidden={this.state.isCreate || this.state.isEditing}
                    href={"/ro/" + this.state.id + "/print"}
                    target="_blank"
                  >
                    <i className="fas fa-print mr-2"></i>Print
                  </Button>
                </Col>
              </Row>
              <br />
            </Container>
          </section>
        </div>

        <Footer />
        <PageLoader marginTop="30%" loading={this.state.isLoading} />
      </div>
    );
  }
}

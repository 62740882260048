import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import {} from "react-router-dom";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

import Header from "../components/Header";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import PageLoader from "../components/PageLoader";
import ButtonLoading from "../components/ButtonLoading";

import {
  toastPopupError,
  toastConfirmDanger,
  toastConfirmSuccess,
} from "../utils/sweetAlert";
import params from "../utils/params";
import localStorage from "../utils/localStorage";

export default class RequisitionOrderDetail extends Component {
  NEW_ID = -1; // starting no. for new item
  VAT = 0.07;
  COLUMNS = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    //--- show
    {
      dataField: "itemId",
      text: "Item ID",
      headerStyle: {
        textAlign: "center",
      },
      editable: false,
      style: {
        backgroundColor: "#e9ecef",
        width: "10%",
        fontSize: "14px",
      },
    },
    {
      dataField: "itemName",
      text: "Item Name",
      headerStyle: {
        textAlign: "center",
      },
      editable: false,
      style: {
        backgroundColor: "#e9ecef",
        width: "25%",
        fontSize: "14px",
      },
    },
    {
      dataField: "remainQty",
      text: "Remain QTY",
      type: "number",
      headerStyle: {
        textAlign: "center",
      },
      editable: false,
      style: {
        backgroundColor: "#e9ecef",
        width: "15%",
        fontSize: "14px",
        textAlign: "right",
      },
    },
    {
      dataField: "unit",
      text: "Unit",
      editable: false,
      headerStyle: {
        textAlign: "center",
      },
      style: {
        backgroundColor: "#e9ecef",
        width: "10%",
        fontSize: "14px",
      },
    },
    {
      dataField: "qty",
      text: "Qty",
      type: "number",
      headerStyle: {
        textAlign: "center",
      },
      style: {
        width: "10%",
        fontSize: "14px",
        textAlign: "right",
      },
      formatter: (cell, row) => {
        return cell.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Qty should be numeric",
          };
        }
        if (newValue < 0) {
          return {
            valid: false,
            message: "Qty should bigger than 0",
          };
        }
        return true;
      },
    },
    {
      dataField: "remark",
      text: "Remark",
      headerStyle: {
        textAlign: "center",
      },
      style: {
        fontSize: "14px",
      },
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      job: "",
      staff: "",
      reCode: "",
      status: params.STATUS.OPEN,
      remark: "",

      stockItem: "",

      staffList: [],
      jobList: [],
      stockItemList: [],
      reItems: [], // for show all list in Bootstrap table
      selectedId: [], // for toggle in Bootstrap table
      selectedItems: [], // for enable/disable button in Bootstrap table

      isLoading: false,
      isEditing: false,
      isCreate: props.match.params.id ? false : true,
    };
  }

  componentDidMount() {
    // dropdown list
    axios.get("/staff").then((res1) => {
      let list1 = [];

      if (res1.data.length) {
        res1.data.map((staff) => {
          return list1.push({
            value: staff.id,
            label:
              staff.code +
              " - " +
              staff.firstname +
              " " +
              staff.lastname +
              " (" +
              staff.nickname +
              ")",
          });
        });

        this.setState({
          staffList: list1,
        });
      }
    });

    axios.get("/roomcode/job").then((res2) => {
      let list2 = [{ value: "", label: "-- Please select --" }];

      if (res2.data.length) {
        res2.data.map((job) => {
          return list2.push({
            value: job.job_id,
            label: job.job_id + " : " + job.project_id + "_" + job.room_code,
          });
        });

        this.setState({
          jobList: list2,
        });
      }
    });

    axios.get("/stockitem/").then((res3) => {
      let list3 = [];

      if (res3.data.length) {
        res3.data.map((item) => {
          return list3.push({
            value: item.id,
            label: item.id + " - " + item.name,
            obj: item,
          });
        });

        this.setState({
          stockItemList: list3,
        });
      }
    });

    // load current data
    if (!this.state.isCreate) {
      this.setState({ isLoading: true });

      axios
        .get("/re/" + this.state.id)
        .then((success) => {
          this.setState({
            id: success.data.id,
            staff: success.data.staff_id,
            job: success.data.job_id,
            reCode: success.data.code,
            status: success.data.status,
            remark: success.data.remark ? success.data.remark : "",
          });

          this.getReItemList();
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ staff: JSON.parse(localStorage.getUser()).role_id });
    }
  }

  getReItemList = () => {
    this.setState({ isLoading: true });

    axios
      .get("/reitem/" + this.state.id)
      .then((res) => {
        let list = [];

        if (res.data.length) {
          res.data.map((item) => {
            return list.push({
              id: item.id,
              itemId: item.item_id,
              itemName: item.s_name,
              remainQty: item.s_remain_qty,
              unit: item.s_unit,
              qty: item.qty,
              remark: item.remark,
            });
          });

          this.setState({
            reItems: list,
            selectedItems: [],
            selectedId: [],
            isEditing: false,
          });
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleOnChange = (e) => {
    let val = "";

    if (e.target.type === "checkbox") {
      val = e.target.checked;
    } else {
      val = e.target.value;
    }

    this.setState({ [e.target.name]: val });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    // validate required input
    if (this.missingRequiredField()) return;

    this.setState({ isLoading: true });

    const data = new FormData();
    data.append("job", this.state.job);
    data.append("staff", this.state.staff);
    data.append("remark", this.state.remark === "" ? "-" : this.state.remark);
    data.append("status", params.STATUS.OPEN);
    data.append("items", JSON.stringify(this.node.table.props.data));
    data.append("itemsEdit", this.state.isEditing);
    if (this.state.isCreate) {
      axios
        .post("/re", data)
        .then((success) => {
          this.setState({
            id: success.data.item.id,
            reCode: success.data.item.code,
            selectedItems: [],
            selectedId: [],
            isCreate: false,
            isEditing: false,
          });

          this.props.history.push("/re/" + this.state.id);
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    } else {
      axios.post("/re/" + this.state.id, data).finally(() => {
        this.setState(
          { isLoading: false, isEditing: false },
          this.getReItemList()
        );
      });
    }
  };

  handleOnStatus = (e) => {
    e.preventDefault();

    const status = e.target.value;
    const data = new FormData();
    data.append("status", status);

    let toastType;
    if (status === params.STATUS.APPROVED) toastType = toastConfirmSuccess;
    else toastType = toastConfirmDanger;

    toastType.fire().then((result) => {
      if (result.isConfirmed) {
        this.setState({ isLoading: true });

        axios
          .post("/re/" + this.state.id + "/status", data)
          .then(() => {
            this.setState({ status: status });
          })
          .finally(() => {
            this.setState({ isLoading: false });
          });
      }
    });
  };

  handleOnAddItem = (e) => {
    if (!this.state.stockItem) return;

    let itemId = this.state.stockItem;
    let selectedItem = this.state.stockItemList.filter(function (o) {
      return itemId === o.obj.id;
    });

    // set data for po item
    let newItem = {
      id: this.NEW_ID--,
      itemId: selectedItem[0].obj.id,
      itemName: selectedItem[0].obj.name,
      remainQty: selectedItem[0].obj.remain_qty,
      unit: selectedItem[0].obj.unit,
      qty: 1,
      remark: null,
    };

    this.setState(
      {
        reItems: [...this.state.reItems, newItem],
        selectedItems: [],
        selectedId: [],
        isEditing: true,
      }
    );
  };

  handleOnDeleteItem = (e) => {
    let selectedItems = this.state.selectedItems;

    toastConfirmDanger
      .fire({
        title: "Delete " + selectedItems.length + " item(s) from the list?",
        confirmButtonText: "Delete",
      })
      .then((result) => {
        if (result.isConfirmed) {
          let filterList = this.state.reItems.filter(function (o) {
            return !selectedItems.includes(o);
          });

          this.setState(
            {
              reItems: filterList,
              selectedItems: [],
              selectedId: [],
              isEditing: true,
            }
          );
        }
      });
  };

  handleOnSelectItemSingle = (row, isSelected) => {
    if (isSelected) {
      this.setState({
        selectedItems: [...this.state.selectedItems, row],
        selectedId: [...this.state.selectedId, row.id],
      });
    } else {
      let selectedId = this.state.selectedId.filter((item) => item !== row.id);
      let filterList = this.state.selectedItems.filter(function (o) {
        return row.id !== o.id;
      });

      this.setState({ selectedItems: filterList, selectedId: selectedId });
    }
  };

  handleOnSelectItemAll = (isSelected, row) => {
    if (isSelected) {
      let selectedId = [];
      row.forEach((item) => selectedId.push(item.id));

      this.setState({ selectedItems: row, selectedId: selectedId });
    } else {
      this.setState({ selectedItems: [], selectedId: [] });
    }
  };

  handleOnDiscard = (e) => {
    this.setState({ isLoading: true });
    this.getReItemList();
  };

  handleOnTableChange = (oldValue, newValue, row, column) => {
    if (oldValue !== newValue) {
      this.setState({ isEditing: true });
    }
  };

  missingRequiredField = () => {
    if (!this.state.job)
      return toastPopupError.fire({ title: "Job is required!" });
    if (!this.state.staff)
      return toastPopupError.fire({ title: "Staff is required!" });

    return false;
  };

  render() {
    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader
            title="RE "
            subtitle={this.state.id}
            prevDir="RE"
            prevUrl="/re"
            activeDir="Detail"
            button="hidden"
          />

          <section className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                RE No.
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Form.Control
                                name="reCode"
                                value={this.state.reCode}
                                onChange={this.handleOnChange}
                                autoComplete="false"
                                disabled
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Status
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <h4>
                                <div
                                  className={
                                    this.state.status === params.STATUS.OPEN
                                      ? "badge badge-primary"
                                      : this.state.status ===
                                        params.STATUS.APPROVED
                                      ? "badge badge-success"
                                      : this.state.status ===
                                        params.STATUS.CANCELLED
                                      ? "badge badge-danger"
                                      : "badge badge-secondary"
                                  }
                                >
                                  {this.state.status}
                                </div>
                              </h4>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Job *
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Select
                                name="job"
                                className="basic-select"
                                classNamePrefix="select"
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                isSearchable={true}
                                options={this.state.jobList}
                                value={
                                  this.state.jobList[
                                    this.state.jobList.findIndex(
                                      (obj) => obj.value === this.state.job
                                    )
                                  ]
                                }
                                onChange={(e) => {
                                  this.setState({ job: e.value });
                                }}
                                isDisabled={
                                  this.state.status ===
                                    params.STATUS.APPROVED ||
                                  this.state.status ===
                                    params.STATUS.CANCELLED
                                }
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Staff *
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Select
                                name="staff"
                                className="basic-select"
                                classNamePrefix="select"
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                isSearchable={true}
                                options={this.state.staffList}
                                value={
                                  this.state.staffList[
                                    this.state.staffList.findIndex(
                                      (obj) => obj.value === this.state.staff
                                    )
                                  ]
                                }
                                onChange={(e) =>
                                  this.setState({ staff: e.value })
                                }
                                isDisabled={
                                  this.state.status ===
                                    params.STATUS.APPROVED ||
                                  this.state.status === params.STATUS.CANCELLED
                                }
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col sm={3}>
                              <Form.Label className="col-form-label">
                                Remark
                              </Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                name="remark"
                                value={this.state.remark}
                                onChange={this.handleOnChange}
                                autoComplete="false"
                                disabled={
                                  this.state.status ===
                                    params.STATUS.APPROVED ||
                                  this.state.status === params.STATUS.CANCELLED
                                }
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Body>
                      <BootstrapTable
                        ref={(n) => (this.node = n)}
                        keyField="id"
                        data={this.state.reItems}
                        columns={this.COLUMNS}
                        cellEdit={cellEditFactory({
                          mode:
                            this.state.status === params.STATUS.APPROVED ||
                            this.state.status === params.STATUS.CANCELLED
                              ? ""
                              : "click",
                          blurToSave: true,
                          afterSaveCell: this.handleOnTableChange,
                        })}
                        selectRow={{
                          mode: "checkbox",
                          selectColumnStyle: {
                            width: "2%",
                          },
                          selected: this.state.selectedId,
                          onSelect: this.handleOnSelectItemSingle,
                          onSelectAll: this.handleOnSelectItemAll,
                        }}
                      />
                      <Row
                        style={{
                          display:
                            this.state.selectedItems.length === 0 ||
                            this.state.status === params.STATUS.APPROVED ||
                            this.state.status === params.STATUS.CANCELLED
                              ? "none"
                              : "",
                        }}
                      >
                        <Col md={12}>
                          <Button
                            variant="outline-danger"
                            onClick={this.handleOnDeleteItem}
                          >
                            <i className="fas fa-trash-alt mr-2" />
                            Delete
                          </Button>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          display:
                            this.state.selectedItems.length > 0 ||
                            this.state.status === params.STATUS.APPROVED ||
                            this.state.status === params.STATUS.CANCELLED
                              ? "none"
                              : "",
                        }}
                      >
                        <Col md={7}>
                          <Select
                            name="stockItem"
                            className="basic-select"
                            classNamePrefix="select"
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                            isSearchable={true}
                            options={this.state.stockItemList}
                            value={
                              this.state.stockItemList[
                                this.state.stockItemList.findIndex(
                                  (obj) => obj.value === this.state.stockItem
                                )
                              ]
                            }
                            onChange={(e) =>
                              this.setState({ stockItem: e.value })
                            }
                          />
                        </Col>
                        <Col md={5}>
                          <Button
                            variant="default"
                            onClick={this.handleOnAddItem}
                            disabled={!this.state.stockItem}
                          >
                            <i className="fas fa-plus mr-2" />
                            Add item
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <font
                    color="red"
                    style={{
                      display: this.state.isEditing ? "" : "none",
                    }}
                  >
                    You are editing items. Please save or discard changes.
                  </font>
                  <ButtonLoading
                    name="Cancel RE"
                    value={params.STATUS.CANCELLED}
                    className="btn btn-danger mr-2"
                    disabled={this.state.isLoading}
                    onClick={this.handleOnStatus}
                    hidden={
                      this.state.isCreate ||
                      this.state.isEditing ||
                      this.state.status === params.STATUS.APPROVED ||
                      this.state.status === params.STATUS.CANCELLED
                    }
                  />

                  <ButtonLoading
                    name="Approve"
                    value={params.STATUS.APPROVED}
                    className="btn btn-success mr-2"
                    disabled={this.state.isLoading}
                    onClick={this.handleOnStatus}
                    hidden={
                      this.state.isCreate ||
                      this.state.isEditing ||
                      this.state.status === params.STATUS.APPROVED ||
                      this.state.status === params.STATUS.CANCELLED
                    }
                  />
                  <ButtonLoading
                    name={this.state.isCreate ? "Submit" : "Save"}
                    className="btn btn-primary float-right mr-2"
                    disabled={this.state.isLoading}
                    onClick={this.handleOnSubmit}
                    hidden={
                      this.state.status === params.STATUS.APPROVED ||
                      this.state.status === params.STATUS.CANCELLED
                    }
                  />
                  <ButtonLoading
                    name="Discard"
                    className="btn btn-default float-right mr-2"
                    disabled={this.state.isLoading}
                    onClick={this.handleOnDiscard}
                    hidden={this.state.isCreate || !this.state.isEditing}
                  />
                  <Button
                    variant="default"
                    className="float-right mr-2"
                    hidden={this.state.isCreate || this.state.isEditing}
                    href={"/re/" + this.state.id + "/print"}
                    target="_blank"
                  >
                    <i className="fas fa-print mr-2"></i>Print
                  </Button>
                </Col>
              </Row>
              <br />
            </Container>
          </section>
        </div>

        <Footer />
        <PageLoader marginTop="30%" loading={this.state.isLoading} />
      </div>
    );
  }
}

import React, { Component } from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import axios from "axios";

import PageLoader from "../components/PageLoader";

export default class ReportPrint4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      jobStatus: "",
      isLoading: true,
      project: "",
      roomCode: "",
      createdAt: new Date(),
      staffFirstname: "",
      staffLastname: "",
      staffNickname: "",
      staffEmail: "",
      customerFirstname: "",
      customerLastname: "",
      customerNickname: "",
      items: [],
    };
  }

  componentDidMount = async () => {
    await axios.get("/job/" + this.state.id).then((success) => {
      this.setState({
        jobStatus: success.data.job.status,
        project: success.data.roomCode.project_id,
        roomCode: success.data.roomCode.room_code,
        createdId: success.data.job.created_by,
        createdAt: success.data.job.created_at,
      });
    });

    // Promise all
    let fetchData1 = axios
      .get("/staff/" + this.state.createdId)
      .then((res1) => {
        this.setState({
          staffFirstname: res1.data.firstname,
          staffLastname: res1.data.lastname,
          staffNickname: res1.data.nickname,
          staffEmail: res1.data.email,
        });
      });

    let fetchData2 = axios
      .get("/project/" + this.state.project)
      .then((res1) => {
        this.setState({
          customerFirstname: res1.data.customer.firstname,
          customerLastname: res1.data.customer.lastname,
          customerNickname: res1.data.customer.nickname,
        });
      });
    let fetchData3 = axios
      .get("/paint/" + this.state.id + "/job")
      .then((res3) => {
        this.setState({
          items: res3.data,
        });
      });

    Promise.all([fetchData1, fetchData2, fetchData3]).then((success) => {
      this.setState({ isLoading: false });
      window.addEventListener("load", window.print());
    });
  };

  render() {
    return (
      <section className="invoice">
        <Container fluid>
          <Card>
            <Card.Body>
              <Row>
                <Col md={12}>
                  <h2 className="page-header">
                    D2P Cabinet Solutions (Paint)
                    <small className="float-right">
                      {"Printed Date: " +
                        new Date().getDate() +
                        "/" +
                        (new Date().getMonth() + 1) +
                        "/" +
                        new Date().getFullYear()}
                    </small>
                  </h2>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={12}>
                  {/* info row */}
                  <PageLoader loading={this.state.isLoading} marginTop="25%" />
                  <Row className="invoice-info">
                    <Col sm={4} className="invoice-col">
                      Project
                      <address>
                        <strong>
                          {this.state.project + " / " + this.state.roomCode}
                        </strong>
                        <br />
                        <br />
                        {this.state.customerFirstname +
                          " " +
                          this.state.customerLastname +
                          " (" +
                          this.state.customerNickname +
                          ")"}
                      </address>
                    </Col>

                    <Col sm={4} className="invoice-col">
                      <b>Job ID #{this.state.id}</b>
                      <br />
                      <br />
                      <b>Status:</b>
                      {" " + this.state.jobStatus}
                      <br />
                      <b>Total Part:</b> {this.state.items.length + " pcs."}
                    </Col>
                    <Col sm={4} className="invoice-col">
                      <b>Created at:</b>{" "}
                      {new Date(this.state.createdAt).toLocaleString("en-GB")}
                      <br />
                      <b>Created by:</b>{" "}
                      {this.state.staffFirstname +
                        " (" +
                        this.state.staffNickname +
                        ")"}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={12}>
                      <Table striped responsive>
                        <thead>
                          <tr>
                            <th>no.</th>
                            <th>ตำแหน่ง</th>
                            <th>รหัสตู้</th>
                            <th>ชื่อชิ้นงาน</th>
                            <th>กว้าง x ยาว</th>
                            <th>จำนวน</th>
                            <th>รหัสสี (หน้า)</th>
                            <th>ส่งสินค้า</th>
                            <th>รับสินค้า</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.items.map((paint) => {
                            return (
                              <tr key={paint.id}>
                                <td>{paint.no}</td>
                                <td>{paint.pos_no}</td>
                                <td>{paint.fur_code}</td>
                                <td>{paint.part_name}</td>
                                <td>
                                  {paint.width}{" x "}
                                  {paint.height}
                                </td>
                                <td>{paint.qty}</td>
                                <td>
                                  {paint.paint_code} ({paint.paint_face_no})
                                </td>
                                <td>_________</td>
                                <td>_________</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    );
  }
}

import React, { Component } from "react";
import Select from "react-select";
import {
  Container,
  Form,
  Row,
  Col,
  Card,
  Button,
  InputGroup,
} from "react-bootstrap";
import { NavLink, Redirect } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";

import Header from "../components/Header";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import PageLoader from "../components/PageLoader";
import ButtonLoading from "../components/ButtonLoading";

import params from "../utils/params";
import localStorage from "../utils/localStorage";
import { toastTimerSuccess, toastConfirmPrimary } from "../utils/sweetAlert";

export default class ReportDetail extends Component {
  STATUS_BUTTON = {
    name: "Status",
    width: "10%",
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    cell: (row) => (
      <h5>
        <span
          className={
            row.status === params.MATERIAL.PURCHASE
              ? "badge badge-secondary"
              : row.status === params.MATERIAL.PROCESS
                ? "badge badge-warning"
                : row.status === params.MATERIAL.PAYMENT
                  ? "badge badge-danger"
                  : row.status === params.MATERIAL.DELIVER
                    ? "badge badge-primary"
                    : row.status === params.MATERIAL.READY
                      ? "badge badge-info"
                      : row.status === params.MATERIAL.COMPLETED
                        ? "badge badge-success"
                        : ""
          }
        >
          {row.status === params.MATERIAL.PURCHASE ? (
            <i className="fas fa-shopping-cart"></i>
          ) : row.status === params.MATERIAL.PROCESS ? (
            <i className="fas fa-cogs"></i>
          ) : row.status === params.MATERIAL.PAYMENT ? (
            <i className="fas fa-credit-card"></i>
          ) : row.status === params.MATERIAL.DELIVER ? (
            <i className="fas fa-shipping-fast"></i>
          ) : row.status === params.MATERIAL.READY ? (
            <i className="fas fa-pallet"></i>
          ) : row.status === params.MATERIAL.COMPLETED ? (
            <i className="fas fa-check-circle"></i>
          ) : (
            <i className="fas"></i>
          )}
        </span>
      </h5>
    ),
  };
  COLUMNS = {
    REPT1: [
      this.STATUS_BUTTON,
      {
        name: "Item Type",
        width: "10%",
        selector: "item_type",
        wrap: true,
        sortable: true,
      },
      {
        name: "Item ID",
        width: "15%",
        selector: "stock_item_id",
        wrap: true,
        sortable: true,
      },
      {
        name: "Item Name",
        selector: "item_name",
        sortable: true,
        wrap: true,
      },
      {
        name: "Quantity",
        width: "10%",
        selector: "qty",
        sortable: true,
        right: true,
      },
      {
        name: "Unit",
        width: "10%",
        selector: "unit",
        sortable: true,
      },
    ],
    REPT2: [
      this.STATUS_BUTTON,
      {
        name: "Fur Code",
        width: "10%",
        selector: "fur_code",
        wrap: true,
        sortable: true,
      },
      {
        name: "Item Type",
        width: "10%",
        selector: "item_type",
        wrap: true,
        sortable: true,
      },
      {
        name: "Pos",
        width: "5%",
        selector: "pos_no",
        wrap: true,
        sortable: true,
      },
      {
        name: "Item ID",
        width: "15%",
        selector: "stock_item_id",
        wrap: true,
        sortable: true,
      },
      {
        name: "Item Name",
        selector: "item_name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Quantity",
        width: "10%",
        selector: "qty",
        sortable: true,
        right: true,
      },
      {
        name: "Unit",
        width: "10%",
        selector: "unit",
        sortable: true,
      },
    ],
    REPT3: [
      this.STATUS_BUTTON,
      {
        name: "Item Type",
        width: "5%",
        selector: "item_type",
        wrap: true,
        sortable: true,
      },
      {
        name: "Supplier ID",
        width: "10%",
        selector: "supplier_id",
        wrap: true,
        sortable: true,
      },
      {
        name: "Supplier ArtNo.",
        width: "15%",
        selector: "supplier_artno",
        wrap: true,
        sortable: true,
      },
      {
        name: "Item Name",
        selector: "item_name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Quantity",
        width: "5%",
        selector: "qty",
        wrap: true,
        sortable: true,
        right: true,
      },
      {
        name: "Unit",
        width: "5%",
        selector: "unit",
        wrap: true,
        sortable: true,
      },
      {
        name: "Item ID",
        width: "10%",
        selector: "stock_item_id",
        wrap: true,
        sortable: true,
      },
      {
        name: "UID",
        width: "10%",
        selector: "uid",
        wrap: true,
        sortable: true,
      },
    ],
    REPT4: [
      {
        name: "no.",
        selector: "no",
        wrap: true,
        sortable: true,
      },
      {
        name: "ตำแหน่ง",
        selector: "pos_no",
        wrap: true,
        sortable: true,
      },
      {
        name: "รหัสตู้",
        selector: "fur_code",
        wrap: true,
        sortable: true,
      },
      {
        name: "ชื่อชิ้นงาน",
        selector: "part_name",
        wrap: true,
        sortable: true,
      },
      {
        name: "กว้าง x ยาว",
        selector: "qty",
        sortable: true,
        cell: (row) => <span>{row.width + " x " + row.height}</span>,
      },
      {
        name: "จำนวน",
        selector: "qty",
        sortable: true,
        right: true,
      },
      {
        name: "รหัสสี (หน้า)",
        selector: "unit",
        sortable: true,
        cell: (row) => (
          <span>{row.paint_code + " (" + row.paint_face_no + ")"}</span>
        ),
      },
    ],
    REPT5: [
      {
        name: "Item Type",
        selector: "item_type",
        wrap: true,
        sortable: true,
      },
      {
        name: "Item Name",
        selector: "item_name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Quantity",
        selector: "qty",
        sortable: true,
        right: true,
      },
      {
        name: "Unit",
        selector: "unit",
        sortable: true,
      },
    ],
  };

  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getUser());
    if (!user) {
      this.state = { invalid: true };
      return;
    }

    this.state = {
      role: user.role,

      jobId: props.match.params.id,
      fileId: "",
      jobStatus: "",
      project: "",
      roomCode: "",
      jobNote: "",
      typeOptions: [
        { value: 1, label: "รายการฟิตติ้ง (ตามรหัสสินค้า)" },
        { value: 2, label: "รายการฟิตติ้ง (ตาม BFcode/FURcode)" },
        { value: 3, label: "รายการวัตถุดิบในการผลิต" },
        { value: 4, label: "รายการชิ้นส่วนทำสี" },
        { value: 5, label: "สรุป Optimize Panel" },
      ],
      type: 1,
      isLoading: true,

      // table
      items: [],
      filterText: "",
      filterItems: [],
      columns: [],
      selectedRow: [],
      toggledClearRows: false,
      customStyles: {
        headCells: {
          style: {
            //fontSize: 16,
            fontWeight: "bold",
            padding: "5px",
          },
        },
        cells: {
          style: {
            //fontSize: 16,
            padding: "5px",
          },
        },
      },
    };
  }

  componentDidMount() {
    axios.get("/job/" + this.state.jobId).then((success) => {
      this.setState({
        project: success.data.job.project_id,
        roomCode: success.data.roomCode.room_code,
        jobStatus: success.data.job.status,
        fileId: success.data.file.id,
        jobNote: success.data.job.note,
      });
    });

    this.getMaterialTable(this.state.type);
  }

  handleOnChange = (e) => {
    this.setState({ type: e.value, isLoading: true });
    this.getMaterialTable(e.value);
  };

  handleOnSelect = (e) => {
    this.setState({ selectedRow: e.selectedRows });
  };

  handleOnClick = (e) => {
    let value = e.target.value;

    toastConfirmPrimary.fire().then((result) => {
      if (result.isConfirmed) {
        this.setState({ isLoading: true });

        let data = new FormData();
        data.append("items", JSON.stringify(this.state.selectedRow));

        axios
          .post(
            "/material/" +
            this.state.jobId +
            "/" +
            value +
            "/" +
            this.state.type,
            data
          )
          .then((success) => {
            toastTimerSuccess.fire({
              title: "Material's status has been updated",
            });
          })
          .finally(() => {
            this.getMaterialTable(this.state.type);
          });
      }
    });
  };

  handleOnSearch = (e) => {
    this.setState({ filterText: e.target.value });
    this.filterTable(e.target.value);
  };

  getMaterialTable = (type) => {
    // set table header
    let columns = [];
    let url = "/material/" + this.state.jobId + "/" + type;

    if (type === 1) {
      columns = this.COLUMNS.REPT1;
    } else if (type === 2) {
      columns = this.COLUMNS.REPT2;
    } else if (type === 3) {
      columns = this.COLUMNS.REPT3;
    } else if (type === 4) {
      columns = this.COLUMNS.REPT4;
      url = "/paint/" + this.state.jobId + "/job";
    } else {
      columns = this.COLUMNS.REPT5;
    }

    axios
      .get(url)
      .then((success) => {
        this.setState({
          items: success.data,
          columns: columns,
          toggledClearRows: !this.state.toggledClearRows,
        });
      })
      .finally(() => {
        this.filterTable(this.state.filterText);
        this.setState({ type: type, isLoading: false });
      });
  };

  filterTable = (search) => {
    let filterItems = [];
    let text = search.toString();

    if (this.state.type === 1) {
      filterItems = this.state.items.filter(
        (item) =>
          (item.item_type &&
            item.item_type
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.stock_item_id &&
            item.stock_item_id
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.item_name &&
            item.item_name
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.qty &&
            item.qty.toString().toLowerCase().includes(text.toLowerCase())) ||
          (item.unit &&
            item.unit.toString().toLowerCase().includes(text.toLowerCase()))
      );
    } else if (this.state.type === 2) {
      filterItems = this.state.items.filter(
        (item) =>
          (item.fur_code &&
            item.fur_code
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.item_type &&
            item.item_type
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.pos_no &&
            item.pos_no
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.stock_item_id &&
            item.stock_item_id
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.item_name &&
            item.item_name
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.qty &&
            item.qty.toString().toLowerCase().includes(text.toLowerCase())) ||
          (item.unit &&
            item.unit.toString().toLowerCase().includes(text.toLowerCase()))
      );
    } else if (this.state.type === 3) {
      filterItems = this.state.items.filter(
        (item) =>
          (item.item_type &&
            item.item_type
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.item_name &&
            item.item_name
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.supplier_id &&
            item.supplier_id
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.supplier_artno &&
            item.supplier_artno
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.qty &&
            item.qty.toString().toLowerCase().includes(text.toLowerCase())) ||
          (item.unit &&
            item.unit.toString().toLowerCase().includes(text.toLowerCase())) ||
          (item.stock_item_id &&
            item.stock_item_id
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.uid &&
            item.uid.toString().toLowerCase().includes(text.toLowerCase()))
      );
    } else if (this.state.type === 4) {
      filterItems = this.state.items.filter(
        (item) =>
          (item.no &&
            item.no.toString().toLowerCase().includes(text.toLowerCase())) ||
          (item.pos_no &&
            item.pos_no
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.fur_code &&
            item.fur_code
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.part_name &&
            item.part_name
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.width + " x " + item.height &&
            (item.width + " x " + item.height)
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.qty &&
            item.qty.toString().toLowerCase().includes(text.toLowerCase())) ||
          (item.paint_code + " (" + item.paint_face_no + ")" &&
            (item.paint_code + " (" + item.paint_face_no + ")")
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase()))
      );
    } else {
      filterItems = this.state.items.filter(
        (item) =>
          (item.item_type &&
            item.item_type
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.item_name &&
            item.item_name
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase())) ||
          (item.qty &&
            item.qty.toString().toLowerCase().includes(text.toLowerCase())) ||
          (item.unit &&
            item.unit.toString().toLowerCase().includes(text.toLowerCase()))
      );
    }

    this.setState({ filterItems: filterItems });
  };

  render() {
    if (this.state.invalid) {
      return <Redirect to="/logout" />;
    }

    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader
            title="Report "
            subtitle={this.state.jobId}
            prevDir="Report"
            prevUrl="/report"
            activeDir="Detail"
            button="hidden"
          />

          <section className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col md={3}>
                              <Form.Label>Project</Form.Label>
                            </Col>
                            <Col md={9}>
                              <NavLink to={"/job/" + this.state.fileId}>
                                {this.state.project}
                              </NavLink>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Col md={3}>
                              <Form.Label>Room Code</Form.Label>
                            </Col>
                            <Col md={9}>
                              <NavLink to={"/job/" + this.state.fileId}>
                                {this.state.roomCode}
                              </NavLink>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col md={3}>
                              <Form.Label>Job ID</Form.Label>
                            </Col>
                            <Col md={9}>{this.state.jobId}</Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Col md={3}>
                              <Form.Label>Status</Form.Label>
                            </Col>
                            <Col md={9}>
                              <small
                                className={
                                  this.state.jobStatus ===
                                    params.STATUS.ERROR ||
                                    this.state.jobStatus ===
                                    params.STATUS.CANCELLED
                                    ? "badge badge-danger"
                                    : this.state.jobStatus ===
                                      params.STATUS.SUCCESS ||
                                      this.state.jobStatus ===
                                      params.STATUS.CONFIRMED
                                      ? "badge badge-success"
                                      : this.state.jobStatus ===
                                        params.STATUS.WARNING ||
                                        this.state.jobStatus ===
                                        params.STATUS.PENDING
                                        ? "badge badge-warning"
                                        : this.state.jobStatus ===
                                          params.STATUS.SUBMITTED
                                          ? "badge badge-primary"
                                          : "badge badge-secondary"
                                }
                              >
                                {this.state.jobStatus}
                              </small>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col md={3}>
                              <Form.Label>Type</Form.Label>
                            </Col>
                            <Col md={9}>
                              <Select
                                name="type"
                                className="basic-select"
                                classNamePrefix="select"
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                isSearchable={false}
                                options={this.state.typeOptions}
                                value={
                                  this.state.typeOptions[
                                  this.state.typeOptions.findIndex(
                                    (obj) => obj.value === this.state.type
                                  )
                                  ]
                                }
                                onChange={this.handleOnChange}
                                isDisabled={this.state.isLoading}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Col md={3}></Col>
                            <Col md={9}></Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group as={Row}>
                            <Col md={3}>
                              <Form.Label>Note</Form.Label>
                            </Col>
                            <Col md={9}>
                              <div style={{ whiteSpace: "pre-wrap" }}>
                                {this.state.jobNote ? this.state.jobNote : "-"}
                              </div>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Row}>
                            <Col md={3}></Col>
                            <Col md={9}></Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col md={8}>
                          <ButtonLoading
                            name="รอจัดซื้อ"
                            value={params.MATERIAL.PURCHASE}
                            className="btn btn-sm btn-secondary mr-2"
                            disabled={this.state.isLoading}
                            readOnly={
                              this.state.type === 2 ||
                              this.state.selectedRow.length === 0 ||
                              this.state.jobStatus !== params.STATUS.CONFIRMED
                            }
                            hidden={
                              this.state.type === 4 || this.state.type === 5
                            }
                            onClick={this.handleOnClick}
                            icon="fa-shopping-cart"
                          />
                          <ButtonLoading
                            name="กำลังดำเนินการ"
                            value={params.MATERIAL.PROCESS}
                            className="btn btn-sm btn-warning mr-2"
                            disabled={this.state.isLoading}
                            readOnly={
                              this.state.type === 2 ||
                              this.state.selectedRow.length === 0 ||
                              this.state.jobStatus !== params.STATUS.CONFIRMED
                            }
                            hidden={
                              this.state.type === 4 || this.state.type === 5
                            }
                            onClick={this.handleOnClick}
                            icon="fa-cogs"
                          />
                          <ButtonLoading
                            name="รอชำระ"
                            value={params.MATERIAL.PAYMENT}
                            className="btn btn-sm btn-danger mr-2"
                            disabled={this.state.isLoading}
                            readOnly={
                              this.state.type === 2 ||
                              this.state.selectedRow.length === 0 ||
                              this.state.jobStatus !== params.STATUS.CONFIRMED
                            }
                            hidden={
                              this.state.type === 4 || this.state.type === 5
                            }
                            onClick={this.handleOnClick}
                            icon="fa-credit-card"
                          />
                          <ButtonLoading
                            name="รอของเข้า"
                            value={params.MATERIAL.DELIVER}
                            className="btn btn-sm btn-primary mr-2"
                            disabled={this.state.isLoading}
                            readOnly={
                              this.state.type === 2 ||
                              this.state.selectedRow.length === 0 ||
                              this.state.jobStatus !== params.STATUS.CONFIRMED
                            }
                            hidden={
                              this.state.type === 4 || this.state.type === 5
                            }
                            onClick={this.handleOnClick}
                            icon="fa-shipping-fast"
                          />
                          <ButtonLoading
                            name="พร้อมเบิก"
                            value={params.MATERIAL.READY}
                            className="btn btn-sm btn-info mr-2"
                            disabled={this.state.isLoading}
                            readOnly={
                              this.state.type === 2 ||
                              this.state.selectedRow.length === 0 ||
                              this.state.jobStatus !== params.STATUS.CONFIRMED
                            }
                            hidden={
                              this.state.type === 4 || this.state.type === 5
                            }
                            onClick={this.handleOnClick}
                            icon="fa-pallet"
                          />
                          <ButtonLoading
                            name="เสร็จสิ้น"
                            value={params.MATERIAL.COMPLETED}
                            className="btn btn-sm btn-success mr-2"
                            disabled={this.state.isLoading}
                            readOnly={
                              this.state.type === 2 ||
                              this.state.selectedRow.length === 0 ||
                              this.state.jobStatus !== params.STATUS.CONFIRMED
                            }
                            hidden={
                              this.state.type === 4 || this.state.type === 5
                            }
                            onClick={this.handleOnClick}
                            icon="fa-check-circle"
                          />
                        </Col>
                        <Col md={4}>
                          <Form.Group as={Row}>
                            <Col sm={12}>
                              <InputGroup>
                                <Form.Control
                                  name="searchBox"
                                  className="float-right"
                                  placeholder="Search ..."
                                  value={this.state.filterText}
                                  onChange={this.handleOnSearch}
                                //onBlur={this.handleOnUpdate}
                                //disabled={this.state.id ? "disabled" : ""}
                                />
                                <InputGroup.Append>
                                  <Form.Label>
                                    <Button
                                      variant="default"
                                      onClick={() => {
                                        this.setState({ filterText: "" });
                                        this.filterTable("");
                                      }}
                                    >
                                      <i className="fas fa-times"></i>
                                    </Button>
                                  </Form.Label>
                                </InputGroup.Append>
                              </InputGroup>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Button
                        variant="default"
                        className="float-right"
                        hidden={this.state.type !== 2}
                        href={"/report/" + this.state.jobId + "/print/2"}
                        target="_blank"
                      >
                        <i className="fas fa-print mr-2"></i>Print
                      </Button>
                      <Button
                        variant="default"
                        className="float-right"
                        hidden={this.state.type !== 4}
                        href={"/report/" + this.state.jobId + "/print/4"}
                        target="_blank"
                      >
                        <i className="fas fa-print mr-2"></i>Print
                      </Button>
                      <div
                        style={{
                          display: this.state.isLoading ? "none" : "",
                        }}
                      >
                        <DataTable
                          columns={this.state.columns}
                          data={this.state.filterItems}
                          customStyles={this.state.customStyles}
                          highlightOnHover
                          clearSelectedRows={this.state.toggledClearRows}
                          noHeader={
                            this.state.type === 2 ||
                              this.state.type === 4 ||
                              this.state.type === 5
                              ? true
                              : false
                          } // not show for type 2/4/5 + role PROD/MGMT-PROD
                          selectableRows={
                            this.state.type === 2 ||
                              this.state.type === 4 ||
                              this.state.type === 5 ||
                              this.state.jobStatus !== params.STATUS.CONFIRMED ||
                              this.state.role === params.ROLE.PRODUCTION ||
                              this.state.role === params.ROLE.PRODUCTION_MANAGER
                              ? false
                              : true
                          } // not show for type 2/4/5
                          onSelectedRowsChange={this.handleOnSelect}
                          pagination
                          paginationPerPage={50}
                          paginationRowsPerPageOptions={[50, 100, 500, 1000]}
                        />
                      </div>
                      <div
                        style={{
                          display: this.state.isLoading ? "" : "none",
                        }}
                      >
                        <PageLoader loading={this.state.isLoading} />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
      </div>
    );
  }
}

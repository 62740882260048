import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// initial axios default
import "./utils/setupAxios";

import RequireAuth from "./services/RequireAuth";
import SignUpActivate from "./services/SignUpActivate";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import PasswordForget from "./pages/PasswordForget";
import PasswordReset from "./pages/PasswordReset";

import Dashboard from "./pages/Dashboard";

import Item from "./pages/Item";
import ItemDetail from "./pages/ItemDetail";

import Job from "./pages/Job";
import JobDetail from "./pages/JobDetail";
import JobPrintDetail from "./pages/JobPrintDetail";
import Report from "./pages/Report";
import ReportDetail from "./pages/ReportDetail";
import ReportPrint2 from "./pages/ReportPrint2";
import ReportPrint4 from "./pages/ReportPrint4";
import Status from "./pages/Status";

import PurchaseOrder from "./pages/PurchaseOrder";
import PurchaseOrderDetail from "./pages/PurchaseOrderDetail";
import PurchaseOrderPrint from "./pages/PurchaseOrderPrint";
import ReceivedOrder from "./pages/ReceivedOrder";
import ReceivedOrderDetail from "./pages/ReceivedOrderDetail";
import ReceivedOrderPrint from "./pages/ReceivedOrderPrint";
import RequisitionOrder from "./pages/RequisitionOrder";
import RequisitionOrderDetail from "./pages/RequisitionOrderDetail";
import RequisitionOrderPrint from "./pages/RequisitionOrderPrint";

import Staff from "./pages/Staff";
import StaffDetail from "./pages/StaffDetail";
import Customer from "./pages/Customer";
import CustomerDetail from "./pages/CustomerDetail";
import Project from "./pages/Project";
import ProjectDetail from "./pages/ProjectDetail";
import Supplier from "./pages/Supplier";
import SupplierDetail from "./pages/SupplierDetail";

import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";

export default class App extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/signup/activate/:token" component={SignUpActivate} />
          <Route exect path="/password/forget" component={PasswordForget} />
          <Route
            exect
            path="/password/reset/:token"
            component={PasswordReset}
          />
          <Route exect path="/logout" component={Logout} />
          <Route exact path="/500" component={Error500} />
          <RequireAuth>
            <Switch>
              <Route exact path="/dashboard" component={Dashboard} />

              <Route exact path="/item" component={Item} />
              <Route exact path="/item/create" component={ItemDetail} />
              <Route exact path="/item/:id" component={ItemDetail} />

              <Route exact path="/job" component={Job} />
              <Route exact path="/job/create" component={JobDetail} />
              <Route exact path="/job/:id" component={JobDetail} />
              <Route exact path="/job/:id/print/job" component={JobPrintDetail} />
              <Route exact path="/report" component={Report} />
              <Route exact path="/report/:id" component={ReportDetail} />
              <Route exact path="/report/:id/print/2" component={ReportPrint2} />
              <Route exact path="/report/:id/print/4" component={ReportPrint4} />
              <Route exact path="/status" component={Status} />

              <Route exact path="/po" component={PurchaseOrder} />
              <Route exact path="/po/create" component={PurchaseOrderDetail} />
              <Route exact path="/po/:id" component={PurchaseOrderDetail} />
              <Route exact path="/po/:id/print" component={PurchaseOrderPrint} />
              <Route exact path="/ro" component={ReceivedOrder} />
              <Route exact path="/ro/create" component={ReceivedOrderDetail} />
              <Route exact path="/ro/:id" component={ReceivedOrderDetail} />
              <Route exact path="/ro/:id/print" component={ReceivedOrderPrint} />
              <Route exact path="/re" component={RequisitionOrder} />
              <Route exact path="/re/create" component={RequisitionOrderDetail} />
              <Route exact path="/re/:id" component={RequisitionOrderDetail} />
              <Route exact path="/re/:id/print" component={RequisitionOrderPrint} />

              <Route exact path="/staff" component={Staff} />
              <Route exact path="/staff/create" component={StaffDetail} />
              <Route exact path="/staff/:id" component={StaffDetail} />
              <Route exact path="/customer" component={Customer} />
              <Route exact path="/customer/create" component={CustomerDetail} />
              <Route exact path="/customer/:id" component={CustomerDetail} />
              <Route exact path="/project" component={Project} />
              <Route exact path="/project/create" component={ProjectDetail} />
              <Route exact path="/project/:id" component={ProjectDetail} />
              <Route exact path="/supplier" component={Supplier} />
              <Route exact path="/supplier/create" component={SupplierDetail} />
              <Route exact path="/supplier/:id" component={SupplierDetail} />
              <Route component={Error404} />
            </Switch>
          </RequireAuth>
        </Switch>
      </BrowserRouter>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Card, Form } from "react-bootstrap";

import Header from "../components/Header";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import PageLoader from "../components/PageLoader";
import ButtonLoading from "../components/ButtonLoading";

import params from "../utils/params";
import localStorage from "../utils/localStorage";
import { toastTimerSuccess, toastPopupError } from "../utils/sweetAlert";

export default class ItemDetail extends Component {
  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getUser());
    if (!user) {
      this.state = { invalid: true };
      return;
    }

    this.state = {
      role: user.role,

      id: props.match.params.id,
      name: "",
      remainQty: "",
      unit: "",
      spec: "",
      price: "",
      priceDate: "",
      averageCost: "",
      supplierPrice: "",
      supplierId: "",
      supplierArtno: "",
      draftCode: "",

      isLoading: false,
      isCreate: props.match.params.id ? false : true,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.setState({ isLoading: true });

      axios
        .get("/stockitem/" + this.state.id)
        .then((success) => {
          this.setState({
            id: success.data.id,
            name: success.data.name,
            remainQty: success.data.remain_qty,
            unit: success.data.unit,
            spec: success.data.spec,
            price: success.data.price,
            priceDate: success.data.price_date
              ? new Date(success.data.price_date).toLocaleDateString("en-US", {
                  year: "numeric",
                }) +
                "-" +
                new Date(success.data.price_date).toLocaleDateString("en-US", {
                  month: "2-digit",
                }) +
                "-" +
                new Date(success.data.price_date).toLocaleDateString("en-US", {
                  day: "2-digit",
                })
              : "",
            averageCost: success.data.average_cost,
            supplierPrice: success.data.supplier_price,
            supplierId: success.data.supplier_id,
            supplierArtno: success.data.supplier_artno,
            draftCode: success.data.draft_code,
          });
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  handleOnChange = (e) => {
    let val = "";

    if (e.target.type === "checkbox") {
      val = e.target.checked;
    } else {
      val = e.target.value;
    }

    this.setState({ [e.target.name]: val });
  };

  handleOnCreate = (e) => {
    e.preventDefault();

    // validate required input
    if (this.missingRequiredField()) return;

    this.setState({ isLoading: true });

    const data = new FormData();
    data.append("id", this.state.id);
    data.append("name", this.state.name);
    data.append("remainQty", this.state.remainQty);
    data.append("unit", this.state.unit);
    data.append("spec", this.state.spec);
    data.append("price", this.state.price);
    data.append("priceDate", this.state.priceDate);
    data.append("averageCost", this.state.averageCost);
    data.append("supplierPrice", this.state.supplierPrice);
    data.append("supplierId", this.state.supplierId);
    data.append("supplierArtno", this.state.supplierArtno);
    data.append("draftCode", this.state.draftCode);

    axios
      .post("/stockitem", data)
      .then((success) => {
        this.setState({
          id: success.data.item.id,
        });

        this.props.history.push("/item/" + this.state.id);
        this.setState({ isCreate: false });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleOnUpdate = (e) => {
    e.preventDefault();

    // validate required input
    if (this.state.id && this.missingRequiredField()) return;

    this.setState({ isLoading: true });

    const data = new FormData();
    data.append("name", this.state.name);
    data.append("remainQty", this.state.remainQty);
    data.append("unit", this.state.unit);
    data.append("spec", this.state.spec);
    data.append("price", this.state.price);
    data.append("priceDate", this.state.priceDate);
    data.append("averageCost", this.state.averageCost);
    data.append("supplierPrice", this.state.supplierPrice);
    data.append("supplierId", this.state.supplierId);
    data.append("supplierArtno", this.state.supplierArtno);
    data.append("draftCode", this.state.draftCode);

    axios
      .post("/stockitem/" + this.state.id, data)
      .then((success) => {
        toastTimerSuccess.fire({
          title: "Item's details has been updated",
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  missingRequiredField = (e) => {
    if (!this.state.id)
      return toastPopupError.fire({ title: "ID is required!" });
    if (!this.state.name)
      return toastPopupError.fire({ title: "Name is required!" });

    return false;
  };

  render() {
    if (this.state.invalid) {
      return <Redirect to="/logout" />;
    }

    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader
            title="Item "
            subtitle={this.state.id}
            prevDir="Item"
            prevUrl="/item"
            activeDir="Detail"
            button="hidden"
          />

          <section className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Body>
                      <Form>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              ID *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="id"
                              value={this.state.id}
                              onChange={this.handleOnChange}
                              autoComplete="false"
                              disabled={!this.state.isCreate}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Name *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="name"
                              value={this.state.name}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Spec
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="spec"
                              value={this.state.spec}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Remain Qty
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="remainQty"
                              type="number"
                              value={this.state.remainQty}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Unit
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="unit"
                              value={this.state.unit}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Average Cost
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="averageCost"
                              type="number"
                              value={this.state.averageCost}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Selling Price
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="price"
                              type="number"
                              value={this.state.price}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Selling Price Date
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              type="date"
                              name="priceDate"
                              value={this.state.priceDate}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <hr />
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Supplier Price
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="supplierPrice"
                              type="number"
                              value={this.state.supplierPrice}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Supplier ID
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="supplierId"
                              value={this.state.supplierId}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Supplier ArtNo
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="supplierArtno"
                              value={this.state.supplierArtno}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Draft Code
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="draftCode"
                              value={this.state.draftCode}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="offset-sm-2">
                          <ButtonLoading
                            name="Submit"
                            hidden={
                              !this.state.isCreate ||
                              this.state.role === params.ROLE.ACCOUNTING ||
                              this.state.role === params.ROLE.DRAFT ||
                              this.state.role === params.ROLE.DRAFT_MANAGER ||
                              this.state.role === params.ROLE.PURCHASE
                            }
                            className="btn btn-success"
                            disabled={
                              this.state.isLoading ||
                              this.state.role === params.ROLE.ACCOUNTING ||
                              this.state.role === params.ROLE.DRAFT ||
                              this.state.role === params.ROLE.DRAFT_MANAGER ||
                              this.state.role === params.ROLE.PURCHASE
                            }
                            onClick={this.handleOnCreate}
                          />
                          <ButtonLoading
                            name="Save"
                            hidden={
                              this.state.isCreate ||
                              this.state.role === params.ROLE.ACCOUNTING ||
                              this.state.role === params.ROLE.DRAFT ||
                              this.state.role === params.ROLE.DRAFT_MANAGER ||
                              this.state.role === params.ROLE.PURCHASE
                            }
                            className="btn btn-success"
                            disabled={
                              this.state.isLoading ||
                              this.state.role === params.ROLE.ACCOUNTING ||
                              this.state.role === params.ROLE.DRAFT ||
                              this.state.role === params.ROLE.DRAFT_MANAGER ||
                              this.state.role === params.ROLE.PURCHASE
                            }
                            onClick={this.handleOnUpdate}
                          />
                        </Form.Group>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
        <PageLoader marginTop="30%" loading={this.state.isLoading} />
      </div>
    );
  }
}

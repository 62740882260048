import React, { Component } from "react";
import axios from "axios";
import {} from "react-router-dom";
import Select from "react-select";
import { Container, Row, Col, Card, Image, Form } from "react-bootstrap";

import Header from "../components/Header";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import PageLoader from "../components/PageLoader";
import ButtonLoading from "../components/ButtonLoading";

import params from "../utils/params";
import { toastTimerSuccess, toastPopupError } from "../utils/sweetAlert";

export default class StaffDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      avatar: "/dist/img/avatarDefault.png",
      code: "",
      firstname: "",
      lastname: "",
      nickname: "",
      mobile: "",
      position: "",
      addrContact: "",
      addrWork: "",

      email: "",
      password: "",
      passwordConfirm: "",
      role: "",
      roleList: [
        { value: params.ROLE.ACCOUNTING, label: params.ROLE.ACCOUNTING },
        { value: params.ROLE.ADMIN, label: params.ROLE.ADMIN },
        { value: params.ROLE.DRAFT, label: params.ROLE.DRAFT },
        { value: params.ROLE.IT_SUPPORT, label: params.ROLE.IT_SUPPORT },
        { value: params.ROLE.PRODUCTION, label: params.ROLE.PRODUCTION },
        { value: params.ROLE.PURCHASE, label: params.ROLE.PURCHASE },
        { value: params.ROLE.MANAGER, label: params.ROLE.MANAGER },
        { value: params.ROLE.DRAFT_MANAGER, label: params.ROLE.DRAFT_MANAGER },
        {
          value: params.ROLE.PRODUCTION_MANAGER,
          label: params.ROLE.PRODUCTION_MANAGER,
        },
        {
          value: params.ROLE.PURCHASE_MANAGER,
          label: params.ROLE.PURCHASE_MANAGER,
        },
      ],
      status: "",
      statusList: [
        { value: 1, label: "Active" },
        { value: 0, label: "Inactive" },
      ],

      verify: true,
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.setState({ isLoading: true });

      axios
        .get("/staff/" + this.state.id)
        .then((success) => {
          this.setState({
            code: success.data.code,
            firstname: success.data.firstname,
            lastname: success.data.lastname,
            nickname: success.data.nickname,
            mobile: success.data.mobile,
            position: success.data.position ? success.data.position : "",
            addrContact: success.data.addr_contact
              ? success.data.addr_contact
              : "",
            addrWork: success.data.addr_work ? success.data.addr_work : "",

            email: success.data.user.email,
            role: success.data.user.role,
            status: success.data.user.active,
            verify: success.data.user.email_verified_at ? true : false,
          });
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  handleOnChange = (e) => {
    let val = "";

    if (e.target.type === "checkbox") {
      val = e.target.checked;
    } else {
      val = e.target.value;
    }

    this.setState({ [e.target.name]: val });
  };

  handleOnCreate = (e) => {
    e.preventDefault();

    // validate required input
    if (this.missingRequiredField()) return;

    this.setState({ isLoading: true });

    const data = new FormData();
    data.append("code", this.state.code);
    data.append("firstname", this.state.firstname);
    data.append("lastname", this.state.lastname);
    data.append("nickname", this.state.nickname);
    data.append("mobile", this.state.mobile);
    data.append("position", this.state.position);
    data.append("addrContact", this.state.addrContact);
    data.append("addrWork", this.state.addrWork);

    // for create user login
    data.append("name", this.state.nickname);
    data.append("email", this.state.email);
    data.append("password", this.state.password);
    data.append("passwordConfirm", this.state.passwordConfirm);
    data.append("role", this.state.role);

    axios
      .post("/staff", data)
      .then((success) => {
        this.setState({
          id: success.data.item.id,
          status: 0,
          verify: false,
        });

        toastTimerSuccess.fire({
          title: "Staff has been created",
        });
        this.props.history.push("/staff/" + this.state.id);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleOnUpdate = (e) => {
    e.preventDefault();

    // validate required input
    if (this.state.id && this.missingRequiredField()) return;

    this.setState({ isLoading: true });

    const data = new FormData();
    data.append("code", this.state.code);
    data.append("firstname", this.state.firstname);
    data.append("lastname", this.state.lastname);
    data.append("nickname", this.state.nickname);
    data.append("mobile", this.state.mobile);
    data.append(
      "position",
      this.state.position === "" ? "-" : this.state.position
    );
    data.append(
      "addrContact",
      this.state.addrContact === "" ? "-" : this.state.addrContact
    );
    data.append(
      "addrWork",
      this.state.addrWork === "" ? "-" : this.state.addrWork
    );

    data.append("email", this.state.email);
    data.append("role", this.state.role);
    data.append("status", this.state.status);

    axios
      .post("/staff/" + this.state.id, data)
      .then((success) => {
        toastTimerSuccess.fire({
          title: "Staff's details has been updated",
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  missingRequiredField = (e) => {
    if (!this.state.email)
      return toastPopupError.fire({ title: "Email is required!" });
    if (!this.state.id && !this.state.password)
      return toastPopupError.fire({ title: "Password is required!" });
    if (!this.state.id && this.state.password !== this.state.passwordConfirm)
      return toastPopupError.fire({ title: "Password mismatch!" });
    if (!this.state.role)
      return toastPopupError.fire({ title: "Role is required!" });
    if (!this.state.code)
      return toastPopupError.fire({ title: "Code is required!" });
    if (!this.state.firstname)
      return toastPopupError.fire({ title: "First Name is required!" });
    if (!this.state.lastname)
      return toastPopupError.fire({ title: "Last Name is required!" });
    if (!this.state.nickname)
      return toastPopupError.fire({ title: "Nick Name is required!" });
    if (!this.state.mobile)
      return toastPopupError.fire({ title: "Mobile is required!" });

    return false;
  };

  render() {
    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader
            title="Staff "
            subtitle={this.state.id}
            prevDir="Staff"
            prevUrl="/staff"
            activeDir="Detail"
            button="hidden"
          />

          <section className="content">
            <Container fluid>
              <Row>
                <Col md={3}>
                  <Card className="card-primary card-outline">
                    <Card.Body className="box-profile">
                      <div className="text-center">
                        <Image
                          className="profile-user-img"
                          src={this.state.avatar}
                          roundedCircle
                        />
                      </div>
                      <h3 className="profile-username text-center">
                        {this.state.firstname}
                        {this.state.nickname
                          ? " (" + this.state.nickname + ") "
                          : ""}
                      </h3>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={9}>
                  <Card>
                    <Card.Header>
                      <Card.Title>User Login</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Email *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              type="email"
                              name="email"
                              placeholder="info@example.com"
                              value={this.state.email}
                              onChange={this.handleOnChange}
                              disabled={!this.state.verify}
                            />
                            <font
                              style={{
                                color: "red",
                                display: this.state.verify ? "none" : "",
                              }}
                            >
                              <small>
                                {
                                  "Please verify an email to activate the user login"
                                }
                              </small>
                            </font>
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          style={{
                            display: this.state.id ? "none" : "",
                          }}
                        >
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Initial Password *
                            </Form.Label>
                          </Col>
                          <Col sm={5}>
                            <Form.Control
                              type="password"
                              name="password"
                              placeholder="Password"
                              value={this.state.password}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                          <Col sm={5}>
                            <Form.Control
                              type="password"
                              name="passwordConfirm"
                              placeholder="Re-enter Password"
                              value={this.state.passwordConfirm}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Role *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Select
                              name="role"
                              className="basic-select"
                              classNamePrefix="select"
                              styles={{
                                // Fixes the overlapping problem of the component
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                              }}
                              isSearchable={true}
                              isDisabled={!this.state.verify}
                              options={this.state.roleList}
                              value={
                                this.state.roleList[
                                  this.state.roleList.findIndex(
                                    (obj) => obj.value === this.state.role
                                  )
                                ]
                              }
                              onChange={(e) => this.setState({ role: e.value })}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          style={{
                            display: this.state.id ? "" : "none",
                          }}
                        >
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Status *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Select
                              name="status"
                              className="basic-select"
                              classNamePrefix="select"
                              styles={{
                                // Fixes the overlapping problem of the component
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                              }}
                              isSearchable={true}
                              isDisabled={!this.state.verify}
                              options={this.state.statusList}
                              value={
                                this.state.statusList[
                                  this.state.statusList.findIndex(
                                    (obj) => obj.value === this.state.status
                                  )
                                ]
                              }
                              onChange={(e) =>
                                this.setState({ status: e.value })
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Form>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Card.Title>Information</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Code *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="code"
                              value={this.state.code}
                              onChange={this.handleOnChange}
                              autoComplete="false"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              First Name *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="firstname"
                              value={this.state.firstname}
                              onChange={this.handleOnChange}
                              autoComplete="false"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Last Name *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="lastname"
                              value={this.state.lastname}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Nick Name *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="nickname"
                              value={this.state.nickname}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Mobile *
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="mobile"
                              value={this.state.mobile}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Position
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              name="position"
                              value={this.state.position}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Address (Contact)
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="addrContact"
                              value={this.state.addrContact}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col sm={2}>
                            <Form.Label className="col-form-label">
                              Address (Work)
                            </Form.Label>
                          </Col>
                          <Col sm={10}>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="addrWork"
                              value={this.state.addrWork}
                              onChange={this.handleOnChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="offset-sm-2">
                          <ButtonLoading
                            name="Submit"
                            className="btn btn-success"
                            hidden={this.state.id ? true : false}
                            disabled={this.state.isLoading}
                            onClick={this.handleOnCreate}
                          />
                          <ButtonLoading
                            name="Save"
                            className="btn btn-success"
                            hidden={this.state.id ? false : true}
                            disabled={this.state.isLoading}
                            onClick={this.handleOnUpdate}
                          />
                        </Form.Group>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
        <PageLoader marginTop="30%" loading={this.state.isLoading} />
      </div>
    );
  }
}

import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { Container, Image, Nav } from "react-bootstrap";

import params from "../utils/params";
import localStorage from "../utils/localStorage";

export default class Menu extends Component {
  constructor() {
    super();

    let user = JSON.parse(localStorage.getUser());
    if (!user) {
      this.state = { invalid: true };
      return;
    }

    this.state = {
      role: user.role,
    };
  }

  render() {
    if (this.state.invalid) {
      return <Redirect to="/logout" />;
    }

    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <NavLink
          exact
          to="/dashboard"
          activeClassName="active"
          className="brand-link"
        >
          <Image
            src={"/dist/img/D2PLogo.jpg"}
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light">
            <b>CABINET</b> SOLUTIONS
          </span>
        </NavLink>

        <Container className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column nav-child-indent"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <Nav.Item
                hidden={
                  this.state.role === params.ROLE.APPS
                }
              >
                <NavLink
                  to="/dashboard"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="nav-icon fas fa-tachometer-alt mr-2" />
                  <p>Dashboard</p>
                </NavLink>
              </Nav.Item>

              {/* --- WAREHOUSE --- */}
              <Nav.Item
                className="nav-header"
                hidden={
                  this.state.role === params.ROLE.APPS ||
                  this.state.role === params.ROLE.IT_SUPPORT ||
                  this.state.role === params.ROLE.PRODUCTION ||
                  this.state.role === params.ROLE.PRODUCTION_MANAGER
                }
              >
                WAREHOUSE
              </Nav.Item>
              <Nav.Item>
                <NavLink
                  to="/item"
                  activeClassName="active"
                  className="nav-link"
                  hidden={
                    this.state.role === params.ROLE.APPS ||
                    this.state.role === params.ROLE.IT_SUPPORT ||
                    this.state.role === params.ROLE.PRODUCTION ||
                    this.state.role === params.ROLE.PRODUCTION_MANAGER
                  }
                >
                  <i className="nav-icon fas fa-warehouse mr-2" />
                  <p>Item</p>
                </NavLink>
              </Nav.Item>

              {/* --- PRODUCTION --- */}
              <Nav.Item
                className="nav-header"
                hidden={
                  this.state.role === params.ROLE.APPS ||
                  this.state.role === params.ROLE.IT_SUPPORT ||
                  this.state.role === params.ROLE.ACCOUNTING
                }
              >
                PRODUCTION
              </Nav.Item>
              <Nav.Item
                hidden={
                  this.state.role === params.ROLE.APPS ||
                  this.state.role === params.ROLE.IT_SUPPORT ||
                  this.state.role === params.ROLE.ACCOUNTING ||
                  this.state.role === params.ROLE.PRODUCTION ||
                  this.state.role === params.ROLE.PURCHASE ||
                  this.state.role === params.ROLE.PURCHASE_MANAGER
                }
              >
                <NavLink
                  to="/job"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="nav-icon fas fa-chalkboard-teacher mr-2" />
                  <p>Job</p>
                </NavLink>
              </Nav.Item>
              <Nav.Item
                hidden={
                  this.state.role === params.ROLE.APPS ||
                  this.state.role === params.ROLE.IT_SUPPORT ||
                  this.state.role === params.ROLE.ACCOUNTING
                }
              >
                <NavLink
                  to="/report"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="nav-icon fas fa-chart-bar mr-2" />
                  <p>Report</p>
                </NavLink>
              </Nav.Item>
              <Nav.Item
                hidden={
                  this.state.role === params.ROLE.APPS ||
                  this.state.role === params.ROLE.IT_SUPPORT ||
                  this.state.role === params.ROLE.ACCOUNTING
                }
              >
                <NavLink
                  to="/status"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="nav-icon fas fa-calendar-alt mr-2" />
                  <p>Status</p>
                </NavLink>
              </Nav.Item>

              {/* --- DOCUMENT --- */}
              <Nav.Item
                className="nav-header"
                hidden={
                  this.state.role === params.ROLE.APPS ||
                  this.state.role === params.ROLE.IT_SUPPORT ||
                  this.state.role === params.ROLE.DRAFT ||
                  this.state.role === params.ROLE.DRAFT_MANAGER ||
                  this.state.role === params.ROLE.PRODUCTION ||
                  this.state.role === params.ROLE.PRODUCTION_MANAGER
                }
              >
                DOCUMENT
              </Nav.Item>
              <Nav.Item
                hidden={
                  this.state.role === params.ROLE.APPS ||
                  this.state.role === params.ROLE.IT_SUPPORT ||
                  this.state.role === params.ROLE.DRAFT ||
                  this.state.role === params.ROLE.DRAFT_MANAGER ||
                  this.state.role === params.ROLE.PRODUCTION ||
                  this.state.role === params.ROLE.PRODUCTION_MANAGER
                }
              >
                <NavLink to="/po" activeClassName="active" className="nav-link">
                  <i className="nav-icon fas fa-truck mr-2" />
                  <p>PO - ซื้อ</p>
                </NavLink>
              </Nav.Item>
              <Nav.Item
                hidden={
                  this.state.role === params.ROLE.APPS ||
                  this.state.role === params.ROLE.IT_SUPPORT ||
                  this.state.role === params.ROLE.DRAFT ||
                  this.state.role === params.ROLE.DRAFT_MANAGER ||
                  this.state.role === params.ROLE.PRODUCTION ||
                  this.state.role === params.ROLE.PRODUCTION_MANAGER
                }
              >
                <NavLink to="/ro" activeClassName="active" className="nav-link">
                  <i className="nav-icon fas fa-pallet mr-2" />
                  <p>RO - รับ</p>
                </NavLink>
              </Nav.Item>
              <Nav.Item
                hidden={
                  this.state.role === params.ROLE.APPS ||
                  this.state.role === params.ROLE.IT_SUPPORT ||
                  this.state.role === params.ROLE.DRAFT ||
                  this.state.role === params.ROLE.DRAFT_MANAGER ||
                  this.state.role === params.ROLE.PRODUCTION ||
                  this.state.role === params.ROLE.PRODUCTION_MANAGER
                }
              >
                <NavLink to="/re" activeClassName="active" className="nav-link">
                  <i className="nav-icon fas fa-shopping-cart mr-2" />
                  <p>RE - เบิก</p>
                </NavLink>
              </Nav.Item>

              {/* --- SETTING --- */}
              <Nav.Item
                className="nav-header"
                hidden={
                  this.state.role === params.ROLE.APPS ||
                  this.state.role === params.ROLE.PRODUCTION ||
                  this.state.role === params.ROLE.PRODUCTION_MANAGER
                }
              >
                SETTING
              </Nav.Item>
              <Nav.Item
                hidden={
                  this.state.role === params.ROLE.APPS ||
                  this.state.role === params.ROLE.PRODUCTION ||
                  this.state.role === params.ROLE.PRODUCTION_MANAGER ||
                  this.state.role === params.ROLE.ACCOUNTING ||
                  this.state.role === params.ROLE.MANAGER ||
                  this.state.role === params.ROLE.DRAFT ||
                  this.state.role === params.ROLE.DRAFT_MANAGER ||
                  this.state.role === params.ROLE.PRODUCTION ||
                  this.state.role === params.ROLE.PRODUCTION_MANAGER ||
                  this.state.role === params.ROLE.PURCHASE ||
                  this.state.role === params.ROLE.PURCHASE_MANAGER
                }
              >
                <NavLink
                  to="/staff"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="nav-icon fas fa-sitemap mr-2" />
                  <p>Staff</p>
                </NavLink>
              </Nav.Item>
              <Nav.Item
                hidden={
                  this.state.role === params.ROLE.APPS ||
                  this.state.role === params.ROLE.IT_SUPPORT ||
                  this.state.role === params.ROLE.PRODUCTION ||
                  this.state.role === params.ROLE.PRODUCTION_MANAGER
                }
              >
                <NavLink
                  to="/customer"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="nav-icon fas fa-users mr-2" />
                  <p>Customer</p>
                </NavLink>
              </Nav.Item>
              <Nav.Item
                hidden={
                  this.state.role === params.ROLE.APPS ||
                  this.state.role === params.ROLE.IT_SUPPORT ||
                  this.state.role === params.ROLE.PRODUCTION ||
                  this.state.role === params.ROLE.PRODUCTION_MANAGER
                }
              >
                <NavLink
                  to="/project"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="nav-icon fas fa-home mr-2" />
                  <p>Project</p>
                </NavLink>
              </Nav.Item>
              <Nav.Item
                hidden={
                  this.state.role === params.ROLE.APPS ||
                  this.state.role === params.ROLE.IT_SUPPORT ||
                  this.state.role === params.ROLE.PRODUCTION ||
                  this.state.role === params.ROLE.PRODUCTION_MANAGER ||
                  this.state.role === params.ROLE.DRAFT ||
                  this.state.role === params.ROLE.DRAFT_MANAGER
                }
              >
                <NavLink
                  to="/supplier"
                  activeClassName="active"
                  className="nav-link"
                >
                  <i className="nav-icon fas fa-building mr-2" />
                  <p>Supplier</p>
                </NavLink>
              </Nav.Item>
            </ul>
          </nav>
        </Container>
      </aside>
    );
  }
}

import React, { Component } from "react";
import { Container, Row, Col, Card, Table, Image } from "react-bootstrap";
import axios from "axios";

import PageLoader from "../components/PageLoader";

export default class PurchaseOrderPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      supplier: "",
      staff: "",
      poCode: "",
      remark: "",
      totalAmount: "",
      discountAmount: "",
      discountSpecial: "",
      vatPercent: "",
      vatAmount: "",
      netAmountBeforeVat: "",
      netAmountAfterVat: "",
      createdAt: "",

      suppName: "",
      suppTaxId: "",
      suppAddress: "",
      suppTel: "",
      suppEmail: "",
      suppContactName: "",
      suppContactTel: "",

      poItems: [],
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    // Promise all
    await axios.get("/po/" + this.state.id).then((res1) => {
      this.setState({
        id: res1.data.id,
        supplier: res1.data.supplier_id,
        staff:
          res1.data.staff.firstname + " (" + res1.data.staff.nickname + ")",
        poCode: res1.data.code,
        remark: res1.data.remark ? res1.data.remark : "",
        totalAmount: res1.data.total_amount,
        discountAmount: res1.data.discount_amount,
        discountSpecial: res1.data.discount_special,
        vatPercent: res1.data.vat_percent,
        vatAmount: res1.data.vat_amount,
        netAmountBeforeVat: res1.data.net_amount_before_vat,
        netAmountAfterVat: res1.data.net_amount_after_vat,
        createdAt: res1.data.created_at,
      });
    });

    let fetchData1 = axios
      .get("/supplier/" + this.state.supplier)
      .then((res1) => {
        this.setState({
          suppName: res1.data.name,
          suppTaxId: res1.data.tax_id,
          suppAddress: res1.data.addr_main,
          suppTel: res1.data.office_tel,
          suppEmail: res1.data.office_email,
          suppContactName: res1.data.contact_name,
          suppContactTel: res1.data.contact_tel,
        });
      });

    let fetchData2 = axios.get("/poitem/" + this.state.id).then((res2) => {
      let list = [];

      if (res2.data.length) {
        res2.data.map((item) => {
          return list.push({
            id: item.id,
            itemId: item.item_id,
            itemName: item.s_name,
            qty: item.qty,
            unit: item.s_unit,
            price: item.price,
            totalAmount: item.total_amount,
            discountPercent: item.discount_percent,
            discountAmount: item.discount_amount,
            netAmount: item.net_amount,
            remark: item.remark,
          });
        });

        this.setState({
          poItems: list,
        });
      }
    });

    Promise.all([fetchData1, fetchData2]).then((success) => {
      this.setState({ isLoading: false });
      window.addEventListener("load", window.print());
    });
  };

  render() {
    return (
      <section className="invoice">
        <Container fluid>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <br />
                  <br />
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <h4 className="page-header">
                    <strong>บริษัท บ้านสุข ดีไซน์ แอนด์ ลิฟวิ่ง จำกัด</strong>
                  </h4>
                  <p className="page-header">
                    เลขที่ 21/4 หมู่ที่ 3 ต.คอกกระบือ อ.เมืองสมุทรสาคร
                    จ.สมุทรสาคร 74000
                    <br />
                    โทร. 096-294-6365&nbsp;&nbsp;&nbsp;&nbsp;เลขนิติบุคคล:
                    0105559007748
                    <br />
                    www.baansook.com&nbsp;&nbsp;&nbsp;&nbsp;email:
                    info@baansook.com
                  </p>
                </Col>
                <Col
                  md={4}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Image
                    src={"/dist/img/BaansookLogo.png"}
                    className="img-responsive"
                    style={{ width: "35%" }}
                  />
                  <br />
                  <br />

                  <h4 className="page-header text-center">
                    <strong>ใบสั่งซื้อ / Purchase Order</strong>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {/* info row */}
                  <PageLoader loading={this.state.isLoading} marginTop="25%" />
                  <Table responsive size="sm">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            border: "solid 1px",
                          }}
                        >
                          <Table responsive borderless size="sm">
                            <tbody>
                              <tr>
                                <td width={"15%"}>
                                  <strong>
                                    ชื่อบริษัท:
                                    <br />
                                    เลขนิติบุคคล:
                                    <br />
                                    ติดต่อ:
                                    <br />
                                    ที่อยู่:
                                  </strong>
                                </td>
                                <td width={"50%"}>
                                  {this.state.suppName}
                                  <br />
                                  {this.state.suppTaxId
                                    ? this.state.suppTaxId
                                    : "-"}
                                  <br />
                                  <i className="fas fa-mobile-alt mr-2" />
                                  {this.state.suppTel
                                    ? this.state.suppTel
                                    : "-"}
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <i className="fas fa-envelope mr-2" />
                                  {this.state.suppEmail
                                    ? this.state.suppEmail
                                    : "-"}
                                  <br />
                                  {this.state.suppAddress}
                                </td>
                                <td width={"15%"}>
                                  <strong>
                                    เลขที่:
                                    <br />
                                    วันที่:
                                    <br />
                                    ผู้สั่งซื้อ:
                                  </strong>
                                </td>
                                <td width={"20%"}>
                                  {this.state.poCode}
                                  <br />
                                  {new Date(
                                    this.state.createdAt
                                  ).toLocaleDateString("en-GB")}
                                  <br />
                                  {this.state.staff}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>ชื่อผู้ติดต่อ:</strong>
                                </td>
                                <td>
                                  {this.state.suppContactName} (
                                  {this.state.suppContactTel})
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Row>
                    <Col md={12}>
                      <Table responsive size="sm">
                        <thead>
                          <tr>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                              }}
                            >
                              No.
                            </th>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                              }}
                            >
                              Item ID
                            </th>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                              }}
                            >
                              Item Name
                            </th>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                              }}
                            >
                              Qty
                            </th>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                              }}
                            >
                              Unit
                            </th>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                              }}
                            >
                              Price
                            </th>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                              }}
                            >
                              Disc.
                            </th>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                              }}
                            >
                              Total
                            </th>
                            <th
                              style={{
                                border: "solid 1px",
                                textAlign: "center",
                              }}
                              width={"10%"}
                            >
                              Remark
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.poItems.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                  align="center"
                                >
                                  {index + 1}
                                </td>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                >
                                  {item.itemId}
                                </td>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                >
                                  {item.itemName}
                                </td>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                  align="right"
                                >
                                  {item.qty.toLocaleString("en-US", {
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                >
                                  {item.unit}
                                </td>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                  align="right"
                                >
                                  {item.price.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                  align="right"
                                >
                                  {item.discountPercent > 0
                                    ? item.discountPercent.toLocaleString(
                                        "en-US",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      ) + "%"
                                    : "-"}
                                </td>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                  align="right"
                                >
                                  {item.netAmount.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                                <td
                                  style={{
                                    borderRight: "solid 1px",
                                    borderLeft: "solid 1px",
                                    borderTop: "0px",
                                    borderButton: "0px",
                                  }}
                                ></td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td
                              style={{ border: "solid 1px" }}
                              rowSpan={4}
                              colSpan={5}
                            >
                              <strong>{"หมายเหตุ: "}</strong>
                              {this.state.remark ? this.state.remark : "-"}
                            </td>
                            <td style={{ border: "solid 1px" }} colSpan={3}>
                              <strong>ส่วนลดพิเศษ</strong>
                            </td>
                            <td style={{ border: "solid 1px" }} align="right">
                              {this.state.discountSpecial > 0 ? "-":""}{this.state.discountSpecial.toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </td>
                          </tr>
                          <tr>
                          <td style={{ border: "solid 1px" }} colSpan={3}>
                              <strong>ยอดหลังหักส่วนลด</strong>
                            </td>
                            <td style={{ border: "solid 1px" }} align="right">
                              {this.state.netAmountBeforeVat.toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: "solid 1px" }} colSpan={3}>
                              <strong>ภาษีมูลค่าเพิ่ม 7%</strong>
                            </td>
                            <td style={{ border: "solid 1px" }} align="right">
                              {this.state.vatAmount.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: "solid 1px" }} colSpan={3}>
                              <strong>จำนวนเงินทั้งสิ้น</strong>
                            </td>
                            <td style={{ border: "solid 1px" }} align="right">
                              <strong>
                                {this.state.netAmountAfterVat.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <br />
                  <Row className="invoice-info">
                    <Col sm={4} className="invoice-col text-center">
                      {"_______________________"}
                      <br />
                      ผู้ขออนุมัติ
                      <br />
                      วันที่:
                      {" ____ / ____ / ________"}
                    </Col>
                    <Col sm={4} className="invoice-col text-center">
                      {"_______________________"}
                      <br />
                      ผู้ตรวจสอบ
                      <br />
                      วันที่:
                      {" ____ / ____ / ________"}
                    </Col>
                    <Col sm={4} className="invoice-col text-center">
                      {"_______________________"}
                      <br />
                      ผู้อนุมัติ
                      <br />
                      วันที่:
                      {" ____ / ____ / ________"}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    );
  }
}

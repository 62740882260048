import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

import LoginBox from "../components/LoginBox";
import ButtonLoading from "../components/ButtonLoading";
import { toastTimerSuccess, toastPopupError } from "../utils/sweetAlert";
import ForceLogin from "../services/ForceLogin";

export default class PasswordReset extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      passwordConfirm: "",
      token: "",
      isTokenValid: true,
      isLoading: false,
    };
  }

  componentDidMount() {
    let token = this.props.match.params.token;

    axios
      .get(`/password/find/${token}`)
      .then((success) => {
        this.setState({
          email: success.data.email,
          token: success.data.token,
          isTokenValid: true,
        });
      })
      .catch((error) => {
        this.setState({ isTokenValid: false });
      });
  }

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    // validate
    if (this.state.password === "") {
      return toastPopupError.fire({ text: "New password is required." });
    }

    if (this.state.password !== this.state.passwordConfirm) {
      return toastPopupError.fire({ text: "Passwords mismatch." });
    }

    this.setState({ isLoading: true });

    // send request
    const { email, password, passwordConfirm, token } = this.state;
    axios
      .post("/password/reset", { email, password, passwordConfirm, token })
      .then((success) => {
        toastTimerSuccess.fire({ title: "Password has been reset." });

        this.props.history.push("/");
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    if (this.state.isTokenValid) {
      return (
        <LoginBox
          isLoading={this.state.isLoading}
          description="You are only one step away, recover your password now."
        >
          <form onSubmit={this.handleOnSubmit}>
            <div className="input-group mb-3">
              <input
                name="password"
                type="password"
                className="form-control"
                placeholder="New password"
                value={this.state.password}
                onChange={this.handleOnChange}
                autoFocus
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock" />
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                name="passwordConfirm"
                type="password"
                className="form-control"
                placeholder="Confirm new password"
                value={this.state.passwordConfirm}
                onChange={this.handleOnChange}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <ButtonLoading
                  name="Change Password"
                  className="btn btn-primary btn-block"
                  disabled={this.state.isLoading}
                />
              </div>
              {/* /.col */}
            </div>
          </form>
          <p className="mt-3 mb-1">
            <NavLink exact to="/" className="mb-3">
              Back to Login
            </NavLink>
          </p>
        </LoginBox>
      );
    }

    if (!this.state.isTokenValid) {
      return <ForceLogin message="Invalid Token." />;
    }

    return <div></div>;
  }
}

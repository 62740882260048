import React, { Component } from "react";
import {} from "react-router-dom";
import { Jumbotron } from "react-bootstrap";
import axios from "axios";

import Header from "../components/Header";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import PageLoader from "../components/PageLoader";

export default class Dashboard extends Component {
  constructor() {
    super();

    this.state = {
      userName: "",
      isLoading: true,
    };
  }

  componentDidMount() {
    axios
      .get("/auth/user")
      .then((success) => {
        this.setState({
          userName: "K." + success.data.name,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader title="Dashboard" breadcrumb="hidden" button="hidden" />

          <section className="content">
            <div className="container-fluid">
              <Jumbotron>
                <PageLoader marginTop="45%" loading={this.state.isLoading} />
                <h1>Hello, {this.state.userName}</h1>
                <h4>Welcome back!</h4>
              </Jumbotron>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import { NavLink, Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Form,
  Button,
  ButtonGroup,
} from "react-bootstrap";

import Header from "../components/Header";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import PageLoader from "../components/PageLoader";
import ButtonLoading from "../components/ButtonLoading";
import RoomCodeTable from "../components/RoomCodeTable";

import params from "../utils/params";
import localStorage from "../utils/localStorage";
import { toastPopupError, toastHtmlSubproject } from "../utils/sweetAlert";

export default class ProjectDetail extends Component {
  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getUser());
    if (!user) {
      this.state = { invalid: true };
      return;
    }

    this.state = {
      role: user.role,
      id: props.match.params.id,

      user: {
        id: "",
        avatar: "/dist/img/avatarDefault.png",
        firstname: "",
        lastname: "",
        nickname: "",
        position: "",
        mobile: "",
        email: "",
        addr_contact: "",
      },

      projectId: "",
      description: "",
      customerId: "",

      customerList: [],
      subprojectList: [],

      isLoading: false,
    };
  }

  componentDidMount() {
    // dropdown options
    axios.get("/customer").then((success) => {
      let list = [];

      // process if has an option
      if (success.data.length) {
        success.data.map((cust) => {
          return list.push({
            value: cust.id,
            label:
              cust.firstname + " " + cust.lastname + " (" + cust.nickname + ")",
          });
        });

        this.setState({
          customerList: list,
        });
      }
    });

    // current info
    if (this.state.id) {
      this.setState({ isLoading: true });

      let fetchData1 = axios.get("/project/" + this.state.id).then((res1) => {
        if (res1.data.customer) {
          this.setState({
            user: {
              ...this.state.user,
              id: res1.data.customer.id,
              firstname: res1.data.customer.firstname,
              lastname: res1.data.customer.lastname,
              nickname: res1.data.customer.nickname,
              position: res1.data.customer.position,
              mobile: res1.data.customer.mobile,
              email: res1.data.customer.email,
              addr_contact: res1.data.customer.addr_contact,
            },
          });
        }

        this.setState({
          projectId: res1.data.project.id,
          description: res1.data.project.description,
          customerId: res1.data.customer ? res1.data.customer.id : "",
        });
      });

      let fetchData2 = axios
        .get("/subproject/" + this.state.id)
        .then((res2) => {
          this.setState({
            subprojectList: res2.data,
          });
        });

      Promise.all([fetchData1, fetchData2]).then((success) => {
        this.setState({ isLoading: false });
      });
    }
  }

  handleOnChange = (e) => {
    let val = "";

    if (e.target.type === "checkbox") {
      val = e.target.checked;
    } else {
      val = e.target.value;
    }

    this.setState({ [e.target.name]: val });
  };

  handleOnDropdown = (e) => {
    this.setState({ customerId: e.value });

    axios.get("/customer/" + e.value).then((success) => {
      this.setState({
        user: {
          ...this.state.user,
          id: success.data.id,
          firstname: success.data.firstname,
          lastname: success.data.lastname,
          nickname: success.data.nickname,
          position: success.data.position,
          mobile: success.data.mobile,
          email: success.data.email,
          addr_contact: success.data.addr_contact,
        },
      });
    });
  };

  handleOnUpdate = (e) => {
    e.preventDefault();

    // validate required input
    if (this.state.id && this.missingRequiredField()) return;

    this.setState({ isLoading: true });

    const data = new FormData();
    data.append(
      "description",
      this.state.description === "" ? "-" : this.state.description
    );
    data.append("customerId", this.state.customerId);
    axios.post("/project/" + this.state.id, data).finally(() => {
      this.setState({ isLoading: false });
    });
  };

  handleOnCreate = (e) => {
    e.preventDefault();

    // validate required input
    if (this.missingRequiredField()) return;

    this.setState({ isLoading: true });

    const data = new FormData();
    data.append(
      "projectId",
      this.state.projectId.toUpperCase().replace(/\s+/g, "")
    );
    data.append(
      "description",
      this.state.description === "" ? "-" : this.state.description
    );
    data.append("customerId", this.state.customerId);

    axios
      .post("/project", data)
      .then((success) => {
        this.setState({
          id: success.data.item.id,
          projectId: success.data.item.id,
        });

        this.props.history.push("/project/" + this.state.id);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleOnCreateSubproject = (e) => {
    toastHtmlSubproject.fire().then((result) => {
      if (result.isConfirmed) {
        this.setState({ isLoading: true });

        const data = new FormData();
        data.append("projectId", this.state.id);
        data.append(
          "subproject",
          result.value.check
            ? "-"
            : result.value.name.toUpperCase().replace(/\s+/g, "")
        );

        axios
          .post("/subproject", data)
          .then((success) => {
            this.setState({
              subprojectList: success.data.items,
            });
          })
          .finally(() => {
            this.setState({
              isLoading: false,
            });
          });
      }
    });
  };

  handleOnUpdateSubproject = (e) => {
    e.preventDefault();
    let oldSubproject = e.target.id;

    toastHtmlSubproject
      .fire({
        title: "Update name from '" + oldSubproject + "'",
        confirmButtonText: "Update",
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.setState({ isLoading: true });

          const data = new FormData();
          data.append("projectId", this.state.id);
          data.append(
            "subproject",
            result.value.check
              ? "-"
              : result.value.name.toUpperCase().replace(/\s+/g, "")
          );
          data.append(
            "oldSubproject",
            oldSubproject ? oldSubproject.toUpperCase() : "-"
          );

          axios
            .post("/subproject/update", data)
            .then((success) => {
              this.setState({
                subprojectList: success.data.items,
              });
            })
            .finally(() => {
              this.setState({
                isLoading: false,
              });
            });
        }
      });
  };

  missingRequiredField = (e) => {
    if (!this.state.projectId)
      return toastPopupError.fire({ title: "Project Code is required!" });
    if (!this.state.customerId)
      return toastPopupError.fire({ title: "Customer is required!" });

    return false;
  };

  render() {
    if (this.state.invalid) {
      return <Redirect to="/logout" />;
    }

    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader
            title="Project "
            subtitle={this.state.id}
            prevDir="Project"
            prevUrl="/project"
            activeDir="Detail"
            button="hidden"
          />

          <section className="content">
            <Container fluid>
              <Row>
                <Col md={3}>
                  <Card className="card-primary card-outline">
                    <Card.Body className="box-profile">
                      <div className="text-center mr-2">
                        <Image
                          className="profile-user-img"
                          src={this.state.user.avatar}
                          roundedCircle
                        />
                      </div>
                      <h3 className="profile-username text-center">
                        {this.state.user.firstname}
                        {this.state.user.nickname
                          ? " (" + this.state.user.nickname + ") "
                          : ""}
                      </h3>
                      <hr />
                      {/*------ START hide for now 2021/05/08 --------*/}
                      <div style={{ display: "none" }}>
                        <strong>
                          <i className="fas fa-award mr-2" />
                          Position
                        </strong>
                        <p className="text-muted">
                          {this.state.user.position
                            ? this.state.user.position
                            : "-"}
                        </p>
                        <strong>
                          <i className="fas fa-mobile-alt mr-2" />
                          Mobile No.
                        </strong>
                        <p className="text-muted">
                          {this.state.user.mobile
                            ? this.state.user.mobile
                            : "-"}
                        </p>
                        <strong>
                          <i className="fas fa-envelope mr-2" />
                          Email
                        </strong>
                        <p className="text-muted">
                          {this.state.user.email ? this.state.user.email : "-"}
                        </p>
                        <strong>
                          <i className="fas fa-home mr-2" />
                          Address
                        </strong>
                        <p className="text-muted">
                          {this.state.user.addr_contact
                            ? this.state.user.addr_contact
                            : "-"}
                        </p>
                      </div>
                      {/*------ END hide for now 2021/05/08 --------*/}

                      <NavLink
                        exact
                        to={"/customer/" + this.state.user.id}
                        activeClassName=""
                      >
                        <Button variant="primary" className="btn-block">
                          Customer Profile
                        </Button>
                      </NavLink>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={9}>
                  <Row>
                    <Col md={12}>
                      <Card>
                        <Card.Header>
                          <Card.Title>Detail</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Form>
                            <Form.Group as={Row}>
                              <Col sm={3}>
                                <Form.Label className="col-form-label">
                                  Project Code *
                                </Form.Label>
                              </Col>
                              <Col sm={9}>
                                <Form.Control
                                  name="projectId"
                                  value={this.state.projectId}
                                  onChange={this.handleOnChange}
                                  disabled={this.state.id ? "disabled" : ""}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col sm={3}>
                                <Form.Label className="col-form-label">
                                  Description
                                </Form.Label>
                              </Col>
                              <Col sm={9}>
                                <Form.Control
                                  name="description"
                                  value={this.state.description}
                                  onChange={this.handleOnChange}
                                  autoComplete="false"
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Col sm={3}>
                                <Form.Label className="col-form-label">
                                  Customer *
                                </Form.Label>
                              </Col>
                              <Col sm={9}>
                                <Select
                                  name="customerId"
                                  className="basic-select"
                                  classNamePrefix="select"
                                  styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  isSearchable={true}
                                  options={this.state.customerList}
                                  value={
                                    this.state.customerList[
                                      this.state.customerList.findIndex(
                                        (obj) =>
                                          obj.value === this.state.customerId
                                      )
                                    ]
                                  }
                                  onChange={this.handleOnDropdown}
                                />
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                              <Col sm={3}></Col>
                              <Col sm={9}>
                                <ButtonLoading
                                  name="Submit"
                                  className="btn btn-success float-right"
                                  disabled={
                                    this.state.isLoading ||
                                    this.state.role ===
                                      params.ROLE.ACCOUNTING ||
                                    this.state.role === params.ROLE.DRAFT ||
                                    this.state.role === params.ROLE.PURCHASE
                                  }
                                  hidden={
                                    this.state.id ||
                                    this.state.role ===
                                      params.ROLE.ACCOUNTING ||
                                    this.state.role === params.ROLE.DRAFT ||
                                    this.state.role === params.ROLE.PURCHASE
                                  }
                                  onClick={this.handleOnCreate}
                                />
                                <ButtonLoading
                                  name="Save"
                                  className="btn btn-success float-right"
                                  disabled={
                                    this.state.isLoading ||
                                    this.state.role ===
                                      params.ROLE.ACCOUNTING ||
                                    this.state.role === params.ROLE.DRAFT ||
                                    this.state.role === params.ROLE.PURCHASE
                                  }
                                  hidden={
                                    !this.state.id ||
                                    this.state.role ===
                                      params.ROLE.ACCOUNTING ||
                                    this.state.role === params.ROLE.DRAFT ||
                                    this.state.role === params.ROLE.PURCHASE
                                  }
                                  onClick={this.handleOnUpdate}
                                />
                              </Col>
                            </Form.Group>
                          </Form>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr hidden={!this.state.id} />
              <Row>
                <Col md={12}>
                  <Form.Group className="text-center">
                    <Button
                      variant="primary"
                      onClick={this.handleOnCreateSubproject}
                      hidden={
                        !this.state.id ||
                        this.state.role === params.ROLE.ACCOUNTING ||
                        this.state.role === params.ROLE.DRAFT ||
                        this.state.role === params.ROLE.PURCHASE
                      }
                    >
                      <i className="fas fa-plus mr-2" />
                      Add Sub Project
                    </Button>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  {this.state.subprojectList.map((subproject) => {
                    return (
                      <Card key={subproject.sub_project}>
                        <Card.Header>
                          <Card.Title>
                            <strong>Sub project: </strong>
                            {subproject.sub_project
                              ? subproject.sub_project
                              : "Default"}
                          </Card.Title>
                          <div className="card-tools">
                            <ButtonGroup>
                              <Button
                                variant="tool"
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                hidden={
                                  this.state.role === params.ROLE.ACCOUNTING ||
                                  this.state.role === params.ROLE.DRAFT ||
                                  this.state.role === params.ROLE.PURCHASE
                                }
                              >
                                <i className="fas fa-cogs" />
                              </Button>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                role="menu"
                              >
                                <a
                                  href="!#"
                                  id={subproject.sub_project}
                                  className="dropdown-item"
                                  onClick={this.handleOnUpdateSubproject}
                                >
                                  Edit
                                </a>
                                <a
                                  href="!#"
                                  id={subproject.sub_project}
                                  className="dropdown-item"
                                  onClick={this.handleOnDeleteSubproject}
                                  hidden
                                >
                                  Delete
                                </a>
                              </div>
                            </ButtonGroup>

                            <Button variant="tool" data-card-widget="collapse">
                              <i className="fas fa-minus" />
                            </Button>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <RoomCodeTable
                            projectId={this.state.id}
                            subproject={
                              subproject.sub_project
                                ? subproject.sub_project
                                : "-"
                            }
                          />
                        </Card.Body>
                      </Card>
                    );
                  })}
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
        <PageLoader marginTop="30%" loading={this.state.isLoading} />
      </div>
    );
  }
}

import React, { Component } from "react";

import Error404 from "../pages/Error404";
import ForceLogin from "./ForceLogin";

import localStorage from "../utils/localStorage";
import params from "../utils/params";

export default class RequireAuth extends Component {
  render() {
    if (!localStorage.getAccessToken()) {
      return <ForceLogin message="Authentication invalid." />;
    }
    if (!localStorage.getUser()) {
      return <ForceLogin message="Authentication invalid." />;
    }

    // check role
    let role = JSON.parse(localStorage.getUser()).role;
    let module = this.props.location.pathname.split("/")[1];
    let arr = [];

    if (role === params.ROLE.ADMIN) {
      arr = [
        "dashboard",
        "item",
        "barcode",
        "job",
        "report",
        "status",
        "po",
        "ro",
        "re",
        "staff",
        "customer",
        "project",
        "supplier",
      ];
    }
    if (role === params.ROLE.IT_SUPPORT) {
      arr = ["dashboard", "staff"];
    } else if (role === params.ROLE.MANAGER) {
      arr = [
        "dashboard",
        "item",
        "job",
        "report",
        "status",
        "po",
        "ro",
        "re",
        "customer",
        "project",
        "supplier",
      ];
    } else if (role === params.ROLE.ACCOUNTING) {
      arr = [
        "dashboard",
        "item",
        "po",
        "ro",
        "re",
        "customer",
        "project",
        "supplier",
      ];
    } else if (role === params.ROLE.DRAFT) {
      arr = [
        "dashboard",
        "item",
        "job",
        "report",
        "status",
        "customer",
        "project",
      ];
    } else if (role === params.ROLE.DRAFT_MANAGER) {
      arr = [
        "dashboard",
        "item",
        "job",
        "report",
        "status",
        "customer",
        "project",
      ];
    } else if (role === params.ROLE.PRODUCTION) {
      arr = ["dashboard", "report", "status"];
    } else if (role === params.ROLE.PRODUCTION_MANAGER) {
      arr = ["dashboard", "job", "report", "status"];
    } else if (role === params.ROLE.PURCHASE) {
      arr = [
        "dashboard",
        "item",
        "barcode",
        "report",
        "status",
        "po",
        "ro",
        "re",
        "customer",
        "project",
        "supplier",
      ];
    } else if (role === params.ROLE.PURCHASE_MANAGER) {
      arr = [
        "dashboard",
        "item",
        "barcode",
        "report",
        "status",
        "po",
        "ro",
        "re",
        "customer",
        "project",
        "supplier",
      ];
    }

    if (arr.indexOf(module) < 0) {
      return <Error404 />;
    }

    return this.props.children;
  }
}

import React, { Component } from "react";
import styled from "styled-components";
import HashLoader from "react-spinners/HashLoader";

export default class PageLoader extends Component {
  render() {
    // style
    const Container = styled.div`
      position: absolute;
      z-index: 1000;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #f8f8f8ad;
    `;

    const Loader = styled.div`
      left: 50%;
      top: ${this.props.marginTop ? this.props.marginTop : "100%"};
      z-index: 1000;
      position: absolute;
    `;

    // render
    if (!this.props.loading) return null;

    return (
      <Container>
        <Loader>
          <HashLoader
            size={80}
            color={"#007bff"}
            loading={this.props.loading}
          />
        </Loader>
      </Container>
    );
  }
}
